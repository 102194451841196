
import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Accordion, AccordionDetails, Stack, TextField, FormControl, InputLabel, Select, Grid, Modal, IconButton, MenuItem, Button, paymentId, Box, Typography, Tabs, Tab, TextareaAutosize, Card, Autocomplete, Chip, RadioGroup, Radio } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';


import { styled } from '@mui/material/styles';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';




const accordionStyle = {
    backgroundColor: '#fbe9e7',
    minHeight: '10px',
    height: '30px',
    marginTop: '10px',
    marginBottom: '10px'
    // Add other CSS properties as needed
};

const remarkTextFieldStyle = {
    width: '48.5%'
}

export default function AllocationExecutiveExpensesTabPanel({ formik, oaProductsData, fieldSets, selectedAllocationType, handleAllocationRadioChange,
    selectedClientId, selectedClient, handleClientChange, selectProject, selectedProjectId, handleProjectChange, handleAllocationValueChange, gridRows,
    allocationValue, handleAddRow, handleFieldChange, handleDelete, editingRowId, newAllocationValue, handleEdit, handleSave, setNewAllocationValue }) {

    const TABLE_HEAD = [
        { field: 'clientName', headerName: 'Client', flex: 1 },
        { field: 'projectName', headerName: 'Project', flex: 1 },
        {
            field: 'allocation',
            headerName: 'Allocation',
            flex: 1,
            renderCell: (cellValues) => {
                if (editingRowId === cellValues.row.id) {
                    return (
                        <TextField
                            value={newAllocationValue}
                            onChange={(e) => {
                                const {value} = e.target;
                                setNewAllocationValue(value === '' ? '' : Number(value));
                            }}
                            size="small"
                            onBlur={() => handleSave(cellValues.id)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    handleSave(cellValues.id);
                                } 
                            }}
                            autoFocus
                        />
                    );
                }
                return <span>{cellValues.row.allocation}</span>;
            }
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 80,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => handleEdit(params.row.id)}>
                        <Iconify icon="ic:outline-edit" width={20} height={20} />
                    </IconButton>
                </>
            )
        },
        {
            field: 'delete',
            headerName: '',
            type: 'delete',
            width: 20,
            renderCell: (cellValues) => (
                        <div>
                            <IconButton
                                onClick={(event) => {
                                    handleDelete(event, cellValues);
                                }}
                            >
                                <Iconify icon="ic:baseline-delete" width='20px' height='20px' color='primary.main' />
                            </IconButton>
                        </div>
                    )

        },
    ];

    return (
        <Stack spacing={3} marginTop={-1}>
           {/* {fieldSets.map((fieldSet, index) => ( */}
        <>
      
                <Stack direction={{ xs: "column", sm: "row" }} spacing={4} marginBottom={-1 }>
        
                        <FormControl>
                            <RadioGroup
                                row
                            value={selectedAllocationType}
                            onChange={handleAllocationRadioChange}
                            >
                            <FormControlLabel value={1} control={<Radio />} label="Net" />
                            <FormControlLabel value={2} control={<Radio />} label="Percentage" />
                            </RadioGroup>
                        </FormControl>
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1} >
                    <FormControl fullWidth size="small">
                        <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Clients</InputLabel>
                        <Select
                            label="Clients"
                            value={selectedClientId || ''}
                            onChange={(e) => handleClientChange(e.target.value)}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7',
                                        maxHeight: '200px',
                                        width: '200px',
                                    },
                                },
                            }}
                        >
                            <MenuItem key={0} value="" onClick={() => handleClientChange('')}>
                                Select
                            </MenuItem>
                            {selectedClient.map((client) => (
                                <MenuItem key={client.ClientId} value={client.ClientId}>
                                    {client.Name}
                                </MenuItem>
                            ))}
                        </Select>

                    </FormControl>
                    <FormControl fullWidth size="small">
                        <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Projects</InputLabel>
                        <Select
                            label="Projects"
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', 
                                        maxHeight: '200px',
                                        width: '200px',
                                    },
                                },
                            }}
                            value={selectProject}
                           
                        >
                            <MenuItem key={0} value="" onClick={() => { handleProjectChange() }}>Select</MenuItem>
                            {selectedProjectId.map((project) => (
                                <MenuItem key={project.ProjectId} value={project.ProjectId} onClick={() => { handleProjectChange(project.ProjectId) }}>
                                    {project.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField
                        fullWidth
                        size="small"
                        label="Allocation Value"
                        value={allocationValue}
                        onChange={handleAllocationValueChange}
                      type='number'
                    />
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>
                        <Button variant="contained" color="primary" onClick={handleAddRow}>
                            <Iconify icon="eva:plus-fill" />
                        </Button>
                        
                    </Stack>
                    </Stack>
                </>
          {/*  ))} */}


            <div style={{ height: 212, width: '100%', marginTop: '1px' }}>
                <DataGrid
                    rows={gridRows.filter((row) => row.isDelete !== 1)}
                    density="compact"
                    columns={TABLE_HEAD}
                    getRowId={(row) => row.id}// Use the ID property as the row ID
                    components={{
                        Toolbar: GridToolbar,
                    }}
                />
            </div>       
        </Stack>
    );
}