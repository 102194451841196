import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Typography,
    Button,
    Modal,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Box,
    Tooltip,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';

import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import Page from '../../Page';
import ModalPopUp from '../../Common/ModalPopUp';


// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 10,
    p: 2,
};

export default function Request() {
    const [levelsDelete, setLevelsDelete] = useState();
    const [open1, setOpen1] = useState(false);
    const handleOpen = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const location = useLocation();
    const [visible, setVisible] = useState(false);

     const TABLE_HEAD = [
         { field: 'Name', headerName: 'Category', width: 150 },
         { field: 'CategoryDetails', headerName: 'Category Details', width: 300 },
         { field: 'isExpiryApplicable', headerName: 'isExpiryApplicable', width: 300, hide: true },
         { field: 'isReturnApplicable', headerName: 'isReturnApplicable', width: 300, hide: true },
         { field: 'ExpiryAlert', headerName: 'ExpiryAlert', width: 300 },
         {
             field: 'actions',
             headerName: '',
             type: 'actions',
             width: 20,
             renderCell: (cellValues) => 
                 // if (!levelsDelete || levelsDelete.length === 0) {
                 //    return null; // or any loading indicator component
                 // }

                 /// / Check if there is no record with LevelNo greater than 0 for the specific RequestCategoryId
                 // const isLevelGreaterThanZero = levelsDelete.some(config =>
                 //    config.RequestCategoryId === cellValues.row.RequestCategoryId && config.LevelNo > 0
                 // );

                 // const isLoginIdNotEqualToRequestedById = userId !== cellValues.row.RequestedById;
                 /// / if (!isLevelGreaterThanZero && isLoginIdNotEqualToRequestedById ) {
                  (
                     <div>

                         <IconButton
                             onClick={(event) => {
                                 handleClick(event, cellValues);
                             }}><Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' /></IconButton>


                     </div>
                     )

             // }

         },
         {
             field: 'delete',
             headerName: '',
             type: 'delete',
             width: 20,
             renderCell: (cellValues) => {
                 // if (!visible) {
                 //    return null; // Don't show the delete icon if visible is false
                 // }
                 // if (!levelsDelete || levelsDelete.length === 0) {
                 //    return null; // or any loading indicator component
                 // }

                 /// / Check if there is no record with LevelNo greater than 0 for the specific RequestCategoryId
                 // const isLevelGreaterThanZero = levelsDelete.some(config =>
                 //    config.RequestCategoryId === cellValues.row.RequestCategoryId && config.LevelNo > 0
                 // );

                 // const isLoginIdNotEqualToRequestedById = userId !== cellValues.row.RequestedById;

                 // if (!isLevelGreaterThanZero && isLoginIdNotEqualToRequestedById && cellValues.row.RequestMasterId === 0) {
                 console.log("row", cellValues.row.RequestMasterId)
                     if (cellValues.row.RequestMasterId === 0) {

                     return (
                         <div>
                             <IconButton
                                 onClick={(event) => {
                                     handleDelete(event, cellValues);
                                 }}
                             >
                                 <Iconify icon="ic:baseline-delete" width='20px' height='20px' color='primary.main' />
                             </IconButton>
                         </div>
                     );
                 }

                 return null;
             }
         }


     ];
    const { enqueueSnackbar } = useSnackbar();
    

    const [isLoading, setIsLoading] = useState();
    const [requestData, setRequestData] = useState([]);
    const [requestCategory, setRequestCategory] = useState([]);
    const [requestId, setRequestId] = useState(''); 

    const handleSelectedRequest = (id) => {
        setRequestId(id);
    };
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        const LoginId = CurrentLoggedInUser.ExecutiveId;
        const payload = { ExecutiveID: LoginId }
        URLS.getRequestCategoryLandingPage(payload)
            .then((response) => {

                setRequestCategory(response.data);

            })
            .catch(error => {
                handleError(error);
            });

       
    }, []);

    const [selectedlevels, setSelectedlevels] = useState([]);
     const navigate = useNavigate();
    const handleClick = (event, cellValues) => {
        const payload = { RequestID: cellValues.row.RequestCategoryId }
        URLS.getRequestCategoryLevelStatus(payload)
            .then((response) => {

                setSelectedlevels(response.data);

                navigate('/dashboard/requestdetailspage', { state: { requestMaster: cellValues.row, isDisabled: true, selectedlevels:response.data } })
            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
       
    };
    const [itemToDelete, setItemToDelete] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const handleDelete = (event, cellValues) => {
        console.log("Item to delete:", cellValues.row);
        setItemToDelete(cellValues.row);
        setOpenDeleteModal(true);
    };


    const handleConfirmDelete = (confirmed) => {
        if (!itemToDelete) {
            console.error("No item to delete");
            return; // Exit if itemToDelete is not set
        }
        if (confirmed && itemToDelete !== null) {
            const payload = { Id: itemToDelete.Id, ExecutiveId: userId, RequestCategoryID: itemToDelete.RequestCategoryId };
            const payload1 = { ExecutiveID: userId };

            URLS.postRequestCategoryMasterDelete(payload)
                .then(() => {
                    URLS.getRequestMaster(payload1)
                        .then((response) => {
                            setRequestData(response.data);
                            enqueueSnackbar("Record Deleted successfully", { variant: 'success' });
                        })
                        .catch(error => handleError(error));
                })
                .catch(error => handleError(error));
        }
        setOpenDeleteModal(false);
        setItemToDelete(null);
    };

    const handleCancelDelete = () => {
        // Close the modal and reset item
        setOpenDeleteModal(false);
        setItemToDelete(null);
    };
    const [snackbarDisplayed, setSnackbarDisplayed] = useState(false);


    const onFilterClick = () => {
        setIsLoading(true);
        setRequestData([]);
        setUserId(CurrentLoggedInUser.ExecutiveId);
        const LoginId = CurrentLoggedInUser.ExecutiveId;
        const payload = { ExecutiveID: LoginId, RequestCategoryID: requestId };

        // Introduce a flag to track whether the snackbar has been displayed
      

        URLS.getRequestMaster(payload)
            .then((response) => {
                if (response.data.length === 0 && !snackbarDisplayed) {
                    enqueueSnackbar("No data", { variant: 'warning' });
                    setSnackbarDisplayed(true);
                } else {
                    // Check if new records are fetched before updating the state
                    // const newRecordsFetched = response.data.length > 0 && response.data.length !== requestData.length;

                    // if (newRecordsFetched) {
                    //    setRequestData(response.data);
                    //    enqueueSnackbar("Reports Fetched successfully", { variant: 'success' });
                    // }
                    setRequestData(response.data);
                    // const hasNullRequestMasterId = response.data.some(
                    //    (item) => item.RequestMasterId === 0
                    // );

                    // Set visible to true if any RequestMasterId is 0, false otherwise
                    // setVisible(hasNullRequestMasterId);

                    enqueueSnackbar("Reports Fetched successfully", { variant: 'success' });
                }
                setIsLoading(false);
            })
            .catch(error => {
                enqueueSnackbar("Data not found", { variant: 'error' });
            });
    };


    const onResetClick = (event) => {
        setRequestId('');
        setRequestData([])
    };

    useEffect(() => {
        setRequestId('');
        setRequestData([]);
        setRequestCategory([])
    }, [location.key]); 

    // const handleChangePage = (event, newPage) => {
    //    setPage(newPage);
    // };

    // const handleRequestSort = (event, property) => {
    //    const isAsc = orderBy === property && order === 'asc';
    //    setOrder(isAsc ? 'desc' : 'asc');
    //    setOrderBy(property);
    // };


    // recomment this code

    // const handleSelectAllClick = (event) => {
    //    if (event.target.checked) {
    //        const newSelecteds = clientMasterData.map((n) => n.margCode);
    //        setSelected(newSelecteds);
    //        return;
    //    }
    //    setSelected([]);
    // };



    // const handleChangeRowsPerPage = (event) => {
    //    setRowsPerPage(parseInt(event.target.value, 10));
    //    setPage(0);
    // };
    // const handleFilterByName = (event) => {
    //    setFilterName(event.target.value);
    // };




    // const filteredProjects = applySortFilter(clientMasterData, getComparator(order, orderBy), filterName);

    // const isProjectNotFound = filteredProjects.length === 0;
   
     useEffect(() => {
         URLS.getLevelsForRequestCategoryDelete()
            .then((response) => {

                setLevelsDelete(response.data);

            })
            .catch(error => {
                enqueueSnackbar("Data not found", { variant: 'error' });
            });
     }, []);

    // const onResetClick1 = (event) => {
    //    setContactId('');
    //    setclientMasterDropDown([]);
    // };
    const [pageSize, setPageSize] = useState(10);
    const [selectedRow, setSelectedRow] = useState(null);
    useEffect(() => {
        const handleDocumentClick = (event) => {
            const gridContainer = document.querySelector('.MuiDataGrid-root'); // get the DataGrid container element
            if (gridContainer && !gridContainer.contains(event.target)) {
                setSelectedRow(null); // deselect the row if the click target is outside the DataGrid
            }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [setSelectedRow]);
    console.log("requestdata",requestData)
    return (

        <Page title="Request" >


            <Card >
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>
                    <FormControl
                        size="small" sx={{ minWidth: 230, fontSize: '0.5rem' }}>
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Request Category</InputLabel>
                        <Select
                            label="Request Category"
                            size="small"
                            value={requestId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}>
                            <MenuItem key={0} value={""} onClick={() => handleSelectedRequest()}>Select</MenuItem>
                            {requestCategory.map((req) => (
                                <MenuItem key={req.CategoryId} value={req.CategoryId} onClick={() => handleSelectedRequest(req.CategoryId)}> {req.Name}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>


                <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                    <Tooltip title="Filter">

                        <Button
                                variant="contained"
                                size="small"
                                onClick={onFilterClick}

                        ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                    </Tooltip >


                    <Tooltip title="Add New ">

                        <Button
                            component={RouterLink}
                            to='/dashboard/requestdetailspage'
                            variant="contained"
                            size="small"
                                state={{ requestMaster: { Id: '', ApprovalHierarchyId: '', CategoryId: '', Name:'',Description:'', CategoryDetails: '', IsExpiryApplicable: '', isReturnApplicable: '', ExpiryAlert: '',LevelNo: 0,ApproverKey:'', ExecutiveId:'' } }}
                        ><Icon icon="ic:baseline-plus" width="25px" height="25px" /></Button>

                    </Tooltip >

                    <Tooltip title="Reset">
                        <Button
                                variant="contained"
                                size="small"
                                onClick={onResetClick}
                        ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>
                    </Tooltip >
                </Stack>
                
                </Stack>
                <Divider />


                <div style={{ height: '72vh', width: '100%' }}>
                    <DataGrid rows={requestData} density={'compact'} columns={TABLE_HEAD}
                        selectionModel={selectedRow ? [selectedRow.id] : []} pageSize={pageSize} 
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} rowsPerPageOptions={[5, 10, 20]} getRowId={(row) => requestData.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>
            </Card>
            {openDeleteModal && (
                <ModalPopUp
                    openCloseValue={openDeleteModal}
                    msg={`Do you want to delete ${itemToDelete?.Name}?`}
                    confirm={handleConfirmDelete}
                    cancel={handleCancelDelete}
                />
            )}

            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}

        </Page>


    );
}