import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import XLSX from 'sheetjs-style';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
    Menu,
    Fade,
    Typography
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import * as FileSaver from 'file-saver';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';
import AppCache from '../../../services/AppCache';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------
const reportTypeData = [
    { id: 1, Name: 'Detailed' },
    { id: 2, Name: 'Summary' },
];

export default function Report() {

    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    // Report type DropDown
    const [reportType, setReportType] = useState(1);
    const [defaultReportType, setDefaultReportType] = useState(1);
    const [column, setColumns] = useState([]);

    // Executive DropDown
    const [executive, setExecutive] = useState([]);
    const [selectedExecutive, setSelectedExecutive] = useState('');

    // Dates
    const [effectiveFromValue, setEffectiveFromValue] = useState(startOfMonth(new Date()));
    const [effectiveToValue, setEffectiveToValue] = useState(new Date());

    // Report 
    const [reports, setReports] = useState([]);

    // Loading
    const [isLoading, setIsLoading] = useState();

    const [userId, setUserId] = useState([]);
    const [userName, setUserName] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
   
    // Client dropdown
    const handleClient = (id) => {
        setSelectedExecutive(id);
    };

    // Dates 
    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };
    const handleEffTo = (newValue) => {
        setEffectiveToValue(newValue)
    };
    const [isVisible, setIsVisible] = useState(false);

    // Filter
    const onGenerateClick = () => {
        const formattedFromDate = Moment(effectiveFromValue).format('YYYY/MMM/DD');
        const formattedToDate = Moment(effectiveToValue).format('YYYY/MMM/DD');
        const payload = {
            ExpenseMasterId: selectedExecutive || userId,
            FromDate: formattedFromDate,
            ToDate: formattedToDate,
            ReportType: reportType
        };

        setIsLoading(true);
        console.log("Selected reportType:", reportType);

        URLS.getExecutiveReportData(payload)
            .then((response) => {
                // Check if data is available and has at least one entry
                if (response.data.length > 0) {
                    const data = response.data;

                    // Calculate the maximum width for each column based on content
                    const columns = Object.keys(data[0]).map((key) => {
                        const newHeaderName = key.charAt(0).toUpperCase() + key.slice(1); // Capitalize headers
                        let maxWidth = newHeaderName.length * 10; // Start with header width (adjust multiplier as needed)

                        // Measure each cell's content for this column
                        data.forEach(row => {
                            const cellContent = String(row[key] || ""); // Get the cell content, default to empty string
                            const contentWidth = cellContent.length * 10; // Measure width (adjust multiplier as needed)
                            maxWidth = Math.max(maxWidth, contentWidth); // Update maxWidth if cell content is wider
                        });

                        return {
                            field: key,
                            headerName: newHeaderName,
                            minWidth: maxWidth + 20, // Add some padding (20 can be adjusted)
                            flex: 1, // Adjust width proportionally
                        };
                    });

                    setColumns(columns); // Update columns state
                    setReports(data); // Set rows data
                    enqueueSnackbar("Fetch data successfully", { variant: 'success' });
                } else {
                    enqueueSnackbar("No data found", { variant: 'info' });
                    setReports([]);
                }

                setIsLoading(false);
            })
            .catch(error => {
                enqueueSnackbar("Record not found", { variant: 'error' });
                setReports([]);
                setIsLoading(false);
            });
    };

    // Reset
    const onResetClick = () => {
        setSelectedExecutive("");
        setReportType(1);
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(new Date());
        setReports([]);
    }

    // Status DropDown
    const handleReportTypeChange = (event) => {
        const newReportType = event.target.value;
        setReportType(newReportType);
    };
    const handleExport = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const columns = [];
    const rows = [];

    reports.forEach((item, index) => {
        const row = { id: index + 1 };
        Object.entries(item).forEach(([key, value]) => {
            if (index === 0) {
                columns.push({
                    field: key,
                    headerName: key,
                    width: 150
                });
            }
            row[key] = value;
        });
        rows.push(row);
    });

    const fileExtention = '.xlsx';

    const FileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const exportToExcel = () => {
        const fileExtension = '.xlsx';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        // Extract column headers from the columns array
        const headers = columns.map((col) => col.headerName);

        // Prepare the row data (filteredData contains your reports data)
        const dataToExport = reports.map((row) => {
            const rowData = {};
            columns.forEach((col) => {
                rowData[col.field] = row[col.field]; // Map each column field to the corresponding row data
            });
            return rowData;
        });

        // Add headers to the dataToExport (prepend headers)
        const dataWithHeaders = [headers, ...dataToExport.map(Object.values)];

        // Create Excel sheet
        const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders); // AOA (Array of Arrays) format for headers and data
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

        // Set column widths dynamically (use default if no width is provided)
        ws['!cols'] = columns.map((col) => ({ wpx: col.width || 100 })); // Set column width or default to 100px

        // Generate Excel file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        // Save the file with a dynamic name
        const fileName = `${userName}_ExecutiveExpensesReport_${reportType === 1 ? 'Detailed' : 'Summary'}${fileExtension}`;
        FileSaver.saveAs(data, fileName);
    };

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        setUserName(CurrentLoggedInUser.ExecutiveName);
    }, []);
    // Client dropdown api call
    useEffect(() => {
        URLS.getExecutivesDropDown()
            .then((response) => {
                setExecutive(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        
        
    }, []);


    console.log("userId >>>>>>>",userId)
    useEffect(() => {
        // Ensure userId is set from the logged-in user
        const currentUserId = CurrentLoggedInUser?.ExecutiveId;
        if (currentUserId) {
            setUserId(currentUserId);
        }

        // Fetch executives for the dropdown
        URLS.getExecutivesDropDown()
            .then((response) => {
                setExecutive(response.data);
            })
            .catch(error => {
                console.log("Error fetching executives", error);
            });

        // Fetch executive report viewer ID based on userId
        if (userId) {
            const payload = { ExpenseMasterId: userId };
            URLS.executiveExpenseReportViewerId(payload)
                .then((response) => {
                    if (response.status === 200) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false);
                    }
                })
                .catch(error => {
                    setIsVisible(false);
                    console.log("Error fetching executive report viewer ID", error);
                });
        }
    }, [userId]);  // Add userId to the dependency array



    useEffect(() => {
        setSelectedExecutive("");
        setReportType(1);
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(new Date());
        setReports([]);
    }, [location.key]);

    return (

        <Page title="Report" >
            <Typography variant="h5" color="#b71c1c" margin={1} >
                  Expenses Report
            </Typography>
            <Card >

                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>
                    <FormControl fullWidth size="small">
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                            Report Type
                        </InputLabel>
                        <Select
                            label="Report Type"
                            size="small"
                            value={reportType}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7',
                                        maxHeight: '200px',
                                    },
                                },
                            }}

                            onChange={handleReportTypeChange}
                        >

                            {reportTypeData.map((category) => (
                                <MenuItem
                                    key={category.id}
                                    value={category.id}
                                >
                                    {category.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="From Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveFromValue).format('YYYY/MM/DD')}
                            onChange={handleEffFrom}
                            maxDate={new Date()} 
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="To Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveToValue).format('YYYY/MM/DD')}
                            onChange={handleEffTo}
                            disableFuture
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                           
                        />
                    </LocalizationProvider>

                    {isVisible && (
                        <>
                            <FormControl
                                size="small" sx={{ minWidth: 230, fontSize: '0.5rem' }}>
                                <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Executive</InputLabel>
                                <Select
                                    label="Executive"
                                    size="small"
                                    value={selectedExecutive}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#fbe9e7', // Set the background color here
                                                maxHeight: '200px',
                                                width: '200px',// Set the max height here
                                            }
                                        }
                                    }}
                                >
                                    <MenuItem key={0} value={""} onClick={() => handleClient()} >Select</MenuItem>

                                    {executive.map((product) => (
                                        <MenuItem key={product.Id} value={product.Id} onClick={() => handleClient(product.Id)}> {product.Name}</MenuItem>
                                    ))}
                                </Select>

                            </FormControl>

                           
                        </>
                    )}
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Generate">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={onGenerateClick}

                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >
                        <Tooltip title="Reset">
                            <Button
                                variant="contained"
                                size="small"
                                onClick={onResetClick}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>
                        </Tooltip >
                        <Tooltip title="Export">
                            <IconButton id="fade-button"
                                aria-controls={open ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleExport} sx={{ marginBottom: 1 }} >
                                <Icon height="15px" icon="ic:baseline-density-small" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem onClick={handleClose}>  <Button sx={{ color: 'black' }} size="small" onClick={exportToExcel}>Export to excel</Button></MenuItem>


                        </Menu>

                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={reports} density={'compact'} columns={column} // Use dynamic columns
                        getRowId={(row) => row.id || reports.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }}
                     
                    />
                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}