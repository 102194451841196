
import * as Yup from 'yup';
import * as React from 'react';
// material
import { Stack, Button, Typography, Divider, Select, MenuItem } from '@mui/material';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { LoadingButton } from '@mui/lab';

const accordionStyle = {
    backgroundColor: '#fbe9e7',
    minHeight: '10px',
    height: '30px',
    marginTop: '10px',
    marginBottom: '10px'
    // Add other CSS properties as needed
};

const remarkTextFieldStyle = {
    width: '48.5%'
}

export default function IssueCloserTicket({ columns, row, saveTicket, handelCancelClick, ticketData, handleActionChange, ticketActionDropdown }) {

    const ticket = [
        { field: 'IssueId', headerName: 'Id', flex: 1 },
        { field: 'Issuedate', headerName: 'Date', flex: 1 },
        { field: 'Remark', headerName: 'Remark', flex: 1 },
        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => {
                const issueId = params.row.IssueId; // Get the unique IssueId for each row
                return (
                    <Select
                        value={ticketActionDropdown[issueId] || 0} // Default value as 'Closed'
                        onChange={(event) => handleActionChange(event, issueId)}
                        variant="outlined"
                        size="small"
                        fullWidth
                    >
                        <MenuItem value="Select">Select</MenuItem>
                        <MenuItem value={1}>Reopened</MenuItem> 
                        <MenuItem value={0}>Closed</MenuItem>
                    </Select>
                );
            },
           
        },
    ];

    return (
        <Stack>
            <>
                <Typography variant="h5" marginLeft={3} marginBottom={2} >
                    Issue Closure
                </Typography>
                <Divider/>
                <div style={{ height: '53vh', width: '100%' }}>

                    <DataGrid
                        rows={ticketData}
                        density="compact"
                        columns={ticket}
                        getRowId={(row) => row.id || ticketData.indexOf(row)}
                        components={{ Toolbar: GridToolbar }}
                    />
                    
                </div>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>

                    <LoadingButton
                        size="medium"
                        type="submit"
                        variant="contained"
                        sx={{ color: 'white' }}
                        onClick={saveTicket }
                    >
                        Save
                    </LoadingButton>

                    <Button
                        sx={{ color: 'white' }}
                        onClick={handelCancelClick}

                        size="medium"
                        variant="contained"
                        color="primary"
                    >
                        Close
                    </Button>

                </Stack>
            </>
        </Stack>
       
    );
}