import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Typography,
    Button,
    Modal,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Box,
    Tooltip,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel
} from '@mui/material';
import { startOfMonth, endOfMonth } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import Page from '../../Page';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';


// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 10,
    p: 2,
};

export default function Approval() {

    const [open1, setOpen1] = useState(false);
    const handleOpen = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const location = useLocation()
    const approvalStatusDropdown = [
        { Id: 1, value: 'All' },
        { Id: 2, value: 'Pending' },
        { Id: 3, value: 'approved' },
        { Id: 4, value: 'rejected' },
    ];

    const TABLE_HEAD = [
        { field: 'RequestCategoryName', headerName: 'Request Category', width: 150 },
        { field: 'ExecutiveName', headerName: 'Requested By', width: 200 },
        { field: 'Remarks', headerName: 'Remark', width: 150 },
        {
            field: 'AccessFromDate', headerName: 'From Date', width: 150, valueFormatter: params =>
                Moment(params?.value).format("DD-MMM-yyyy hh:mm")
        },

        {
            field: 'AccessToDate', flex: 1, headerName: 'To Date', width: 150, valueFormatter: params =>
                Moment(params?.value).format("DD-MMM-yyyy hh:mm")
        },

        // {
        //    field: 'approve',
        //    headerName: '',
        //    type: 'Approve',
        //    width: 20,
        //    renderCell: (cellValues) => {
        //        return (<div>

        //            <IconButton
        //                onClick={(event) => {
        //                    handleClick(event, cellValues);
        //                }}><Iconify icon="ic:baseline-check-circle" width='20px' height='20px' color='primary.main' /></IconButton>


        //        </div>
        //        );
        //    }

        // },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
            renderCell: (cellValues, isActions, isHidden) => {
                return (<div>

                    <IconButton
                        onClick={(event) => {
                            handleClickEdit(event, cellValues, isActions,isHidden);
                        }}><Iconify icon="ic:twotone-pending-actions" width='20px' height='20px' color='primary.main' /></IconButton>

                </div>
                );
            }
        },
        // {
        //    field: 'delete',
        //    headerName: '',
        //    type: 'delete',
        //    width: 20,
        //    renderCell: (cellValues) => {
        //        return (<div>

        //            <IconButton
        //                onClick={(event, isHidden, isActions) => {
        //                    handleDelete(event, cellValues, isActions,isHidden);
        //                }}><Iconify icon="ic:baseline-delete" width='20px' height='20px' color='primary.main' /></IconButton>

        //        </div>
        //        );
        //    }
            //    return (<div>

            //        <IconButton
            //            onClick={handleOpen}><Iconify icon="ic:baseline-delete" width='20px' height='20px'  color='primary.main' /></IconButton>
            //        <Modal
            //            open={open1}
            //            onClose={handleClose1}
            //            aria-labelledby="modal-modal-title"
            //            aria-describedby="modal-modal-description"
            //        >
            //            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...style }}>


            //                <Typography id="modal-modal-title" variant="subtitle2" component="h2" textAlign="center">
            //                    Are you sure?
            //                </Typography>
            //                <Stack direction="row" mt={2} spacing={2}>
            //                    <Button
            //                        sx={{ color: 'white' }}
            //                        onClick={(event) => {
            //                            handleDelete(event, cellValues);
            //                        }}
            //                        size="small"
            //                        variant="contained"
            //                        color="primary"
            //                    >
            //                        Yes
            //                    </Button>
            //                    <Button
            //                        sx={{ color: 'white' }}
            //                        onClick={handleClose1}
            //                        size="small"
            //                        variant="contained"
            //                        color="primary"
            //                    >
            //                        No
            //                    </Button>
            //                </Stack>
            //            </Box>

            //        </Modal>
            //    </div>
            //    );
            // }

       // },
       

    ];
    const { enqueueSnackbar } = useSnackbar();
    const handleClickEdit = (event, cellValues, isActions, isHidden) => {
        navigate('/dashboard/requestmasterdetailspage', { state: { request: cellValues.row, isActions: true, isHidden: true } })
    };

    const [effectiveFromValue, setEffectiveFromValue] = useState('' || startOfMonth(new Date()));
    const [effectiveToValue, setEffectiveToValue] = useState('' || endOfMonth(new Date()));
    const [selectedRadio, setSelectedRadio] = useState('request'); 
    const handleRadioChange = (event) => {
        setSelectedRadio(event.target.value);
        if (setSelectedRadio === 'request') {
            setApprovalStatusId(''); // Clear ApprovalStatusID when 'Request' is selected
        } else if (setSelectedRadio === 'approval') {
            setRequestStatusId(''); // Clear RequestStatusID when 'Approval' is selected
        }
    };
    console.log("selectedRadio",selectedRadio)
    const handleEffFrom = (newValue) => {
        if (newValue > effectiveToValue) {
            enqueueSnackbar('Please select a valid date');
            setEffectiveFromValue(startOfMonth(new Date()));
        } else {
            setEffectiveFromValue(newValue);
        }
    };
    const handleEffTo = (newValue) => {
        if (newValue < effectiveFromValue) {
            enqueueSnackbar('Please select a valid date');
            setEffectiveToValue(endOfMonth(new Date()));
        } else {
            setEffectiveToValue(newValue);

        }
    };

    const [isLoading, setIsLoading] = useState();
    const [requestData, setRequestData] = useState([]);
    const [approvalHierarchy, setApprovalHierarchy] = useState([]);
    const [requestId, setRequestId] = useState('');
    const [requestStatus, setRequestStatus] = useState([]);
    const [requestStatusId, setRequestStatusId] = useState(2);
    const [approvalStatus, setApprovalStatus] = useState([]);
    const [approvalStatusId, setApprovalStatusId] = useState('');


    const handelChangeRequestStatus = () => {
        setRequestStatusId("");


    };


    const handleSelectedRequest = (id) => {
        setRequestId(id);
    };
    const handleSelectedRequestStatus = (id) => {
        setRequestStatusId(id);
    };
    const handleApprovalStatus = (id) => {
        setApprovalStatusId(id);
    };
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        const LoginId = CurrentLoggedInUser.ExecutiveId;
        const payload = { ExecutiveID: LoginId }
        // URLS.getRequestApprovalData(payload)
        //    .then((response) => {

        //        setApprovalHierarchy(response.data);

        //    })
        //    .catch(error => {
        //        handleError(error);
        //    });
        URLS.getRequestApprovalDropDownLandingPage(payload)
            .then((response) => {

                setRequestData(response.data);

            })
            .catch(error => {
                handleError(error);
            });
        URLS.getRequestStatus()
            .then((response) => {

                setRequestStatus(response.data);

            })
            .catch(error => {
                handleError(error);
            });

        URLS.getRequestStatus()
            .then((response) => {

                setApprovalStatus(response.data);

            })
            .catch(error => {
                handleError(error);
            });

    }, []);


    const navigate = useNavigate();
    // const handleClick = (event, cellValues) => {
    //    navigate('/dashboard/requestdetailspage', { state: { requestMaster: cellValues.row } })
    //    console.log("cellValues.row", cellValues.row)
    // };
    // const handleDelete = (event, cellValues) => {
    //    setOpen1(false);
    //    const payload = { Id: cellValues.row.Id, ExecutiveId: userId }
    //    const payload1 = { ExecutiveID: userId }
    //    URLS.postRequestApprovalDeleteData(payload)
    //        .then((response) => {
    //            URLS.getRequestApprovalData(payload1)
    //                .then((response) => {

    //                    setApprovalHierarchy(response.data);

    //                })
    //                .catch(error => {
    //                    handleError(error);
    //                });

    //            enqueueSnackbar("Record Deleted successfully", { variant: 'success' });
    //        })
    //        .catch(error => {
    //            handleError(error);
    //        });
    // }
    const [snackbarDisplayed, setSnackbarDisplayed] = useState(false);


    const onFilterClick = () => {
        setApprovalHierarchy([]);

        const mappedApprovalStatusId = selectedRadio === 'request' ? 0 : approvalStatusId;
        const mappedRequestStatusId = selectedRadio === 'approval' ? 0 : requestStatusId;


        const payload = { ExecutiveID: userId, RequestCategoryID: requestId || "", AccessFromDate: effectiveFromValue, AccessToDate: effectiveToValue, RequestStatusID: mappedRequestStatusId, ApprovalStatusID: mappedApprovalStatusId }
            //   let payload = { clientId };
           console.log("payload>>>>>>>>>>>>>>>>>>>>>",payload)
        URLS.getRequestApprovalFilterData(payload)
            .then((response) => {
                if (response.data.length === 0 && !snackbarDisplayed) {
                    setApprovalHierarchy([]);
                    setSnackbarDisplayed(true);
                    enqueueSnackbar("No data", { variant: 'error' });
                } else {
                    // Check if new records are fetched before updating the state
                    const newRecordsFetched = response.data.length > 0 && response.data.length !== approvalHierarchy.length;

                    if (newRecordsFetched) {
                        setApprovalHierarchy(response.data);
                        enqueueSnackbar("Records Fetched successfully", { variant: 'success' });
                    }
                }

                setIsLoading(false);
            })
            .catch(error => {
                enqueueSnackbar("Records not found", { variant: 'success' });

                // On error
                console.log(" Error ");
            });

        
    }

    const onResetClick = (event) => {
        setApprovalHierarchy([]);

        setRequestStatusId(2)
        setApprovalStatusId('')

        setRequestId('')
        setEffectiveFromValue(startOfMonth(new Date()))
        setEffectiveToValue(endOfMonth(new Date()))

        console.log("click>")
    };

    useEffect(() => {
        setApprovalHierarchy([]);
        setRequestStatusId(2)
        setApprovalStatusId('')
        setRequestId('')
        setEffectiveFromValue(startOfMonth(new Date()))
        setEffectiveToValue(endOfMonth(new Date()))
    }, [location.key]); 

    const [pageSize, setPageSize] = useState(10);
    const [selectedRow, setSelectedRow] = useState(null);
    useEffect(() => {
        const handleDocumentClick = (event) => {
            const gridContainer = document.querySelector('.MuiDataGrid-root'); // get the DataGrid container element
            if (gridContainer && !gridContainer.contains(event.target)) {
                setSelectedRow(null); // deselect the row if the click target is outside the DataGrid
            }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [setSelectedRow]);




    return (

        <Page title="Request" >


            <Card >
                <Stack style={{ marginTop: '8px'}}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={4}  >
                    <FormControl
                        style={{ width: '300px' }}
                        size="small" >
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Request Category</InputLabel>
                        <Select
                            label="Request Category"
                            size="small"
                            value={requestId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}>
                            <MenuItem key={0} value={""} >Select</MenuItem>
                            {requestData.map((req) => (
                                <MenuItem key={req.RequestCategoryId} value={req.RequestCategoryId} onClick={() => handleSelectedRequest(req.RequestCategoryId)}> {req.Name}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>

                    <Stack direction="row" alignItems="center" spacing={1}>
                        <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                        <RadioGroup row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group" value={selectedRadio} onChange={handleRadioChange}>
                        <FormControlLabel value="request" control={<Radio />} label="Request" />
                        <FormControlLabel value="approval" control={<Radio />} label="Approval" />
                        </RadioGroup>
                    </Stack>

                        {selectedRadio === 'request' ? (
                        <Stack style={{ width: '300px'}} >

                        <FormControl
                            
                                    size="small" >
                                    <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Request Status</InputLabel>

                            <Select
                                        label="Request Status"
                                size="small"
                                value={requestStatusId}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px',// Set the max height here
                                        }
                                    }
                                }}>
                                {/* <MenuItem key={0} value={""} onClick={() => handleSelectedRequestStatus()}>Select</MenuItem> */}
                                {requestStatus.map((req) => (
                                    <MenuItem key={req.Id} value={req.Id} onClick={() => handleSelectedRequestStatus(req.Id)}> {req.Status}</MenuItem>
                                ))}
                            </Select>

                            </FormControl>
                        </Stack>
                    ) : (
                            <>
                                <Stack style={{ width: '250px'}} >

                                <FormControl
                                    
                                    size="small" >
                                    <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Approval Status</InputLabel>
                                    <Select
                                        label="Approval Status"
                                        size="small"
                                        value={approvalStatusId}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    backgroundColor: '#fbe9e7', // Set the background color here
                                                    maxHeight: '200px',
                                                    width: '200px',// Set the max height here
                                                }
                                            }
                                        }}>

                                        {approvalStatusDropdown.map((req) => (
                                            <MenuItem key={req.Id} value={req.Id} onClick={() => handleApprovalStatus(req.Id)}> {req.value}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                </Stack>
                                <Tooltip title="Approval Status Filter applies to logged-in users approval status">

                                    <Icon icon="ic:round-info" style={{ marginLeft: '8px', cursor: 'pointer', height: '20px', width: '80px' }} />
                                    </Tooltip>
                            </>
                        
                            )
                        }
                    </Stack>

                  

                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="From Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveFromValue).format('DD-MMM-yyyy')}
                            onChange={handleEffFrom}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="To Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveToValue).format('DD-MMM-yyyy')}
                            onChange={handleEffTo}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                onClick={onFilterClick}
                                variant="contained"
                                size="small"

                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >


                       

                        <Tooltip title="Reset">
                            <Button
                                variant="contained"
                                size="small"
                                onClick={onResetClick}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>
                        </Tooltip >
                    </Stack>

                </Stack>
                <Divider />

                </Stack>
                <div style={{ height: '65vh', width: '100%' }}>
                    <DataGrid rows={approvalHierarchy} density={'compact'} columns={TABLE_HEAD} selectionModel={selectedRow ? [selectedRow.id] : []} pageSize={pageSize} onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} rowsPerPageOptions={[5, 10, 20]} getRowId={(row) => approvalHierarchy.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>
            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}

        </Page>


    );
}