import axios from 'axios';
import { configFileURL } from './Siteconfigpath';





import {
    loginUrl, dashBoardMenuUrl, loginImageUrl, ChangeLoginImageUrl, API_URL, ClientAliasUrl,
    ProjectServerDrivesUrl, ProjectClientNamesUrl, SaveProjectInformationUrl, GetProjectInformationUrl,
    GetClientsUrl, GetServerProviderUrl, GetProjectinformationDropDownUrl, GetDropdownUrl, GetClientMasterUrl,
    SaveClientDetailsUrl, GetProjectStatusDropDownUrl, GetProjectNamesDropDownUrl, GetClientMasterDropDownDataUrl,
    GetClientNameValidationUrl, GetERPCodeValidationUrl, GetClientInstallationLocationDataUrl, GetContactTypeIdDropDownUrl,
    GetAMCDropDownDataUrl, GetClassificationDropDownUrl, GetSiteAutocompleteUrl, GetProjectVersionAutocompleteUrl,
    GetContactMasterUrl, SaveClientInstallationLocationsUrl, GetClientsDropDownUrl, GetProductMasterListingDataUrl,
    GetProductMasterAutocompleteUrl, SaveProductMasterDetailsUrl, GetClientMasterFilterDropDownUrl,
    GetTeamAndPrefferedClientsDropdownUrl, GetProductMasterFilterDropDownUrl, EditClientInstallationLocationsUrl,
    SaveContactMasterUrl, GetContactMasterDataUrl, EditContactMasterUrl, GetClientInstallationContactMasterUrl, BulkSaveUrl,
    SaveContactSiteUrl, GetClientGroupingDropDownUrl, GetClientNamesDDUrl, GetServiceWeightageUrl, GetTeamNameUrl, GetDepartmentNameUrl,
    GetExecutiveNameUrl, GetDesignationNameUrl, SaveServiceWeightage, SaveServiceWeightageAll, FileterServiceWeightage, addAttendanceUrl,
    GetAttendanceUrl, GetClientInstallationFilterDropDownUrl, GetReportingToDropDownUrl, GetOfficeDropDownUrl, GetDepartmentDropDownUrl,
    GetDesignationDropDownUrl, GetClientsPopOverDropDownUrl, GetGenderDropDownUrl, GetMaritalStatusDropDownUrl, GetTestingDataUrl,
    GetServiceEntryClientNameUrl, GetServiceEntrySiteNameUrl, GetServiceEntryActivityUrl, GetServiceEntryProjectUrl,
    SaveQuickServiceEntryUrl, GetFilteredServicEntrySectionCUrl, GetFilteredServicEntrySectionDUrl, GetServiceEntryTotalServiceCountUrl,
    GetServiceEntryTotalMinutesUrl, UpdateQuickServiceEntryUrl, GetControlsDropDownUrl, GetExecutivesDropDownUrl, GetServiceProviderUrl,
    GetServiceTimeContributionReportUrl, GetClientAliasDropDownListUrl, GetUserAccessRightsUrl, GetDashboardMenusUrl, GetUserPhotoUrl,
    GetExecutiveListDropDownUrl, PostMarkAsAbsentUrl, GetExecutiveImmediateListDropDownUrl, GetExecutiveNameDepartmentDDUrl, GetDefaultIssueIdUrl,
    GetActivityDataGridUrl, GetRequestCategoryDropDownUrl, GetRequestMasterUrl, SaveRequestCategoryMasterUrl, GetRequestCategoryFilterDropDownUrl,
    RequestCategoryMasterDeleteUrl, GetRequestCategoryLandingPageUrl, GetApprovalHierarchyUrl, GetExecutivesListUrl, GetRequestCategoryApprovalHierarchyUrl,
    GetRequestMasterMasterUrl, GetRequestCategoryMasterDropDownUrl, SaveRequestSlipMasterUrl, GetRequestMasterDropDownLandingPageUrl, GetRequestStatusUrl,
    GetRequestApprovalDataUrl, SaveRequestSlipDetailsUrl, GetRequestApprovalStatusDataUrl, GetRequestCategoryDataGridDataUrl, GetApprovalStatusConfirmationUrl,
    GetRequestApprovalDropDownLandingPageUrl, RejectRequestSlipDetailsUrl, RequestApprovalDeleteDataUrl, GetRequestMasterFilterDataUrl,
    GetRequestApprovalFilterDataUrl, PowerBIDashBoardUrl, GetRolesUrl, GetRequestCategoryLevelStatusUrl, CheckRequestNameDataUrl, GetRequestDateRangeUrl,
    RequestApprovalHierarchyDeleteUrl, GetExecutiveDataUrl, GetLevelsDataUrl, GetApprovedStatusDataUrl, CheckApprovedStatusUrl, GetNextApproveStatusUrl,
    GetProductNameValidationUrl, GetOAMasterListingDataUrl, GetExecutivesDropDataUrl, GetOAProductsDropDownUrl, GetPOMasterListingDataUrl,
    GetCLientsDropDownPOModuleUrl, GetPODetailsDropDownListUrl, GetPOGroupsDropDownDataUrl, GetProductLinkingListingDataUrl, GetProductNonCommercialUrl,
    GetIRQustionnariesDataUrl, GetClientLandingPageGenerateReportUIUrl, GetGenerateReportFilterUrl, GetActivityDDUrl, SaveOAMasterListingUrl,
    GetOADetailsEditDataUrl, GetPaymentTermDropDownUrl, GetLevelsForRequestCategoryDeleteUrl, GetClientDDOAUrl, SavePOMasterListingUrl,
    SaveProductMasterLinkingUrl, ProductLinkingDeleteDataUrl, GetGenerateInstallationFilterDataUrl, SaveInstallationReportMasterUrl,
    
    GetOAClientUrl, GetClientMasterDataUrl, GetOAProductWiseDataForPOUrl, GetOAProductWiseDataUrl,
    GetGenerateInstallationReportListingUrl, GetPOMasterListingUrl, GetPOMasterEditDataUrl, GetGenerateInstallationReportEditDataUrl,
    GetOAMasterEditDataUrl, GetApiMasterIdUrl, GetAPIMasterListingDataUrl, SaveAPIMasterUrl, GetAPIMasterEditDataUrl, CheckAPIIdExistUrl,
    GenerateInstallationReportExcelDataUrl, GetProductMasterEditDataUrl, GetCommercialProductsUrl, GetProductLinkingEditDataUrl, GetIssueDetailsUrl,
    SaveIssueIncidentUrl, GetClientMasterListingDataUrl, ValidateIssueIdAgainstOAUrl, GetGroupsDataUrl, GenerateOAPDIsCostSheetTrueUrl, GenerateOAPDIsCostSheetFalseUrl,
    GetClientMasterEditDataUrl, GetExecutiveInfoUrl, GenerateInstallationReportDataUrl, GetIssueDeliveryDateListingUrl, SaveIssueDeliveryDateUrl, ValidateIssueIdUrl,
    SaveCopyAccessRightsUrl, ExpensesCategoryDataUrl, TravelModeCategoryDataUrl, FoodCategoryDataUrl, DeleteExecutiveExpensesUrl, SaveExecutiveExpensesUrl, GetExecutiveExpensesEditDataUrl,
    FilterExecutiveExpensesUrl, GetExpenseCategoryColumnsUrl, GetExecutiveReportDataUrl, ExecutiveExpenseReportViewerIdUrl,
    InstallationReportFilterDataUrl, FillInstallationReportFilterDataUrl, SaveFillInstallationReportUrl, SaveFillInstallationDataUrl, GenerateFillInstallationPDFUrl, GetDesignationUrl,
    SaveQuestionariesUrl, GetParentQuestionariesUrl, SaveServiceRequestUrl, GetRequestFromForClientUrl, GetServiceRequestDataUrl, ServiceRequestStatusReportUrl, LoadTicketIssueCloserPendingListUrl,
    LoadDashboardIssueCloserPendingListUrl, SaveIssueCloserPendingListUrl, SaveTicketIssueCloserPendingListUrl
} from './config';

/* import { loginUrl, dashBoardMenuUrl, loginImageUrl, ChangeLoginImageUrl, API_URL, ClientAliasUrl, ProjectServerDrivesUrl, ProjectClientNamesUrl, SaveProjectInformationUrl, GetProjectInformationUrl, GetClientsUrl, GetServerProviderUrl, GetProjectinformationDropDownUrl, GetDropdownUrl, GetClientMasterUrl, SaveClientDetailsUrl, GetProjectStatusDropDownUrl, GetClientMasterDropDownDataUrl, GetClientInstallationLocationDataUrl, GetContactTypeIdDropDownUrl, GetAMCDropDownDataUrl, GetClassificationDropDownUrl, GetSiteAutocompleteUrl, GetProjectVersionAutocompleteUrl, GetContactMasterUrl, SaveClientInstallationLocationsUrl, GetClientsDropDownUrl, GetProductMasterDetailsUrl, GetProductMasterAutocompleteUrl, SaveProductMasterDetailsUrl, GetServiceWeightageUrl, GetTeamNameUrl, GetExecutiveNameUrl, SaveServiceWeightage, FileterServiceWeightage, SaveServiceWeightageAll, GetServiceEntryClientNameUrl, GetServiceEntrySiteNameUrl, GetServiceEntryActivityUrl, GetServiceEntryProjectUrl, SaveQuickServiceEntryUrl} from './config'; */


import AppCache from './AppCache';

 // const API_URL = '/api/';
// const API_URL = commonurl.API_URL;

// const headers = { 'content-type': 'application/json' };
const header = { 'content-type': 'application/json'};
const fileheaders = { 'content-type': 'multipart/form-data' };

const Imageheader = { 'content-type': 'application/json', 'Access-Control-Allow-Origin': '*' };

class URLS {
    constructor() {
        // login = (payload) => (axios.post(`${API_URL}User/login`, payload));

        this.configFileURL = () => (axios.get(configFileURL, "", header));
        console.log("configFileURL>>>>>", configFileURL)
       // console.log("configFileURL", configFileURL);
       // this.loginURL = (payload) => (axios.post(`${loginUrl}`, payload, headers));

        this.loginURL = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL()+loginUrl}`, payload, header));
        this.loginImage = (payload) => (axios.post(`${API_URL + loginImageUrl}`, payload, fileheaders));
     //   console.log("loginURL", AppCache.GetCurrentAPIURL() + loginUrl);
        this.changeImageURL = () => (axios.get(`${API_URL + ChangeLoginImageUrl}`, "", Imageheader));

        this.dashBoardMenu = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL()+dashBoardMenuUrl}`, payload, header));
        this.clientAlias = () => (axios.get(`${API_URL + ClientAliasUrl}`, "", header));
        this.getProjectInformation = () => (axios.get(`${API_URL + ClientAliasUrl}`, "", header));

        this.getProjectServerDrives = () => (axios.get(`${AppCache.GetCurrentAPIURL() + ProjectServerDrivesUrl}`, "", header));


        this.getClientNames = () => (axios.get(`${AppCache.GetCurrentAPIURL() + ProjectClientNamesUrl}`, "", header));

        this.postProjectInformation = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveProjectInformationUrl}`, payload, header));

        this.getProjectInformationTable = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetProjectInformationUrl}`, "", header));

        this.getClientDetails = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetClientsUrl}`, "", header));

        this.getServerProvider = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetServerProviderUrl}`, "", header));

        this.getProjectinformationDropDown = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetProjectinformationDropDownUrl}`, payload, header));

        this.getDropdown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetDropdownUrl}`, "", header));

        this.getServiceProvider = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetServiceProviderUrl}`, "", header));

        this.getClientAliasDropDownList = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetClientAliasDropDownListUrl}`, "", header));

        this.getExecutiveListDropDown = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetExecutiveListDropDownUrl}`, payload, header));

        this.postMarkAsAbsent = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + PostMarkAsAbsentUrl}`, payload, header));

        this.getExecutiveImmediateListDropDown = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetExecutiveImmediateListDropDownUrl}`, payload, header));



        // clientmaster
        this.getClientMaster = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetClientMasterUrl}`, "", header));
        this.saveClientDetails = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveClientDetailsUrl}`, payload, header));
        this.getProjectStatusDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetProjectStatusDropDownUrl}`, "", header));
        this.getClientMasterDropDownData = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetClientMasterDropDownDataUrl}`, "", header));
        this.GetClientMasterFilterDropDown = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetClientMasterFilterDropDownUrl}`, payload, header));
        this.getTeamAndPrefferedClientsDropdown = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetTeamAndPrefferedClientsDropdownUrl}`, payload, header));
        this.getClientGroupingDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetClientGroupingDropDownUrl}`, "", header));
        this.getClientNamesDD = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetClientNamesDDUrl}`, "", header));
        this.getClientNameValidation = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetClientNameValidationUrl}`, payload, header));
        this.getERPCodeValidation = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetERPCodeValidationUrl}`, payload, header));
        this.getProjectNamesDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetProjectNamesDropDownUrl}`, "", header));
        this.getDesignationName = (payload) => (axios.get(`${AppCache.GetCurrentAPIURL() + GetDesignationNameUrl + payload }`, "", header));
        this.getDepartmentName = (payload) => (axios.get(`${AppCache.GetCurrentAPIURL() + GetDepartmentNameUrl + payload}`, "", header));
        this.getRoles = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetRolesUrl}`, "", header));
        this.getClientMasterListingData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetClientMasterListingDataUrl}`, payload, header));
        this.getExecutiveInfo = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetExecutiveInfoUrl}`, payload, header));

        
        // ClientInstallationLocations
        this.getClientInstallationLocationData = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetClientInstallationLocationDataUrl}`, "", header));
        this.getContactTypeIdDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetContactTypeIdDropDownUrl}`, "", header));
        this.getAMCDropDownData = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetAMCDropDownDataUrl}`, "", header));
        this.getClassificationDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetClassificationDropDownUrl}`, "", header));
        this.getSiteAutocomplete = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetSiteAutocompleteUrl}`, "", header));
        this.getProjectVersionAutocomplete = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetProjectVersionAutocompleteUrl}`, "", header));
        this.getClientsDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetClientsDropDownUrl}`, "", header));
        this.postClientInstallationLocations = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveClientInstallationLocationsUrl}`, payload, header));
        this.editClientInstallationLocations = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + EditClientInstallationLocationsUrl}`, payload, header));
        this.editContactMaster = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + EditContactMasterUrl}`, payload, header));
        this.postContactMaster = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveContactMasterUrl}`, payload, header));
        this.getContactMasterData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetContactMasterDataUrl}`, payload, header));
        this.getClientInstallationContactMaster = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetClientInstallationContactMasterUrl}`, "", header));
        this.postbulkSave = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + BulkSaveUrl}`, payload, header));
        this.postContactSite = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveContactSiteUrl}`, payload, header));
        this.getClientInstallationFilterDropDown = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetClientInstallationFilterDropDownUrl}`, payload, header));

        // Contact Master
        this.getContactMaster = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetContactMasterUrl}`, "", header));

        // ProductMaster
        this.getProductMasterListingData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetProductMasterListingDataUrl}`, payload, header));
        this.getProductMasterAutocomplete = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetProductMasterAutocompleteUrl}`, "", header));
        this.saveProductMaster = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveProductMasterDetailsUrl}`, payload, header));
        this.getProductNameValidation = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetProductNameValidationUrl}`, payload, header));
        this.getProductMasterEditData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetProductMasterEditDataUrl}`, payload, header));

      
        // Api Master
        this.getApiMasterId = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetApiMasterIdUrl}`, "", header));
        this.getAPIMasterListingData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetAPIMasterListingDataUrl}`, payload, header));
        this.saveAPIMaster = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveAPIMasterUrl}`, payload, header));
        this.getAPIMasterEditData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetAPIMasterEditDataUrl}`, payload, header));
        this.checkAPIIdExist = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + CheckAPIIdExistUrl}`, payload, header));

        // Services
        this.getServiceWeightageData = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetServiceWeightageUrl}`, "", header));
        this.getTeamNameData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetTeamNameUrl}`, payload, header));
        this.getExecutiveNameData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetExecutiveNameUrl}`, payload, header));
        this.postServiceWeightage = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveServiceWeightage}`, payload, header));
        this.postServiceWeightageAll = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveServiceWeightageAll}`, payload, header));
        this.postFilterServiceWeightage = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + FileterServiceWeightage}`, payload, header));
        this.getExecutiveNameDepartmentDD = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetExecutiveNameDepartmentDDUrl}`, payload, header));


        this.getProductMasterFilterDropDown = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetProductMasterFilterDropDownUrl}`, payload, header));

       // ServiceEntry
        this.getServiceEntryClientName = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetServiceEntryClientNameUrl}`, "", header));
        this.getServiceEntrySiteName = (playload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetServiceEntrySiteNameUrl}`, playload, header));
        this.getServiceActivityType = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetServiceEntryActivityUrl}`, "", header));
        this.getServiceEntryProject = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetServiceEntryProjectUrl}`, "", header));
        this.postQuickServiceEntry = (playload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveQuickServiceEntryUrl}`, playload, header));
        this.getFilteredServicEntrySectionC = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetFilteredServicEntrySectionCUrl}`, payload, header));
        this.getActivityDataGrid = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetActivityDataGridUrl}`, payload, header));
        this.getFilteredServicEntrySectionD = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetFilteredServicEntrySectionDUrl}`, payload, header));
        this.getServiceEntryTotalServiceCount = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetServiceEntryTotalServiceCountUrl}`, payload, header));
        this.getServiceEntryTotalMinutes = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetServiceEntryTotalMinutesUrl}`, payload, header));
        this.updateQuickServiceEntry = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + UpdateQuickServiceEntryUrl}`, payload, header));
        this.getDefaultIssueId = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetDefaultIssueIdUrl}`, "", header));
        this.SaveFillInstallationData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveFillInstallationDataUrl}`, payload, header));


     // UPload photo
        this.getUserPhoto = (payload) => (axios.get(`${AppCache.GetCurrentAPIURL() + GetUserPhotoUrl + payload}`, "", header));
        this.postaddAttendance = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + addAttendanceUrl}`, payload, header));
        this.getUserAccessRights = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetUserAccessRightsUrl}`, payload, header));
        this.getAttendance = (payload) => (axios.get(`${AppCache.GetCurrentAPIURL() + GetAttendanceUrl + payload}`, "", header));
        this.getDashboardMenus = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetDashboardMenusUrl}`, "", header));
        this.saveCopyAccessRights = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveCopyAccessRightsUrl}`, payload, header));

        // Contact Popover
        this.getReportingToDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetReportingToDropDownUrl}`, "", header));
        this.getOfficeDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetOfficeDropDownUrl}`, "", header));
        this.getDepartmentDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetDepartmentDropDownUrl}`, "", header));
        this.getDesignationDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetDesignationDropDownUrl}`, "", header));
        this.getClientsPopOverDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetClientsPopOverDropDownUrl}`, "", header));
        this.getGenderDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetGenderDropDownUrl}`, "", header));
        this.getMaritalStatusDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetMaritalStatusDropDownUrl}`, "", header));


        // Reports
        this.getControlsDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetControlsDropDownUrl}`, "", header));
        this.getExecutivesDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetExecutivesDropDownUrl}`, "", header));
        this.getServiceTimeContributionReport = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetServiceTimeContributionReportUrl}`, payload, header));
        this.getExecutiveReportData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetExecutiveReportDataUrl}`, payload, header));
        this.getExecutiveReportData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetExecutiveReportDataUrl}`, payload, header));
        this.executiveExpenseReportViewerId = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + ExecutiveExpenseReportViewerIdUrl}`, payload, header));


        // RequestAndApproval
        this.getRequestCategoryDropDown = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestCategoryDropDownUrl}`, payload, header));
        this.getRequestCategoryLandingPage = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestCategoryLandingPageUrl}`, payload, header));
       
        this.getRequestMaster = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestMasterUrl}`, payload, header));
        this.postRequestCategoryMaster = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveRequestCategoryMasterUrl}`, payload, header));
        this.getRequestCategoryDataGridData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestCategoryDataGridDataUrl}`, payload, header));
        this.getRequestCategoryFilterDropDown = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestCategoryFilterDropDownUrl}`, payload, header));
        this.postRequestCategoryMasterDelete = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + RequestCategoryMasterDeleteUrl}`, payload, header));

        this.getApprovalHierarchy = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetApprovalHierarchyUrl}`, "", header));
        this.getExecutivesList = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetExecutivesListUrl}`, "", header));
        this.getRequestCategoryApprovalHierarchy = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetRequestCategoryApprovalHierarchyUrl}`, "", header));
        this.getRequestMasterMaster = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestMasterMasterUrl}`, payload, header));
        this.getRequestCategoryMasterDropDown = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestCategoryMasterDropDownUrl}`, payload, header));
        this.postSaveRequestSlipMaster = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveRequestSlipMasterUrl}`, payload, header));
        this.getRequestMasterDropDownLandingPage = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestMasterDropDownLandingPageUrl}`, payload, header));
        this.getRequestStatus = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetRequestStatusUrl}`, "", header));
        this.getRequestApprovalData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestApprovalDataUrl}`, payload, header));
        this.postRequestSlipDetails = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveRequestSlipDetailsUrl}`, payload, header));
        this.postRejectRequestSlipDetails = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + RejectRequestSlipDetailsUrl}`, payload, header));
        this.getRequestApprovalStatusData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestApprovalStatusDataUrl}`, payload, header));
        this.getApprovalStatusConfirmation = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetApprovalStatusConfirmationUrl}`, payload, header));
        this.getRequestApprovalDropDownLandingPage = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestApprovalDropDownLandingPageUrl}`, payload, header));
        this.postRequestApprovalDeleteData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + RequestApprovalDeleteDataUrl}`, payload, header));
        this.getRequestMasterFilterData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestMasterFilterDataUrl}`, payload, header));
        this.getRequestApprovalFilterData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestApprovalFilterDataUrl}`, payload, header));
        this.getRequestCategoryLevelStatus = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestCategoryLevelStatusUrl}`, payload, header));
        this.checkRequestNameData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + CheckRequestNameDataUrl}`, payload, header));
        this.getRequestDateRange = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestDateRangeUrl}`, payload, header));
        this.requestApprovalHierarchyDelete = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + RequestApprovalHierarchyDeleteUrl}`, payload, header));
        this.getExecutiveData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetExecutiveDataUrl}`, payload, header));
        this.getLevelsData = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetLevelsDataUrl}`, "", header));
        this.getApprovedData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetApprovedStatusDataUrl}`, payload, header));
        this.checkApprovedStatus = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + CheckApprovedStatusUrl}`, payload, header));
        this.getNextApproveStatus = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetNextApproveStatusUrl}`, payload, header));
        this.getLevelsForRequestCategoryDelete = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetLevelsForRequestCategoryDeleteUrl}`, "", header));




        // OA Master
        this.getOAMasterListingData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetOAMasterListingDataUrl}`, payload, header));
        this.getExecutivesDropData = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetExecutivesDropDataUrl}`, "", header));
        this.getOAProductsDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetOAProductsDropDownUrl}`, "", header));
        this.getPOMasterListingData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetPOMasterListingDataUrl}`, payload, header));
        this.getCLientsDropDownPOModule = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetCLientsDropDownPOModuleUrl}`, payload, header));
        this.getPODetailsDropDownList = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetPODetailsDropDownListUrl}`, payload, header));
        this.getPOGroupsDropDownData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetPOGroupsDropDownDataUrl}`, payload, header));
        this.getProductLinkingListingData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetProductLinkingListingDataUrl}`, payload, header));
        this.getProductNonCommercial = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetProductNonCommercialUrl}`, "", header));
        this.getIRQustionnariesData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetIRQustionnariesDataUrl}`, payload, header));
        this.getClientLandingPageGenerateReportUI = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetClientLandingPageGenerateReportUIUrl}`, "", header));
        this.getGenerateReportFilter = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetGenerateReportFilterUrl}`, payload, header));
        // this.getActivityDD = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetActivityDDUrl}`, payload, header));
        this.getActivityDD = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetActivityDDUrl}`, "", header));
        this.saveOAMasterListing = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveOAMasterListingUrl}`, payload, header));
        this.getOADetailsEditData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetOADetailsEditDataUrl}`, payload, header));
        this.getPaymentTermDropDown = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetPaymentTermDropDownUrl}`, "", header));
        this.getClientDDOA = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetClientDDOAUrl}`, "", header));
        this.savePOMasterListing = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SavePOMasterListingUrl}`, payload, header));
        this.saveProductMasterLinking = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveProductMasterLinkingUrl}`, payload, header));
        this.productLinkingDeleteData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + ProductLinkingDeleteDataUrl}`, payload, header));
        this.getGenerateInstallationFilterData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetGenerateInstallationFilterDataUrl}`, payload, header));
        this.saveInstallationReportMaster = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveInstallationReportMasterUrl}`, payload, header));
        this.getOAClient = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetOAClientUrl}`, "", header));
        this.getClientMasterData = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetClientMasterDataUrl}`, "", header))
        this.getOAProductWiseDataForPO = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetOAProductWiseDataForPOUrl}`, payload, header));
        this.getOAProductWiseData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetOAProductWiseDataUrl}`, payload, header));
        this.getGenerateInstallationReportListing = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetGenerateInstallationReportListingUrl}`, payload, header));
        this.getPOMasterListing = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetPOMasterListingUrl}`, payload, header));
        this.getPOMasterEditData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetPOMasterEditDataUrl}`, payload, header));
        this.getGenerateInstallationReportEditData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetGenerateInstallationReportEditDataUrl}`, payload, header));
        this.getOAMasterEditData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetOAMasterEditDataUrl}`, payload, header));
        this.getGenerateInstallationReportExcelData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GenerateInstallationReportExcelDataUrl}`, payload, header));
        this.getCommercialProducts = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetCommercialProductsUrl}`, "", header))
        this.getProductLinkingEditData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetProductLinkingEditDataUrl}`, payload, header));
        this.validateIssueIdAgainstOA = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + ValidateIssueIdAgainstOAUrl}`, payload, header));
        this.getGroupsData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetGroupsDataUrl}`, payload, header));
        this.generateOAPDFTrue = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GenerateOAPDIsCostSheetTrueUrl}`, payload, header));
        this.generateOAPDFFalse = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GenerateOAPDIsCostSheetFalseUrl}`, payload, header));
        this.getClientMasterEditData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetClientMasterEditDataUrl}`, payload, header));
        this.generateInstallationReportData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GenerateInstallationReportDataUrl}`, payload, header));
        this.installationReportFilterData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + InstallationReportFilterDataUrl}`, payload, header));
        this.fillInstallationReportFilterData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + FillInstallationReportFilterDataUrl}`, payload, header));
        this.saveFillInstallationReport = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveFillInstallationReportUrl}`, payload, header));
        this.generateFillInstallationPDF = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GenerateFillInstallationPDFUrl}`, payload, header));
        this.getDesignation = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetDesignationUrl}`, "", header))
        this.saveQuestionaries = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveQuestionariesUrl}`, payload, header));
        this.getParentQuestionaries = () => (axios.get(`${AppCache.GetCurrentAPIURL() + GetParentQuestionariesUrl}`, header));

        // Service request
        this.saveServiceRequest = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveServiceRequestUrl}`, payload, fileheaders));
        this.getRequestFromForClient = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetRequestFromForClientUrl}`, payload, header));
        this.getServiceRequestData = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetServiceRequestDataUrl}`, payload, header));
        this.serviceRequestStatusReport = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + ServiceRequestStatusReportUrl}`, payload, header));
        this.loadTicketIssueCloserPendingList = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + LoadTicketIssueCloserPendingListUrl}`, payload, header));
        this.loadDashboardIssueCloserPendingList = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + LoadDashboardIssueCloserPendingListUrl}`, payload, header));
        this.saveIssueCloserPendingList = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveIssueCloserPendingListUrl}`, payload, header));
        this.saveTicketIssueCloserPendingList = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveTicketIssueCloserPendingListUrl}`, payload, header));
        
        // DashBoard
        this.getPowerBIDashBoard = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + PowerBIDashBoardUrl}`, payload, header));
        //  this.getPowerBIData = (payload) => (axios.get(`https://zylemskfindia.co.in/SmileService_PBI/Home/EmbedReportNORLS?UserName=233&ReportId=cee14564-601d-4cd7-ab92-d7b1f394c975&DatasetId=603199d7-276a-48d2-a8cb-420ae6f44121`,"",header));
        //  this.getPowerBIData = (payload) => (axios.get(`https://zylemskfindia.co.in/SmileService_PBI/Home/EmbedReportRLS?UserName=` + payload, "", header));

        // Issue
        this.getIssueDetails = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetIssueDetailsUrl}`, payload, header));
        this.saveIssueIncident = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveIssueIncidentUrl}`, payload, header));
        this.getIssueDeliveryDateListing = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetIssueDeliveryDateListingUrl}`, payload, header));
        this.saveIssueDeliveryDate = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveIssueDeliveryDateUrl}`, payload, header));
        this.validateIssueId = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + ValidateIssueIdUrl}`, payload, header));

        // Executive Expenses
        this.getExpensesCategoryData = () => (axios.get(`${AppCache.GetCurrentAPIURL() + ExpensesCategoryDataUrl}`, "", header))
        this.getTravelModeCategoryData = () => (axios.get(`${AppCache.GetCurrentAPIURL() + TravelModeCategoryDataUrl}`, "", header))
        this.getFoodCategoryData = () => (axios.get(`${AppCache.GetCurrentAPIURL() + FoodCategoryDataUrl}`, "", header))
        this.deleteExecutiveExpenses = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + DeleteExecutiveExpensesUrl}`, payload, header));
        this.saveExecutiveExpenses = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + SaveExecutiveExpensesUrl}`, payload, header));
        this.editExecutiveExpenses = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetExecutiveExpensesEditDataUrl}`, payload, header));
        this.filterExecutiveExpenses = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + FilterExecutiveExpensesUrl}`, payload, header));
        this.getExpenseCategoryColumns = (payload) => (axios.post(`${AppCache.GetCurrentAPIURL() + GetExpenseCategoryColumnsUrl}`, payload, header));



        this.getPowerBINoRlsData = (payload) => axios.get(`https://zylemskfindia.co.in/SmileService_PBI/Home/EmbedReportNORLS?UserName=${payload}`, { headers: header });



        this.getPowerBIData = (payload) => axios.get(`https://zylemskfindia.co.in/SmileService_PBI/Home/EmbedReportRLS?UserName=${payload}`, { headers: header });
    }
}

export default new URLS();

