import { React, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography, Divider, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { useFormik, Form, FormikProvider, isNaN } from 'formik';
import axios from 'axios';
import { startOfMonth, endOfMonth } from 'date-fns';
import Moment from 'moment';
import { handleError } from '../../../components/Common/Utils';
import AppCache from '../../../services/AppCache';
import URLS from '../../../services/urls.service';


import ModalPopUp from '../../../components/Common/ModalPopUp';
import Page from '../../../components/Page';
import IssueCloserDashboard from '../../../components/SidebarMenuComponents/ApprovalComponent/IssueCloserDashboard';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';


// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
export default function IssueCloser() {
    const columns = [
        {
            field: 'formattedRequest',
            headerName: 'Service Request Details',
            
        },
        {
            field: 'RequestStatus',
            headerName: '',
            flex: 2, // 20% of the available width
            align: 'right',
            headerAlign: 'right', // Align header to the right as well
        },
         {
            field: "country",
            editable: true,
            type: "singleSelect",
            valueOptions: ["United Kingdom", "Spain", "Brazil"]
        }
    ];
    const row = [
        {
            id: 1,
            headerName: 'Service Request Details',
            
        },
        {
            id: 1,
         
            headerName: 'RequestStatus',
           
        },
    ];

    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState(CurrentLoggedInUser.ExecutiveId || '');

    const { enqueueSnackbar } = useSnackbar();

    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const [value, setValue] = useState(0);
    const [hide, setHide] = useState(false);

    const [effectiveFromValue, setEffectiveFromValue] = useState(startOfMonth(new Date()));
    const [effectiveToValue, setEffectiveToValue] = useState(endOfMonth(new Date()));

    const [historyFromValue, setHistoryFromValue] = useState(startOfMonth(new Date()));
    const [historyToValue, setHistoryToValue] = useState(endOfMonth(new Date()));

    const [isLoading, setIsLoading] = useState();

    const [historyData, setHistoryData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    const [pendingListData, setPendingListData] = useState([]);
    const [ticketData, setTicketData] = useState([]);

    const [ticketActionDropdown, setTicketActionDropdown] = useState({});
    const [historyActionDropdown, setHistoryActionDropdown] = useState({});

    const [reOpenCheckbox, setReOpenCheckbox] = useState({});
    const [remarkText, setRemarkText] = useState({});


    const [fieldSets, setFieldSets] = useState([
        {
            OAMasterDetailsId: 0,
            oaProductId: "",
            Remark: "",
            Rate: "",
            Quantity: "",
            Month: "",
            SeqNo: "",
            OAPayementTerm: ""
        },
    ]);

    // message
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };

    // cancle confirm
    const forCancelConfirm = (value) => {
        if (value) {
          /*  navigate('/dashboard/oa/oamaster', { replace: true }); */
        }
        else {
            setOpenModalCancel(false);
        }
    };

    // Tabs
    const handleChangeValue = (event, newValue) => {
        setValue(newValue);
    };

    // Hide
    const handleHide = () => {
        setHide(true)
    };

    // show
    const handleShow = () => {
        setFieldSets((prevFieldSets) => {
            const updatedFieldSets = [...prevFieldSets];
            return updatedFieldSets;
        });
        setHide(false)
    };

    // cancel
    const handelCancelClick = () => {
        setOpenModalCancel(true);
    };

    const handleEffFrom = (newValue) => setEffectiveFromValue(newValue);
    const handleEffTo = (newValue) => setEffectiveToValue(newValue);

    const handleHistoryFrom = (newValue) => setHistoryFromValue(newValue);
    const handleHistoryTo = (newValue) => setHistoryToValue(newValue);
    const [modifiedRows, setModifiedRows] = useState({});
    const handleAddButtonClick = () => {
        setFieldSets((prevFieldSets) => {
        
            const newFieldSet = {
                oaProductId: '',
                Quantity: '',
                Rate: '',
                Month: '',
                OAPayementTerm: '',
                Remark: '',
                SeqNo: '',
            };

            return [
                ...prevFieldSets,
                newFieldSet
            ];
        });
    };


    // filed
    const handleFieldChange = (index, fieldName, value) => {
        setFieldSets((prevFieldSets) => {
            const updatedFieldSets = [...prevFieldSets];
            // Update the specific field in the field set
            updatedFieldSets[index][fieldName] = value;
            // If the field being updated is "ProductPayemntId", update all matching payment terms
            
            return updatedFieldSets;
        });
    };
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);

    }, [])

    const handleActionChange = (event, issueId) => {
        const {value} = event.target;
        setTicketActionDropdown(prevState => ({
            ...prevState,
            [issueId]: value // Assigns 1 for Reopened and 0 for Closed
        }));
    };
    const handleHistoryActionChange = (event, issueId) => {
        const { value } = event.target;
        setHistoryActionDropdown((prevActions) => ({
            ...prevActions,
            [issueId]: value, // Use IssueId as the key for specific row control
        }));
    };
    const handleReOpenChange = (event, issueId) => {
        const isReopen = event.target.checked;
        setModifiedRows((prev) => ({
            ...prev,
            [issueId]: {
                ...(prev[issueId] || {}),
                IsReopen: isReopen,
            },
        }));
    };
    const handleRemarkChange = (event, issueId) => {
        const remark = event.target.value;
        setModifiedRows((prev) => ({
            ...prev,
            [issueId]: {
                ...(prev[issueId] || {}),
                IssuesReopenRemark: remark,
            },
        }));
    };


    console.log("userId", userId, CurrentLoggedInUser.ExecutiveId)

    const onGenerate = () => {
        setIsLoading(true);

        const formattedFromDate = Moment(effectiveFromValue).format('DD-MMM-yyyy');
        const formattedToDate = Moment(effectiveToValue).format('DD-MMM-yyyy');
        const payload = {
            Id: userId,
            FromDate: formattedFromDate,
            ToDate: formattedToDate,

        };
        URLS.loadDashboardIssueCloserPendingList(payload)
                .then((response) => {
                    setIsLoading(false);
                    setSummaryData(response.data.HistoryIssues || []);
                    setPendingListData(response.data.PendingIssues || []);
                })
                .catch(error => {
                    handleError(error);
                });
       
    }

    const onGenerateHistory = () => {
        setIsLoading(true);

        const formattedFromDate = Moment(effectiveFromValue).format('DD-MMM-yyyy');
        const formattedToDate = Moment(effectiveToValue).format('DD-MMM-yyyy');
        const payload = {
            Id: userId,
            FromDate: formattedFromDate,
            ToDate: formattedToDate,

        };
        URLS.loadTicketIssueCloserPendingList(payload)
            .then((response) => {
                setIsLoading(false);
                setHistoryData(response.data.HistoryIssues || []);

            })
            .catch(error => {
                handleError(error);
            });

    }
    useEffect(() => {
        setIsLoading(true);
        const formattedFromDate = Moment(effectiveFromValue).format('DD-MMM-yyyy');
        const formattedToDate = Moment(effectiveToValue).format('DD-MMM-yyyy');
        const payload = {
            Id: userId,
            FromDate: formattedFromDate,
            ToDate: formattedToDate,
            
        };
        /* if (userId) { */
            URLS.loadTicketIssueCloserPendingList(payload)
                .then((response) => {
                    setIsLoading(false);
                    setTicketData(response.data.PendingIssues || []);
                    setHistoryData(response.data.HistoryIssues || []);
                })
                .catch(error => {
                    handleError(error);
                });
        // }
    }, [])

    const oaMasterSchema = Yup.object().shape({
        ClientId: Yup.string()
            .required('Please select a client'),
        PaymentTerm: Yup.string().required('Please select a payment term'),

    });
    const saveTicket = () => {
        const payload = Object.keys(ticketActionDropdown).map(issueId => {
            const rowData = ticketData.find(row => row.IssueId === parseInt(issueId, 10)) || {};
            return {
                Id: userId,
                IssueId: parseInt(issueId, 10),
                ClientId: rowData.ClientId || 0,
                IsReopen: ticketActionDropdown[issueId] === "Reopened", // true if Reopened
                IssuesReopenRemark: modifiedRows[issueId]?.IssuesReopenRemark || ""
            };
        });

        // Send each payload entry individually
        payload.forEach(item => {
            URLS.saveTicketIssueCloserPendingList(item)
                .then(response => {
                    console.log("Response:", response.data);
                    enqueueSnackbar("Record saved successfully", { variant: 'success' });
                })
                .catch(error => {
                    console.error("Error:", error);
                    enqueueSnackbar("Record not saved", { variant: 'error' });
                });
        });
    };

    // const saveTicket = () => {
    //    // Create a payload array to hold all ticket data
    //    const payload = Object.keys(ticketActionDropdown).map(issueId => {
    //        // Find the corresponding row data in ticketData based on IssueId
    //        const rowData = ticketData.find(row => row.IssueId === parseInt(issueId, 10)) || {};

    //        return {
    //            Id: userId,  // Assuming userId is defined correctly
    //            IssueId: parseInt(issueId, 10),  // Parse the issueId as integer
    //            ClientId: rowData.ClientId || 0,  // Default to 0 if ClientId is not found
    //            IsReopen: ticketActionDropdown[issueId] === "Reopened",  // true if Reopened
    //            IssuesReopenRemark: modifiedRows[issueId]?.IssuesReopenRemark || ""  // Use remark if it exists
    //        };
    //    });

    //    // Now send the payload array directly to the API
    //    // Make sure the structure matches the expected format
    //    URLS.saveTicketIssueCloserPendingList(payload)
    //        .then(response => {
    //            console.log("Response:", response.data);
    //            enqueueSnackbar("Records saved successfully", { variant: 'success' });
    //        })
    //        .catch(error => {
    //            console.error("Error:", error);
    //            enqueueSnackbar("Records not saved", { variant: 'error' });
    //        });
    // };

    const saveHistory = () => {
        const payload = Object.keys(modifiedRows).map(issueId => ({
                Id: userId,
                IssueId: parseInt(issueId, 10),
                IsCheck: modifiedRows[issueId].IsReopen || false,
                IssuesReopenRemark: modifiedRows[issueId].IssuesReopenRemark || ""
            }));

        URLS.saveIssueCloserPendingList(payload)
            .then((response) => {
                console.log("Response:", response.data);
                enqueueSnackbar("Records saved successfully", { variant: 'success' });


            })
            .catch(error => {
                console.error("Error:", error);
                enqueueSnackbar("Records not saved", { variant: 'error' });
            });
        console.log("history>payload", payload)
    }
    const formik = useFormik({
        initialValues: {
            
        },

        validationSchema: oaMasterSchema,

        onSubmit: (values, actions) => {

            const payload = {
              
            };
            console.log("values>>>>>>>>", values)
          
        },
    });
    console.log("summaryData", summaryData, pendingListData)
    
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <Page title="  Issue Closer | tiketic" >
            <FormikProvider value={formik}>
                <Form variant="body2" autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >
                        Issue Closer Pending List
                    </Typography>

                    <ContentStyle>
                        
                        <IssueCloserDashboard formik={formik} value={value} handleChangeValue={handleChangeValue} handelCancelClick={handelCancelClick}
                            hide={hide} handleHide={handleHide} handleShow={handleShow} fieldSets={fieldSets} handleAddButtonClick={handleAddButtonClick} handleFieldChange={handleFieldChange}
                            handleEffFrom={handleEffFrom} handleEffTo={handleEffTo} effectiveFromValue={effectiveFromValue} effectiveToValue={effectiveToValue} columns={columns} row={row}
                            onGenerate={onGenerate} handleHistoryFrom={handleHistoryFrom} handleHistoryTo={handleHistoryTo} historyFromValue={historyFromValue} historyToValue={historyToValue}
                            summaryData={summaryData} pendingListData={pendingListData} historyData={historyData} ticketData={ticketData} handleActionChange={handleActionChange}
                            ticketActionDropdown={ticketActionDropdown} handleHistoryActionChange={handleHistoryActionChange} historyActionDropdown={historyActionDropdown}
                            handleReOpenChange={handleReOpenChange} reOpenCheckbox={reOpenCheckbox} handleRemarkChange={handleRemarkChange} remarkText={remarkText} onGenerateHistory={onGenerateHistory}
                            saveTicket={saveTicket} saveHistory={saveHistory} modifiedRows={modifiedRows }
                            />
                    </ContentStyle>

                   
                </Form>
                {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

            </FormikProvider>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}

        </Page>
    );
}

