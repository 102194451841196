
import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { Stack, TextField, Tooltip, Menu, Fade, Grid, IconButton, MenuItem, Button, paymentId, Box, Typography, Tabs, Tab, TextareaAutosize, Card, Autocomplete, Chip, RadioGroup, Radio, Divider } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { styled } from '@mui/material/styles';
import Moment from 'moment';

import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import IssueCloserTicket from './IssueCloserTicket';
import IssueCloserHistory from './IssueCloserHistory';

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};

const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #4e342e',
    position: 'sticky',
    '& .MuiTabs-indicator': {

    },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontsize: '20px',
    color: '#b71c1c',
    Transition: '2s',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#4e342e',

        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4e342e',

        fontWeight: theme.typography.fontWeightMedium,

    },
    '&.Mui-focusVisible': {
        backgroundColor: '#fff',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const remarkTextFieldStyle = {
    width: '48.5%'
}

export default function IssueCloserDashboard({ handleShow, handleChangeValue, handelCancelClick,
    value, effectiveFromValue, effectiveToValue, handleEffFrom, handleEffTo, columns, row, onGenerate,
    handleHistoryFrom, handleHistoryTo, historyFromValue, historyToValue, summaryData, pendingListData,
    ticketData, historyData, handleActionChange, ticketActionDropdown, historyActionDropdown, handleHistoryActionChange,
    handleReOpenChange, reOpenCheckbox, handleRemarkChange, remarkText, onGenerateHistory, saveTicket, saveHistory, modifiedRows
    
}) {
    const summary = [
        { field: 'IssueCategory', headerName: 'Issue Category', flex: 1 },
        { field: 'Raised', headerName: 'Raised', flex: 1 },
        { field: 'Closed', headerName: 'Closed', flex: 1 },
        { field: 'Resolved', headerName: 'Resolved', flex: 1 },
        { field: 'Pending', headerName: 'Pending', flex: 1 }
    ];

    const columnsPending = [
        { field: 'Date', headerName: 'Date', flex: 1, renderCell: (params) => Moment(params.value).format('YYYY-MM-DD') },
        { field: 'TicketAge', headerName: 'Ticket Age', flex: 1 },
        { field: 'SiteName', headerName: 'Delear Name', flex: 1 },
        { field: 'CategoryName', headerName: 'Category', flex: 1 },
        { field: 'ReportedBy', headerName: 'Issue / Service Requests From', flex: 1 },
        { field: 'IssueRemark', headerName: 'Description of the Problem', flex: 1 },
        { field: 'IssuedOwned', headerName: 'Assigned to', flex: 1 },
        { field: 'Status', headerName: 'Status', flex: 1 },
        { field: 'ServiceProvided', headerName: 'Comments', flex: 1 },

    ];

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <AntTabs value={value} onChange={handleChangeValue} variant="fullWidth" aria-label="basic tabs example">
                    <AntTab onClick={handleShow} label="Dashboard" {...a11yProps(0)} />
                    <AntTab onClick={handleShow} label=" Ticket" {...a11yProps(1)} />
                    <AntTab onClick={handleShow} label=" History" {...a11yProps(2)} />
            </AntTabs >
            </Box>
            <TabPanel value={value} index={0}>
            <Stack spacing={2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="From Date"
                            inputFormat="dd/MMM/yyyy"
                                value={Moment(effectiveFromValue).format('YYYY/MM/DD')}
                                onChange={handleEffFrom}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="To Date"
                            inputFormat="dd/MMM/yyyy"
                                value={Moment(effectiveToValue).format('DD-MMM-yyyy')}
                                onChange={handleEffTo}  
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Generate">

                            <Button
                                variant="contained"
                                size="small"
                                    onClick={onGenerate}
                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >

                        <Tooltip title="Export">
                            <IconButton id="fade-button"
                                aria-haspopup="true"
                                onClick={{}} sx={{ marginBottom: 1 }} >
                                <Icon height="15px" icon="ic:baseline-density-small" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}

                        >
                            <MenuItem onClick={{}}>  <Button sx={{ color: 'black' }} size="small" onClick={{}}>Export to excel</Button></MenuItem>


                        </Menu>

                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}
                    <Grid container spacing={2} style={{ height: '70vh' }}>
                        <Grid item xs={6}>
                            <DataGrid
                                rows={summaryData}
                                density="compact"
                                columns={summary}
                                getRowId={(row) => row.id || summaryData.indexOf(row)}
                                components={{ Toolbar: GridToolbar }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DataGrid
                                rows={pendingListData}
                                density="compact"
                                columns={columnsPending}
                                getRowId={(row) => row.id || pendingListData.indexOf(row)}
                                components={{ Toolbar: GridToolbar }}
                            />
                        </Grid>
                    </Grid>
                
            </Stack>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <IssueCloserTicket columns={columns} row={row} handelCancelClick={handelCancelClick} ticketData={ticketData} handleActionChange={handleActionChange} ticketActionDropdown={ticketActionDropdown}
                    saveTicket={saveTicket} 
                />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <IssueCloserHistory columns={columns} row={row} handelCancelClick={handelCancelClick} handleHistoryFrom={handleHistoryFrom} handleHistoryTo={handleHistoryTo} historyFromValue={historyFromValue}
                    historyToValue={historyToValue} handleHistoryActionChange={handleHistoryActionChange} historyActionDropdown={historyActionDropdown} historyData={historyData}
                    handleReOpenChange={handleReOpenChange} reOpenCheckbox={reOpenCheckbox} handleRemarkChange={handleRemarkChange} remarkText={remarkText} onGenerateHistory={onGenerateHistory}
                    saveHistory={saveHistory} modifiedRows={modifiedRows }
                
                />
            </TabPanel>
        </>
        
    );
}