import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
    Grid
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------
const Status = [
    { id: -1, Name: 'All' },
    { id: 0, Name: 'Pending' },
    { id: 1, Name: 'Completed' },
    { id: 2, Name: 'Cancelled' },
];

export default function IssueDeliveryDatePage() {

    const TABLE_HEAD = [
        { field: 'IssueId', headerName: 'Issue Id', width: 150 },
        {
            field: 'DeliveryDate', headerName: 'Delivery date', width: 300, valueFormatter: params =>
                Moment(params?.value).format("DD-MM-yyyy HH:mm")
},
        {
            field: 'CommittedOn', headerName: ' Committed on', width: 300, valueFormatter: params =>
                Moment(params?.value).format("DD-MM-yyyy HH:mm")
        },
        {
            field: 'CreatedBy', headerName: ' Committed By', width: 300
        },
       

    ];

    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    // Dates
    const [effectiveFromValue, setEffectiveFromValue] = useState(startOfMonth(new Date()));

    // issue 
    const [issueData, setIssueData] = useState([]);

    // Loading
    const [isLoading, setIsLoading] = useState();
    const [issueId, setIssueId] = useState();
    const [execData, setExecData] = useState([]);
    const [execMap, setExecMap] = useState({});
    // Dates 
    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };

    const handleIssueChange = () => {
        if (issueId) {
            const validatePayload = { IssueId: issueId };
            URLS.validateIssueId(validatePayload)
                .then((response) => {
                    if (response.status === 200 && response.data.status === "true") {
                        enqueueSnackbar(response.data.message, { variant: 'success' });

                    }
                    else {
                        enqueueSnackbar(response.data.message, { variant: 'error' });

                    }
                })

        }
       
    }
    const [data, setData] = useState([])

    useEffect(() => {
        URLS.getExecutivesDropDown()
            .then((response) => {
                const map = response.data.reduce((acc, exec) => {
                    acc[exec.Id] = exec.Name;
                    return acc;
                }, {});
                setExecData(response.data);
                setExecMap(map)
            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
    }, []);

    // Filter
    const onFilterClick = () => {
        setIsLoading(true);
        const payload = { IssueId: issueId, DeliveryDate: "" }
        URLS.getIssueDeliveryDateListing(payload)
            .then((response) => {
                const transformedData = response.data.map(item => ({
                    ...item,
                    CreatedBy: execMap[item.CreatedBy] || '' // Replace ID with Name
                }));
                setIssueData(transformedData);
                setIsLoading(false);
                enqueueSnackbar("Fetch data successfully", { variant: 'success' });

            })
            .catch(error => {
             
                enqueueSnackbar("Data Not Found", { variant: 'error' })

            });

    };

    // Reset
    const handleReset = () => {
        setIssueId('');
        setIssueData([])
    }


    useEffect(() => {
        setIssueId('');
        setIssueData([])
    }, [location.key]);

    console.log("landing page", issueData)



    return (

        <Page title="Issue Delivery Date" >

            <Card >

                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>

                    <TextField
                        fullWidth
                        size="small"
                        label="Issue Id"
                        type='number'
                                value={issueId}
                                onChange={(e) => setIssueId(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Tab') {
                                        handleIssueChange()
                                        console.log("tav>>>>>>>>>>>>>>>>>")
                                    }
                                }}
                    />
                        </Grid>
                    </Grid>
                   
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={onFilterClick}

                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >

                        <Tooltip title="Add New ">

                            <Button
                                component={RouterLink}
                                to='/dashboard/issuedeliverydatedetailspage'
                                variant="contained"
                                size="small"
                                state={{ issueData: { Id: '', OADetailsId: '', ClientId: '', Remark: '', IssueId: '', InstallationStatusUpdateDateTime: new Date(), CreatedBy: '', InstallationReportDetailsModel: { InstallationReportDetailsId: '', InstallationReportMasterId: '', InstallationReportQuestionnairesMasterId: '', QuestionId: '', Answers: '' } } }}
                            ><Icon icon="ic:baseline-plus" width="25px" height="25px" /></Button>

                        </Tooltip >

                        <Tooltip title="Reset">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleReset}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>

                        </Tooltip >

                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%' }}>
                    <DataGrid rows={issueData} density={'compact'} columns={TABLE_HEAD} getRowId={(row) => issueData.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}