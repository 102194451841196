import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

// material
import {
    Card,
    IconButton,
    Stack,
    Typography,
    Button,
    Modal,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Box,
    Tooltip,
    TextField
} from '@mui/material';
import { startOfMonth, endOfMonth } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import Page from '../../Page';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';


// ----------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 10,
    p: 2,
};

export default function RequestMaster() {
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    const location = useLocation()
    const requestedId = location.state ? location.state.requestedId : null;
    const statusId = location.state ? location.state.statusId : null;
    const fromDate = location.state ? location.state.fromDate : new Date();
    const toDate = location.state ? location.state.toDate : new Date();
    const [requestMasterData, setRequestMasterData] = useState([]);
    const [isStatus, setIsStatus] = useState([])
  
   // console.log("isStatus", isStatus);
    const [levelsnumbers, setLevelsNumbers] = useState();

    useEffect(() => {
        URLS.getLevelsData()
            .then((response) => {
                setLevelsNumbers(response.data);
               // console.log("response.data children", response.data);
            })
            .catch(error => {
                // On error
                console.log("Error");
            });
    }, []);

    const TABLE_HEAD = [

        { field: 'Name', headerName: 'Request Category', width: 150 },
        {
            field: 'AccessFromDate', headerName: 'From Date', width: 200, valueFormatter: params =>
                Moment(params?.value).format("DD-MMM-yyyy HH:mm")
        },

        {
            field: 'AccessToDate', headerName: 'To Date', width: 200, valueFormatter: params =>
                Moment(params?.value).format("DD-MMM-yyyy HH:mm")
        },

        { field: 'Remarks', headerName: 'Remark', width: 150},
        { field: 'Status', headerName: 'Status', width: 150, flex: 1  },
        {
            field: 'view',
            headerName: '',
            type: 'view',
            width: 20,
            renderCell: (cellValues, isDisable, requestedId, statusId, fromDate,toDate) => {
                return (<div>

                    <IconButton
                        onClick={(event) => {
                            handleClick(event, cellValues, isDisable, requestedId, statusId, fromDate,toDate);
                        }}><Iconify icon="ic:round-pageview" width='20px' height='20px' color='primary.main' /></IconButton>


                </div>
                );
            }

        },
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
            renderCell: (cellValues, isEdit) => {
                if (!levelsnumbers || levelsnumbers.length === 0) {
                    return null; // or any loading indicator component
                }

                // Check if levelsNumbers is available and not empty
                const levelsForCurrentId = levelsnumbers.filter(item => item.RequestSlipMasterId === cellValues.row.Id);

                // Check if there is exactly one entry for the specific RequestSlipMasterId and its LevelNo is 0
                const hasSingleRecordWithLevelNoZero = levelsForCurrentId.length === 1 && levelsForCurrentId[0].LevelNo === 0;

                // Check if loginId is not equal to requestedById
               
                const isLoginIdNotEqualToRequestedById = userId === cellValues.row.RequestedById;
               
                // If there is exactly one entry with LevelNo 0, and loginId is not equal to requestedById, show the edit button
                if (hasSingleRecordWithLevelNoZero && isLoginIdNotEqualToRequestedById) {
                    return (
                        <div>
                            <IconButton
                                onClick={(event) => {
                                    handleClickEdit(event, cellValues, isEdit);
                                }}
                            >
                                <Iconify icon="ic:outline-edit" width='20px' height='20px' color='primary.main' />
                            </IconButton>
                        </div>
                    );
                }

                // If there are zero entries, or more than one entry, or the LevelNo is not 0, or loginId is equal to requestedById, hide the edit button
                return null;

            }
        }




    ];
    const { enqueueSnackbar } = useSnackbar();


    const [isLoading, setIsLoading] = useState();
    
    const [requestCategory, setRequestCategory] = useState([]);
    const [requestId, setRequestId] = useState(requestedId);
    const [requestStatus, setRequestStatus] = useState([]);
    const [requestStatusId, setRequestStatusId] = useState(2 || statusId);
    const [executivesChildrens, setExecutivesChildrens] = useState([]);
    const [ExecutiveChildrenlistId, setExecutiveChildrenId] = useState(executivesChildrens.length > 2 ?CurrentLoggedInUser.ExecutiveId : '');

    const handelSelectedExecutive = (id) => {
       
        setExecutiveChildrenId(id);
    };


    const handleClickEdit = (event, cellValues, isEdit) => {

        navigate('/dashboard/requestmasterdetailspage', { state: { request: cellValues.row, isEdit: true } })
    };
    const handleClick = (event, cellValues, isDisable, requestedId, statusId, fromDate, toDate) => {
        navigate('/dashboard/requestmasterdetailspage', { state: { request: cellValues.row, isDisable: true, requestedId: requestId, statusId: requestStatusId, fromDate: effectiveFromValue, toDate: effectiveToValue } })
    };

    // console.log("requestStatusId", requestStatusId);
    const handleSelectedRequest = (id) => {
        setRequestId(id);
    };
    const handleSelectedRequestStatus = (id) => {
        setRequestStatusId(id);
    };
   
   // console.log("requestId req", requestId)
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);
    const UserID = userId;
    const [effectiveFromValue, setEffectiveFromValue] = useState(startOfMonth(new Date()) || fromDate); 
    const [effectiveToValue, setEffectiveToValue] = useState(endOfMonth(new Date()) || toDate);
    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };
    const handleEffTo = (newValue) => {
        setEffectiveToValue(newValue)
    };
   // console.log("effectiveFromValue", effectiveFromValue)
   // console.log("effectiveToValue", effectiveToValue)
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        const LoginId = CurrentLoggedInUser.ExecutiveId;
        const payload = { ExecutiveID: LoginId }
        URLS.getRequestMasterDropDownLandingPage(payload)
            .then((response) => {

                setRequestCategory(response.data);

            })
            .catch(error => {
                handleError(error);
            });
        URLS.getApprovalStatusConfirmation(payload)
            .then((response) => {

                setIsStatus(response.data);

            })
            .catch(error => {
                handleError(error);
            });

        URLS.getRequestStatus()
            .then((response) => {

                setRequestStatus(response.data);

            })
            .catch(error => {
                handleError(error);
            });
        // const payload1 = { ExecutiveID: contact1Id }
        URLS.getExecutiveData(payload)
            .then((response) => {
                setExecutivesChildrens(response.data);
               // console.log("response.data children", response.data)
            })
            .catch(error => {
                // On error
                console.log("Error");
            });




        if (requestedId && statusId && fromDate && toDate) {
            const payload1 = { ExecutiveID: LoginId, RequestCategoryID: requestId, AccessFromDate: effectiveFromValue, AccessToDate: effectiveToValue, RequestStatusID: requestStatusId }
            URLS.getRequestMasterFilterData(payload1)
                .then((response) => {

                    setRequestMasterData(response.data);
                   // console.log("Response.data", response.data);
                })
                .catch(error => {
                    handleError(error);
                });

        }
      

     



    }, []);


    const navigate = useNavigate();
    const [snackbarDisplayed, setSnackbarDisplayed] = useState(false);

    const onFilterClick = () => {
        setRequestMasterData([]);
        setIsLoading(true)
        /* if (requestId) { */
            // setUserId(CurrentLoggedInUser.ExecutiveId);
            // const LoginId = CurrentLoggedInUser.ExecutiveId;
        const payload = { ExecutiveID: userId, RequestCategoryID: requestId, AccessFromDate: effectiveFromValue, AccessToDate: effectiveToValue, RequestStatusID: requestStatusId, ExecutiveSelectedID: ExecutiveChildrenlistId}
        URLS.getRequestMasterFilterData(payload)
            .then((response) => {
                if (response.data.length === 0 && !snackbarDisplayed) {
                    setRequestMasterData([]);
                    setSnackbarDisplayed(true);
                    enqueueSnackbar("No data", { variant: 'warning' });
                } else {
                    // Check if new records are fetched before updating the state
                    const newRecordsFetched = response.data.length > 0 && response.data.length !== requestMasterData.length;

                    if (newRecordsFetched) {
                        setRequestMasterData(response.data);
                        enqueueSnackbar("Records Fetched successfully", { variant: 'success' });
                    }
                }
                setIsLoading(false);

            })
            .catch(error => {
                handleError(error);
            });
          
            

        // } else {
        //    const payload = { ExecutiveID: UserID };

        //    URLS.getRequestMasterMaster(payload)
        //        .then((response) => {
        //            if (response.data.length === 0) {
        //                enqueueSnackbar("No data", { variant: 'warning' });
        //            } else {
        //                setIsStatus(response.data);
        //                setRequestMasterData(response.data);
        //            }
        //        })
        //        .catch(error => {
        //            handleError(error);
        //        });

        // }



    }

    const handelChangetRequestStatus = () => {
        setRequestStatusId("");


    };

    const handelChangetExecutive = () => {
        setExecutiveChildrenId("");


    };

    const handelChangeRequest = () => {
        setRequestId('');
    }

    const onResetClick = (event) => {
        setRequestId('');
        setRequestMasterData([]);
        setRequestStatusId(2);
        setExecutiveChildrenId('');
        setEffectiveFromValue(startOfMonth(new Date()))
        setEffectiveToValue(endOfMonth(new Date()))

    };

    useEffect(() => {
        setRequestId('');
        setRequestMasterData([]);
        setRequestStatusId(2);
        setExecutiveChildrenId('');
        setEffectiveFromValue(startOfMonth(new Date()))
        setEffectiveToValue(endOfMonth(new Date()))
    }, [location.key]); 

    const [pageSize, setPageSize] = useState(10);
    const [selectedRow, setSelectedRow] = useState(null);
    useEffect(() => {
        const handleDocumentClick = (event) => {
            const gridContainer = document.querySelector('.MuiDataGrid-root'); // get the DataGrid container element
            if (gridContainer && !gridContainer.contains(event.target)) {
                setSelectedRow(null); // deselect the row if the click target is outside the DataGrid
            }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [setSelectedRow]);

    return (

        <Page title="Request Master" >


            <Card >
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>

                    <FormControl
                        fullWidth
                        size="small" >
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Request Category</InputLabel>
                        <Select
                            label="Request Category"
                            size="small"
                            value={requestId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}>
                            <MenuItem key={0} value={""} onClick={() => handelChangeRequest()} >Select</MenuItem>
                            {requestCategory.map((req) => (
                                <MenuItem key={req.RequestCategoryId} value={req.RequestCategoryId} onClick={() => handleSelectedRequest(req.RequestCategoryId)}> {req.Name}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>

                    <FormControl
                        fullWidth
                        size="small" >
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Request Status</InputLabel>
                        <Select
                            label="Request Status"
                            size="small"
                            value={requestStatusId}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}>
                            <MenuItem key={0} value={""} onClick={() => handleSelectedRequestStatus()} >Select</MenuItem>
                            {requestStatus.map((req) => (
                                <MenuItem key={req.Id} value={req.Id} onClick={() => handleSelectedRequestStatus(req.Id)}> {req.Status}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>



                    {executivesChildrens.length > 2 ? (
                        <FormControl
                            fullWidth
                            size="small"
                        >
                            <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                Executive
                            </InputLabel>
                            <Select
                                value={ExecutiveChildrenlistId}
                                label="Executive"
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7', // Set the background color here
                                            maxHeight: '200px',
                                            width: '200px', // Set the max height here
                                        },
                                    },
                                }}
                            >
                                <MenuItem key={0} value={""} onClick={() => handelChangetExecutive()}>
                                    Select
                                </MenuItem>
                                {executivesChildrens.map((project) => (
                                    <MenuItem
                                        key={project.Id}
                                        value={project.Id}
                                        onClick={() => handelSelectedExecutive(project.Id, project.Name)}
                                    >
                                        {project.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : null}


                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="From Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveFromValue).format('DD-MMM-yyyy')}
                            onChange={handleEffFrom}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="To Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveToValue).format('DD-MMM-yyyy')}
                            onChange={handleEffTo}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={onFilterClick}
                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >


                        <Tooltip title="Add New ">

                            <Button
                                component={RouterLink}
                                to='/dashboard/requestmasterdetailspage'
                                variant="contained"
                                size="small"
                                state={{ request: { Id: '', RequestCategoryId: '', Remarks: '', AccessFromDate: new Date(), AccessToDate: new Date(), RequestedById: '', IsFromDate: '', IsInMin: '' } }}
                            ><Icon icon="ic:baseline-plus" width="25px" height="25px" /></Button>

                        </Tooltip >

                        <Tooltip title="Reset">
                            <Button
                                variant="contained"
                                size="small"
                                onClick={onResetClick}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>
                        </Tooltip >
                    </Stack>
                </Stack>

                <Divider />


                <div style={{ height: '65vh', width: '100%' }}>
                    <DataGrid disableSelectionOnClick rows={requestMasterData} density={'compact'} columns={TABLE_HEAD} selectionModel={selectedRow ? [selectedRow.id] : []} pageSize={pageSize} onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} rowsPerPageOptions={[5, 10, 20]} getRowId={(row) => requestMasterData.indexOf(row)} components={{
                        Toolbar: GridToolbar,
                    }} />
                </div>
            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}

        </Page>


    );
}