import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';

// material
import { Tooltip, Stack, TextField, Typography, Modal, FormControl, FormLabel, InputLabel, Select, MenuItem, Button, Grid, RadioGroup, Radio, Divider } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import TimePicker from '@mui/lab/TimePicker';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
// -----------------------------------------------------------------------------------------------------


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: '#fbe9e7', // Set the background color to #fbe9e7
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));


const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};


export default function FillInstallationReportDetails({ fillInstallation }) {
    console.log("fillInstallation detail ", fillInstallation, fillInstallation.fillInstallation?.FillInstallationReportFilterData,
    )
    const columns = [
        { field: 'activity', headerName: 'Activity', width: 100 },
        { field: 'StartTime', headerName: 'Start Time', width: 100 },
        { field: 'ServiceInMinute', headerName: 'Service In Min', width: 100 },
        { field: 'ServiceRemark', headerName: 'Service Remark', width: 100 }
    ];

    const installationStatus = [
        { id: 1, Name: 'Completed' },
        { id: 2, Name: 'Cancelled' },
    ];

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    // const productId = proMaster.ID;


    const [serviceData, setServiceData] = useState([]);

    // executive ID
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    const [insStatusId, setInsStatusId] = useState(fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].InstallationStatus || '');
    const handleInsStatus = (id) => {
        setInsStatusId(id);

    };
    // Modal
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        navigate('/dashboard/oa/fillintallationreport', { replace: true });
        setOpenModalCancel(false);
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);
    };
 
    const [effectiveFromValue, setEffectiveFromValue] = useState(
        fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].InstallationStatusUpdateDateTime
            ? new Date(fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].InstallationStatusUpdateDateTime)
            : new Date() // Fallback to current date if no value is provided
    );

    const handleEffFrom = (newValue) => {
        setEffectiveFromValue(newValue)
    };

    const [activityData, setActivityData] = useState([]);
    const [activityDataValue, setActivityDataValue] = useState('');
    const [installationRemark, setInstallationRemark] = useState(fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].REMARK || '');
    const [startTime, setStartTime] = useState(fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].StartTime || (new Date()));
    const [serviceInMin, setServiceInMin] = useState(fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].ServiceInMin || '');
    const [serviceRemark, setServiceRemark] = useState(fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].Remark || '');
    const [services, setServices] = useState([]);

    const handleActivity = (id) => {
        setActivityDataValue(id);

    };

    useEffect(() => {
       
        URLS.getActivityDD()
            .then((response) => {
                setActivityData(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
    }, []);

    const handleInstallationRemark = (e) => {
        setInstallationRemark(e.target.value);
    };
    const handleStartTime = (e) => {
        setStartTime(e.target.value);
    };
    const handleServiceInMin = (e) => {
        setServiceInMin(e.target.value);
    };
    const handleServiceRemark = (e) => {
        setServiceRemark(e.target.value);
    };
    const fields = fillInstallation.fillInstallation?.FillInstallationReportFilterData || [];

    const [fieldValues, setFieldValues] = useState({});

    const parentPanels = fields.filter(field => field.ParentId === 0);
    console.log("parentPanels", parentPanels)

    const childFields = fields.filter(field => field.ParentId !== 0);
    console.log("childFields", childFields)

    // Handle changes in form elements
    const handleField = (id, value) => {
        setFieldValues((prevValues) => ({
            ...prevValues,
            [id]: value // Update only the specific field based on its id
        }));
    };
    const getActivityNameById = (id) => {
        console.log('Looking for activity with ID:', id); // Log the ID
        const activity = activityData.find(act => act.Id === Number(id));
        console.log('Found activity:', activity); // Log the activity found, if any
        return activity ? activity.Name : '';
    };

    const handleAddService = () => {
        if (!activityDataValue) {
            enqueueSnackbar("Please select an activity.");
            return;
        }
        if (!startTime) {
            enqueueSnackbar("Please select a start time.");
            return;
        }
        if (!serviceInMin) {
            enqueueSnackbar("Please enter service time in minutes.");
            return;
        }
        if (!serviceRemark) {
            enqueueSnackbar("Please enter a service remark.");
            return;
        }
        const activityName = getActivityNameById(activityDataValue); // Get the name based on the selected ID

        const newService = {
            ClientId: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].ClientId,
            ProjectId: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].ProductId,
            SiteId: 0,
            ActivityId: activityDataValue, // Store the activity ID
            activity: activityName,
            StartTime: startTime,
            ServiceInMinute: serviceInMin,
            ServiceRemark:serviceRemark,
            UserID: userId,
            ServiceTypeId: activityDataValue,
            IssueId: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].IssueId
        };

        setServices(prevData => [...prevData, newService]);
        setServiceData(prevData => [...prevData, newService]);

        // Resetting after adding service
        setActivityDataValue('');
        setStartTime('');  // Ensure correct reset logic for date input
        setServiceInMin('');
        setServiceRemark('');
    };

    const renderField = (fields) => {
        const preSelectedValue = fields.Answers || null; // Get the pre-selected value from the Answers property

        switch (fields.ControlType) {
            case 'TextBox': {
                return (
                    <TextField
                        label={fields.QuestionDescription}
                        fullWidth
                        size="small"
                        onChange={(e) => handleField(fields.Sequence, e.target.value)}
                        value={fieldValues[fields.Sequence] || preSelectedValue}
                    />
                );
            }
            case 'DropDownList': {
                // Check if DataValues is in the "Id: ..., Name: ..." format or pipe-separated format
                let options = [];

                if (fields.DataValues.includes("Id:")) {
                    // Handle the "Id: ..., Name: ..." format
                    const regex = /Id:\s*(\d+),\s*Name:\s*([\w\s]+)/g;
                    const matches = Array.from(fields.DataValues.matchAll(regex)); // Convert matches to an array

                    // Use map to create options array from matches
                    options = matches.map(match => ({
                        id: parseInt(match[1], 10), // Convert id to integer
                        name: match[2]
                    }));

                } else {
                    // Handle the pipe-separated format
                    const dataValuesMatch = fields.DataValues.match(/\[([^[\]]+)\](?=[^[]*$)/); // Match content inside the last set of square brackets
                    options = dataValuesMatch
                        ? dataValuesMatch[1].split('|').map(value => ({ id: value, name: value })) // Create an object with the same id and name for each option
                        : [];
                }

                // Log the parsed options to verify
                console.log("Parsed options:", options);
                return (
                    <FormControl fullWidth size="small">
                        <InputLabel>{fields.QuestionDescription}</InputLabel>
                        <Select
                            label={fields.QuestionDescription}
                            onChange={(e) => handleField(fields.Sequence, e.target.value)}
                            value={fieldValues[fields.Sequence] ?? preSelectedValue ?? ""}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7',
                                        maxHeight: '200px',
                                        width: '200px',
                                    },
                                },
                            }}
                        >
                            <MenuItem key={0} value="">Select</MenuItem>
                            {options.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }

            case 'Date Format': {
                return (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label={fields.QuestionDescription}
                            inputFormat="dd/MMM/yyyy"
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            onChange={(date) => {
                                // Format the date as "dd-MMM-yyyy" before storing it
                                const formattedDate = date ? format(date, 'dd-MMM-yyyy') : null;
                                handleField(fields.Sequence, formattedDate);
                            }}
                            value={fieldValues[fields.Sequence] || preSelectedValue}
                        />
                    </LocalizationProvider>
                );
            }
            case 'RadioButton': {
                // Initialize radio options and pre-selected value
                let radioOptions = [];
                const preSelected = fields.Answers || 'No'; // Use fields.Answers if available, otherwise default to 'No'

                if (fields.DataValues) {
                    // Clean up DataValues to extract relevant options
                    const cleanedDataValues = fields.DataValues
                        .replace(/[{}]/g, '') // Remove curly braces
                        .split(',') // Split by comma to separate values
                        .map(option => option.trim()) // Trim whitespace
                        .filter(option => option.length > 0); // Remove empty entries

                    // Loop through cleaned values to extract radio options
                    cleanedDataValues.forEach(option => {
                        if (option.includes('No')) {
                            radioOptions.push('No'); // Add No option
                        }
                        if (option.includes('Yes')) {
                            radioOptions.push('Yes'); // Add Yes option
                        }

                        // Special case for [Yes|No] meaning both options should be included
                        if (option === '[Yes|No]') {
                            radioOptions = ['Yes', 'No']; // Set both options
                        }
                    });
                }

                // Log an error if no valid options are found
                if (radioOptions.length === 0) {
                    console.error('No valid radio options found for field:', fields.QuestionDescription);
                }

                return (
                    <FormControl component="fieldset">
                        <FormLabel component="legend">{fields.QuestionDescription}</FormLabel>
                        <RadioGroup
                            row
                            value={fieldValues[fields.Sequence] || preSelected} // Use preSelected value from fields.Answers or default to 'No'
                            onChange={(e) => handleField(fields.Sequence, e.target.value)} // Handle changes
                            sx={{ flexDirection: 'row' }}
                        >
                            {radioOptions.map((option, index) => (
                                <FormControlLabel
                                    key={`${fields.QuestionId}-${index}`} // Unique key
                                    value={option} // Clean the value to store
                                    control={<Radio />}
                                    label={option} // Label for display
                                    sx={{ marginRight: 2 }} // Add spacing
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                );
            }

            default: {
                return null;
            }
        }
    };

    const handleSaveDraft = () => {
        const QuestionAnswersData = fillInstallation.fillInstallation?.FillInstallationReportFilterData.map(field => ({
            QuestionId: Number(field.QuestionId), // Using QuestionId from the field
            Answer: fieldValues[field.Sequence] || field.Answers || '' // Use field value or default to Answers if available
        }));

        const installationReportQuestionnairesMasterIds = new Set(
            fillInstallation.fillInstallation?.FillInstallationReportFilterData.map(item => item.InstallationReportQuestionnairesMasterId)
        );

        const installationReportQuestionnairesMasterId = installationReportQuestionnairesMasterIds.size > 0
            ? Array.from(installationReportQuestionnairesMasterIds)[0] // Take the first unique ID
            : 0;

        const formattedDate = effectiveFromValue ? format(effectiveFromValue, 'dd-MMM-yyyy') : '';

        const saveDraftPayload = {
            Id: 0, // Assuming default value for Id, update if necessary
            OAInstallationReportMasterId: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].OAInstallationReportMasterId,
            InstallationReportQuestionnairesMasterId: installationReportQuestionnairesMasterId,
            InstallationStatus: insStatusId,
            InstallationStatusUpdateDateTime: formattedDate,
            Remark: installationRemark || "",
            QuestionAnswers: QuestionAnswersData, // Transformed question-answers array
            Mode: 1,
            ClientId: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].ClientId,
            PONoDate: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].PONumber,
            LoggedinuserId: userId,
            InstallationDate: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].InstallationStatusUpdateDateTime,
            InstallationRemark: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].REMARK
        };
        const servicePayload = {
            saveservice: services, // Array of service objects
        };
         URLS.saveFillInstallationReport(saveDraftPayload)
             .then((response) => {
                
                if (response.status === 200) {
                    enqueueSnackbar("Record saved successfully", { variant: 'success' });
                }
            })
            .catch(error => {
                // handleError(error, enqueueSnackbar);
                enqueueSnackbar("Record not saved.", { variant: 'error' });

            });
        URLS.SaveFillInstallationData(servicePayload)
            .then((response) => {
                if (response.data.status === 200) {
                    enqueueSnackbar("Service record saved successfully", { variant: 'success' });

                }
            })
            .catch(error => {
                // handleError(error, enqueueSnackbar);

            });
        console.log("save draft", saveDraftPayload, servicePayload)
    };

    const handlePrint = () => {
        const QuestionAnswersData = fillInstallation.fillInstallation?.FillInstallationReportFilterData.map(field => ({
            QuestionId: Number(field.QuestionId), // Using QuestionId from the field
            Answer: fieldValues[field.Sequence] || field.Answers || '' ,// Use field value or default to Answers if available
             ParentId: field.ParentId, // Adding ParentId
            QuestionDescription: field.QuestionDescription
        }));

        const installationReportQuestionnairesMasterIds = new Set(
            fillInstallation.fillInstallation?.FillInstallationReportFilterData.map(item => item.InstallationReportQuestionnairesMasterId)
        );

        const installationReportQuestionnairesMasterId = installationReportQuestionnairesMasterIds.size > 0
            ? Array.from(installationReportQuestionnairesMasterIds)[0] // Take the first unique ID
            : 0;

        const formattedDate = effectiveFromValue ? format(effectiveFromValue, 'dd-MMM-yyyy') : '';

        const printPayload = {
            Id: 0, // Assuming default value for Id, update if necessary
            OAInstallationReportMasterId: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].OAInstallationReportMasterId,
            InstallationReportQuestionnairesMasterId: installationReportQuestionnairesMasterId,
            InstallationStatus: insStatusId,
            InstallationDate: formattedDate,
            InstallationRemark: installationRemark || "",
            QuestionAnswers: QuestionAnswersData, // Transformed question-answers array
            Mode: 0 ,// Set to 1 for Save Draft
            "ClientId": fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].ClientId,
            "ClientName": fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].ClientName,
            "ProductName": fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].ProductName,
            "PONoDate": fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].PONumber,
            "DistributorName": fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].DistributorName,
            "DistributorContact": fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].DistributorContact,
            "LoggedinuserId": userId,
        };
       
        URLS.generateFillInstallationPDF(printPayload)
            .then((response) => {
                if (response.data && response.data.filepath) {
                    // Open the PDF file in a new browser tab or window
                    const pdf = response.data.filepath
                    window.open(pdf, '');
                    console.log("filepath>>>>>>>>>>>>>>>>>>>>>>>>.", pdf, response)
                    enqueueSnackbar(" PDF file generated successfully.", { variant: 'success' });

                }
            })
            .catch(error => {
                // enqueueSnackbar("Error generating PDF", { variant: 'error' });
            });
        
        console.log("print", printPayload, )
    };

    console.log("ServiceData", serviceData,services)
    const formik = useFormik({
        initialValues: {
            ProductId: fillInstallation.ProductId,
            SiteId: fillInstallation.Issueid,
            InstallationStatus:  insStatusId || "",
            InstallationStatusUpdateDateTime: effectiveFromValue || "",
            Remark: installationRemark || "",
            Activity: activityDataValue || "",
            StartTime: startTime || "",
            ServiceInMin: serviceInMin || "",
            ServiceRemark: serviceRemark || ""
        },

        onSubmit: (values, actions) => {
            if (!insStatusId) {
                enqueueSnackbar("Please select installation status.");
                return;
            }
            const QuestionAnswersData = fillInstallation.fillInstallation?.FillInstallationReportFilterData.map(field => ({
                QuestionId: Number(field.QuestionId), // Using QuestionId from the field
                Answer: fieldValues[field.Sequence] || field.Answers || '' // Use field value or default to Answers if available
            }));

            const installationReportQuestionnairesMasterIds = new Set(
                fillInstallation.fillInstallation?.FillInstallationReportFilterData.map(item => item.InstallationReportQuestionnairesMasterId)
            );
            const installationReportQuestionnairesMasterId = installationReportQuestionnairesMasterIds.size > 0
                ? Array.from(installationReportQuestionnairesMasterIds)[0] // Take the first unique ID
                : 0;

            const formattedDate = effectiveFromValue ? format(effectiveFromValue, 'dd-MMM-yyyy') : '';

            const payload = {
                Id: 0, // Assuming default value for Id, update if necessary
                OAInstallationReportMasterId: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].OAInstallationReportMasterId, // Update if you have a dynamic ID for InstallationReportMasterId
                InstallationReportQuestionnairesMasterId: installationReportQuestionnairesMasterId,
                InstallationStatus: insStatusId,
                InstallationStatusUpdateDateTime: formattedDate,
                Remark: installationRemark || "",// Update if you have a dynamic ID for InstallationReportQuestionnairesMasterId
                QuestionAnswers: QuestionAnswersData ,// Transformed question-answers array
                Mode: 0,
                ClientId: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].ClientId,
                PONoDate: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].PONumber,
                LoggedinuserId: userId,
                InstallationDate: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].InstallationStatusUpdateDateTime,
                InstallationRemark: fillInstallation.fillInstallation?.ClientProductDistributorDetails[0].REMARK
                
            };
            const servicePayload = {
                saveservice: services , // Array of service objects
            };

            URLS.saveFillInstallationReport(payload)
                .then((response) => {
                    if (response.status === 200 ) {
                                enqueueSnackbar("Record saved successfully", { variant: 'success' });
                                navigate('/dashboard/oa/fillintallationreport', { replace: true });

                    }
                })
                .catch(error => {
                    handleError(error, enqueueSnackbar);
                });
            URLS.SaveFillInstallationData(servicePayload)
                .then((response) => {
                    if (response.data.status === 200) {
                        // enqueueSnackbar("Record saved successfully", { variant: 'success' });
                        enqueueSnackbar("Service record saved successfully", { variant: 'success' });

                    }
                })
                .catch(error => {
                    // enqueueSnackbar("Record not saved.", { variant: 'error' });

                });
            console.log("payload", payload, servicePayload);
        },
    });
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Stack spacing={4}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={null}>
                        <Grid container spacing={2} alignItems="center">
                            {fillInstallation.fillInstallation?.ClientProductDistributorDetails?.length > 0 && (
                                <>
                                    <Grid item xs={12} style={{ textAlign: 'center' }}>
                                        <Typography
                                            variant="h6"
                                            style={{ borderBottom: '1px solid grey', display: 'inline-block' }}
                                        >
                                            <strong>{fillInstallation.fillInstallation.ClientProductDistributorDetails[0]?.ClientName}</strong>
                                        </Typography>
                                    </Grid>


                                    {/* First Row */}
                                    <Grid item xs={12}>
                                        <Stack direction="column" spacing={2} alignItems="flex-start" justifyContent="flex-start">
                                            <Typography>
                                                <strong>Product Name: {fillInstallation.fillInstallation.ClientProductDistributorDetails[0]?.ProductName}</strong>
                                            </Typography>
                                            <Typography>
                                                <strong>Distributor Name: {fillInstallation.fillInstallation.ClientProductDistributorDetails[0]?.DistributorName || ''}</strong>
                                            </Typography>
                                            <Typography>
                                                <strong>Distributor Contact: {fillInstallation.fillInstallation.ClientProductDistributorDetails[0]?.DistributorContact || ''}</strong>
                                            </Typography>
                                            <Typography>
                                                <strong>P.O. No. and Date: {fillInstallation.fillInstallation.ClientProductDistributorDetails[0]?.PONumber || ''}</strong>
                                            </Typography>
                                        </Stack>
                                    </Grid>

                                </>
                            )}
                        </Grid>
                    </AccordionSummary>
                    {parentPanels.map((parent) => (
                        <Accordion key={parent.Sequence}>
                            <AccordionSummary>
                                <Typography variant="h6">{parent.QuestionDescription}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    {childFields
                                        .filter(field => field.ParentId === parent.Sequence) // Correct filter
                                        .map((field) => (
                                                <Grid item xs={4} key={field.Sequence}>
                                                    {renderField(field)}
                                                </Grid>
                                            ))
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Accordion>
                        <AccordionSummary expandIcon={null}>
                            <Grid container spacing={2}>
                                {/* Installation Status Select Field */}
                                <Grid item xs={4}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                            Installation Status:
                                        </InputLabel>
                                        <Select
                                            label="Installation Status"
                                            size="small"
                                            value={insStatusId}
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        backgroundColor: '#fbe9e7',
                                                        maxHeight: '200px',
                                                    },
                                                },
                                            }}
                                            onChange={(event) => handleInsStatus(event.target.value)}
                                        >
                                            {installationStatus.map((category) => (
                                                <MenuItem key={category.id} value={category.id}>
                                                    {category.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/* Installation Date Picker */}
                                <Grid item xs={4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopDatePicker
                                            label="Installation Date"
                                            inputFormat="dd/MMM/yyyy"
                                            value={effectiveFromValue}
                                            onChange={handleEffFrom}
                                            maxDate={new Date()}
                                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                        />
                                    </LocalizationProvider>
                                </Grid>

                                {/* Installation Remark Field */}
                                <Grid item xs={4}>
                                    <TextField
                                        label="Installation Remark"
                                        fullWidth
                                        size="small"
                                        onChange={handleInstallationRemark}
                                        value={installationRemark}
                                        multiline
                                    />
                                </Grid>
                            </Grid>
                        </AccordionSummary>

                    </Accordion>

                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                            <Accordion>
                                <AccordionSummary>
                                    <Typography variant="h6">Service Details</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth size="small">
                                                <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                                    Activity
                                                </InputLabel>
                                                <Select
                                                    label="Activity"
                                                    size="small"
                                                    value={activityDataValue}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                backgroundColor: '#fbe9e7',
                                                                maxHeight: '200px',
                                                            },
                                                        },
                                                    }}
                                                    onChange={(event) => handleActivity(event.target.value)}
                                                >
                                                    {activityData.map((category) => (
                                                        <MenuItem key={category.Id} value={category.Id}>
                                                            {category.Name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                label="Start Time"
                                                type="time"
                                                value={startTime}
                                                onChange={handleStartTime}
                                                inputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    step: 300, // 5 min
                                                }}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                size="small"
                                                label="Service in Min"
                                                type="number"
                                                value={serviceInMin}
                                                onChange={handleServiceInMin}
                                                fullWidth
                                            />
                                        </Grid>

                                        {/* Remark Field and Add Button */}
                                        <Grid item xs={10}>
                                            <TextField
                                                label="Remark"
                                                fullWidth
                                                size="small"
                                                onChange={handleServiceRemark}
                                                value={serviceRemark}
                                                multiline
                                                style={{ width: '60%' }}
                                            />
                                        </Grid>
                                        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
                                            <Tooltip title="Add New Service">
                                                <Button onClick={handleAddService} variant="contained" size="small">
                                                    <Icon icon="ic:baseline-plus" width="25px" height="25px" />
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />

                                            <div style={{ height: '50vh', width: '100%', marginLeft: '20px', marginTop: '10px' }}>
                                                <DataGrid
                                                    rows={serviceData}
                                                    density="compact"
                                                    columns={columns}
                                                    getRowId={(row) => serviceData.indexOf(row)}
                                                    components={{
                                                        Toolbar: GridToolbar,
                                                    }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>



                    {/* ----------------------------------------------------------------- */}

                    {/* <div style={{ height: '50vh', width: '100%', marginLeft: '20px', marginTop:'10px' }}> */}
                    {/*    <DataGrid rows={serviceData} density={'compact'} columns={columns} getRowId={(row) => serviceData.indexOf(row)} components={{ */}
                    {/*        Toolbar: GridToolbar, */}
                    {/*    }} /> */}
                    {/* </div> */}


                    <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>

                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            sx={{ color: 'white' }}
                        >
                            Save
                        </LoadingButton>
                        <Button
                            sx={{ color: 'white' }}
                            onClick={handleSaveDraft}
                            size="medium"
                            variant="contained"
                            color="primary"
                        >
                            Save Draft
                        </Button>
                        <Button
                            sx={{ color: 'white' }}
                            onClick={handlePrint}
                            size="medium"
                            variant="contained"
                            color="primary"
                        >
                            Print
                        </Button>
                        <Button
                            sx={{ color: 'white' }}
                            onClick={handelCancelClick}
                            size="medium"
                            variant="contained"
                            color="primary"
                        >
                            Back
                        </Button>
                      
                    </Stack>
                </Stack>
                
            </Form>
            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

        </FormikProvider >
    );
}