import * as Yup from 'yup';
import * as React from 'react';
// material
import { Stack, Button, Typography, Divider, Tooltip, Select, MenuItem, FormControlLabel, TextField, Checkbox, TextareaAutosize } from '@mui/material';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { styled } from '@mui/material/styles';
import Moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';

const accordionStyle = {
    backgroundColor: '#fbe9e7',
    minHeight: '10px',
    height: '30px',
    marginTop: '10px',
    marginBottom: '10px'
    // Add other CSS properties as needed
};

const remarkTextFieldStyle = {
    width: '48.5%'
}

export default function IssueCloserHistory({ columns, row, fieldSets, handelCancelClick,
    handleHistoryFrom, handleHistoryTo, historyFromValue, historyToValue, historyData, handleRemarkChange, remarkText,
    reOpenCheckbox, handleReOpenChange, onGenerateHistory, saveHistory, modifiedRows
}) {

    const history = [
        { field: 'IssueId', headerName: 'Id', flex: 1 },
        { field: 'Issuedate', headerName: 'Date', flex: 1 },
        { field: 'Remark', headerName: 'Remark', flex: 1 },
        {
            field: 'Action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => {
                const issueId = params.row.IssueId;
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '100%' }}>
                    
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={modifiedRows[issueId]?.IsReopen || false} 
                                    onChange={(event) => handleReOpenChange(event, issueId)}
                                    color="primary"
                                />
                            }
                            label="Re-Open"
                        />
                    </div>
                );
            },
        },
        {
            field: '',
            headerName: '',
            flex: 1,
            renderCell: (params) => {
                const issueId = params.row.IssueId;
                return (
                    <TextareaAutosize
                        minRows={3}
                        placeholder=" Remarks "
                        value={modifiedRows[issueId]?.IssuesReopenRemark || ''}
                        onChange={(event) => handleRemarkChange(event, issueId)}
                        onKeyDown={(event) => {
                            // Prevent default action if necessary
                            if (event.key === ' ') {
                                event.stopPropagation(); // Prevent the event from bubbling up
                            }
                        }}
                         // This allows the textarea to be focused when rendered
                    />
                );
            },
            
        },
      
    ];

    return (
        <Stack>
            <>
                {/* <Typography variant="h5" marginLeft={3} marginBottom={2} > */}
                {/*    Issue Closure History */}
                {/* </Typography> */}
                <Stack spacing={2}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker

                                label="From Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(historyFromValue).format('YYYY/MM/DD')}
                                onChange={handleHistoryFrom}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DesktopDatePicker
                                label="To Date"
                                inputFormat="dd/MMM/yyyy"
                                value={Moment(historyToValue).format('DD-MMM-yyyy')}
                                onChange={handleHistoryTo}
                                renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                            />
                        </LocalizationProvider>

                        <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                            <Tooltip title="Generate">

                                <Button
                                    variant="contained"
                                    size="small"
                                    onClick={onGenerateHistory}
                                ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                            </Tooltip >

                        </Stack>
                    </Stack>
                    <Divider />

                    {/* ----------------------------------------------------------------- */}
                    <div style={{ height: '53vh', width: '100%' }}>
                        <DataGrid
                            rows={historyData}
                            density="compact"
                            columns={history}
                            getRowId={(row) =>  historyData.indexOf(row)}
                            components={{ Toolbar: GridToolbar }}
                        />
                       
                    </div>

                </Stack>
              
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>

                    <LoadingButton
                        size="medium"
                        type="submit"
                        variant="contained"
                        sx={{ color: 'white' }}
                        onClick={saveHistory }
                    >
                        Save
                    </LoadingButton>

                    <Button
                        sx={{ color: 'white' }}
                        onClick={handelCancelClick}

                        size="medium"
                        variant="contained"
                        color="primary"
                    >
                        Close
                    </Button>

                </Stack>
            </>
        </Stack>
       
    );
}