import { React, useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useFormik, Form, FormikProvider } from 'formik';


import XLSX from 'sheetjs-style';

// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
    Grid,
    TextareaAutosize,
    Typography
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import * as FileSaver from 'file-saver';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';
import AppCache from '../../../services/AppCache';
import ModalPopUp from '../../Common/ModalPopUp';
import Dropdown from '../../Dropdown';


// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 1)
}));
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
export default function ServiceRequest() {

    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState('');
    const navigate = useNavigate();

    const [editData, setEditData] = useState([]); // New state for Base64 string

    const [client, setClient] = useState( "");
    const [selectClient, setSelectClient] = useState([]);

    const [site, setSite] = useState("");
    const [selectSite, setSelectSite] = useState([]);

    const [executive, setExecutive] = useState("");
    const [selectExecutive, setSelectExecutive] = useState([]);

    const [moduleName, setModuleName] = useState("");
    const [request, setRequest] = useState("");
    const [requestFrom, setRequestFrom] = useState("");
    const [requestFile, setRequestFile] = useState([]);
    const [base64Document, setBase64Document] = useState([]); // New state for Base64 string

    const [isLoading, setIsLoading] = useState();
    const [openModalForCancel, setOpenModalCancel] = useState(false);

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    
    useEffect(() => {
        URLS.getServiceEntryClientName()
            .then((response) => {

                setSelectClient(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });

        URLS.getRequestFromForClient({ ClientId:client, Name: "" })
            .then((response) => {
                setSelectExecutive(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        
    },[])

    useEffect(() => {
        if (userId) {
           
            URLS.getServiceRequestData({ LoggedInUserId: userId })
                .then((response) => {
                    setEditData(response.data); 
                    if (response.data.length > 0) {
                        setClient(response.data[0]?.ClientId); // Set the pre-selected client after data is loaded
                        setExecutive(response.data[0]?.ExecutiveId || userId);

                    } else {
                        setExecutive(userId); // Default to userId if no ExecutiveId is found
                    }

                })
                .catch(error => {
                    console.log("Error fetching site names:", error); // Log the error for debugging
                });
        }
    }, [userId])

    useEffect(() => {
        if (client) {  // Ensure the client is not empty before making the call
            URLS.getServiceEntrySiteName({ ClientId: client })
                .then((response) => {
                    setSelectSite(response.data); // Update the site selection state with the response data
                })
                .catch(error => {
                    console.log("Error fetching site names:", error);
                });
        }
    }, [client]);
    console.log("exc", executive)

     useEffect(() => {
         setClient('');
         setSite('');
         setModuleName('');
         setExecutive('');
         setRequest('');
         setRequestFrom('');
     }, [location.key]);

    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            setClient('');
            setSite('');
            setModuleName('');
            setExecutive('');
            setRequest('');
            setRequestFrom('');
            // navigate('/requestandapproval/servicerequest', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };
        const handleClient = (event) => {
            const selectedClient = event.target.value; // Get the selected client value
            setClient(selectedClient); // Update the state with the selected client
        
        };

    const handleSite = (event) => {
        setSite(event.target.value); // Same here
    };
    const handleRequest = (event) => {
        setRequest(event.target.value); // Same here
    };
    const handleModule = (event) => {
        setModuleName(event.target.value); // Same here
    };
    const handleExecutive = (event) => {
        setExecutive(event.target.value); // Same here
    };
    const fileInputRef = useRef(null);  // Reference for the file input

    const handleDocument = (event) => {
        const file = event.target.files[0];
        if (file) {
            setRequestFile(file);
            console.log("file",file)
        }
    };

    console.log("rewuestfile",requestFile)
    const handleFileSelect = () => {
        fileInputRef.current.click();  // Trigger the file input click when the button is clicked
    };

    const handleRequestFrom = (event) => {
        setRequestFrom(event.target.value); // Same here
    };
  
    const handleSelectFile = (event) => {
        // const {files} = event.target;
        // if (files.length > 0) {
        //    const fileArray = Array.from(files);
        //    setRequestFile(fileArray); // Store selected files in state

        //    // Convert files to base64 if needed
        //    const base64Array = [];
        //    fileArray.forEach((file) => {
        //        const reader = new FileReader();
        //        reader.onloadend = () => {
        //            const base64String = reader.result;
        //            base64Array.push(base64String);
        //            if (base64Array.length === fileArray.length) {
        //                setBase64Document(base64Array); // Save base64 strings in state
        //            }
        //        };
        //        reader.readAsDataURL(file);
        //    });
        // }


        const selectedFiles = Array.from(event.target.files);
        const imagesArray = selectedFiles.map((file) => URL.createObjectURL(file));

        setRequestFile((prevImages) => [...prevImages, ...selectedFiles]);
        setBase64Document((prevFiles) => [...prevFiles, ...imagesArray]);

        event.target.value = ""; // Clear the file input value
        console.log("selectedFiles", selectedFiles);
    };

    const removeFile = (index) => {
        setRequestFile((prevFiles) => prevFiles.filter((_, i) => i !== index)); // Remove file by index
    };

    // useEffect(() => {
    //    if (userId) {
    //        const loadPayload = {  LoggedInUserId: userId};
    //        URLS.loadServiceRequest(loadPayload)
    //            .then((response) => {
    //                setEditData(response.data);
    //                if (response.data.length > 0) {
    //                    const preselectedClientId = response.data[0].ClientId;
    //                    setClient(preselectedClientId); // Set the clientId after data is loaded

    //                    // Fetch sites for the preselected client
    //                    URLS.getServiceEntrySiteName({ ClientId: preselectedClientId })
    //                        .then((response) => {
    //                            setSelectSite(response.data); // Update the site selection state with the response data
    //                        })
    //                        .catch(error => {
    //                            console.log("Error fetching site names:", error);
    //                        });
    //                }
    //            })
    //            .catch(error => {
    //                console.log("Error fetching service request:", error);
    //            });
    //    }
    // }, [userId]); 
    const handleKeyDown = (event) => {
        // Select item on Tab or Enter key press
        if (event.key === 'Tab' || event.key === 'Enter') {
            const focusedItem = event.target;
            if (focusedItem && focusedItem.getAttribute("data-value") !== null) {
                setClient(focusedItem.getAttribute("data-value"));
                event.preventDefault(); // Prevents default tab behavior
            }
        }
    };
    console.log("edit", editData)
    const formik = useFormik({
        initialValues: {
            ClientId: client,
            SiteId: site,
            ModuleName: moduleName,
            Request: request,
            ExecutiveId: executive || userId,
            RequestFrom: requestFrom || "",
            Document: ""
        },
        onSubmit: (values, actions) => {
              if (!client) {
                enqueueSnackbar("Please select client.");
                actions.setSubmitting(false);
                return;
              }
              // if (!site) {
              //  enqueueSnackbar("Please select site.");
              //  actions.setSubmitting(false);
              //  return;
              // }
              if (!request) {
                enqueueSnackbar("Please select request.");
                actions.setSubmitting(false);
                return;
              }

             const payload = {
                Id: 0,
                ClientInt: client,
                SiteId: site,
                Module: moduleName,
                Request: request,
                ExecutiveId: executive || userId,
                RequestFrom: requestFrom,
                LoggedInUserId: userId,
                RequestDocuments: "",
                File: requestFile.map((file) => ({
                    ContentLength: file.size, // Size of the file in bytes
                    ContentType: file.type,   // MIME type of the file
                    FileName: file.name,      // Name of the file
                    InputStream: {
                        __identity: {}      // Add any required info here
                    }
                }
                )),
            };

            const formData = new FormData();

            // Append non-file fields to the FormData
            formData.append("Id", 0);
            formData.append("ClientId", client);
            formData.append("SiteId", site || 0);
            formData.append("Module", moduleName);
            formData.append("Request", request);
            formData.append("ExecutiveId", executive || userId);
            formData.append("RequestFrom", requestFrom ||  "");
            formData.append("LoggedInUserId", userId);
            formData.append("RequestDocuments", ""); // Append this if needed, adjust if it's optional
            formData.append("RequestFromId", executive || userId); // Append this if needed, adjust if it's optional
            
            // Append files to the FormData
            // requestFile.forEach((file, index) => {
            //    formData.append(`File[${index}].ContentLength`, file.size);
            //    formData.append(`File[${index}].ContentType`, file.type);
            //    formData.append(`File[${index}].FileName`, file.name);
            //    formData.append(`File[${index}]`, file);  // Add the file itself (this will handle the file binary data)
            // });


            for (let i = 0; i < requestFile.length; i += 1) {
                formData.append("File", requestFile[i]);
            }
            
            // Send the formData to the API with enctype="multipart/form-data"
            URLS.saveServiceRequest(formData)
                .then((response) => {
                    console.log("Response:", response.data);
                    enqueueSnackbar("Records saved successfully", { variant: 'success' });

                    // After successful save, reload the data from getServiceRequestData API and set defaults
                    URLS.getServiceRequestData({ LoggedInUserId: userId })
                        .then((response) => {
                            const savedData = response.data;

                            if (savedData.length === 0) {
                                // Clear the form fields if no data is returned
                                setClient("");
                                setSite("");
                                setRequest("");
                                setExecutive(userId); // Reset executive to the default userId
                                setRequestFrom('');
                                setModuleName('');
                                setRequestFile([]);
                                // If using Formik, reset form values
                                actions.resetForm();
                            } else {
                                // If data exists, update form with the saved data
                                const savedRecord = savedData[0];

                                setClient(savedRecord.ClientId);
                                setSite(savedRecord.SiteId);
                                setRequest(savedRecord.Request);
                                setExecutive(savedRecord.ExecutiveId || userId);
                                setRequestFrom('')
                                setRequest("");
                                setModuleName('');
                                setRequestFile([]);

                                // If using Formik, set the form values
                                actions.setValues({
                                    client: savedRecord.ClientId,
                                    site: savedRecord.SiteId,
                                    request: savedRecord.Request,
                                    executive: savedRecord.ExecutiveId || userId,
                                });
                            }
                        })
                        .catch(error => {
                            console.error("Error fetching saved data:", error);
                        });
                })
                .catch(error => {
                    console.error("Error:", error);
                    enqueueSnackbar("Records not saved", { variant: 'error' });
                });
         
            console.log("FormData payload", formData,"payload",payload);
            actions.setSubmitting(false); // Stop form submission
        }

    });
    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <Page title="Service Request">
            <Typography variant="h5" color="#b71c1c" margin={2}>
                Service Request
            </Typography>
            <FormikProvider value={formik}>
                <Form variant="body2" autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <ContentStyle>
                    <Stack spacing={3}  >
                        {/* First row with 3 controls */}
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1} >
                            <FormControl fullWidth size="small" style={{ width: '32%' }}>
                                <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                    Select Client
                                </InputLabel>
                                <Select
                                    label="Select Client"
                                    size="small"
                                        value={client}
                                        onKeyDown={handleKeyDown}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#fbe9e7',
                                                maxHeight: '200px',
                                            },
                                        },
                                    }}
                                    onChange={handleClient}
                                    >
                                        <MenuItem key={0} value={""} >Select</MenuItem>

                                    {selectClient.map((client) => (
                                        <MenuItem
                                            key={client.ClientId}
                                            value={client.ClientId}
                                        >
                                            {client.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                </FormControl>
                                
                            <FormControl fullWidth size="small" style={{ width: '32%' }}>
                                <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                                    Select Site
                                </InputLabel>
                                <Select
                                    label="Select Site"
                                    size="small"
                                    value={site}
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                backgroundColor: '#fbe9e7',
                                                maxHeight: '200px',
                                            },
                                        },
                                    }}
                                    onChange={handleSite}
                                    >
                                        <MenuItem key={0} value={""} >Select</MenuItem>
                                        {selectSite.map((siteItem, index) => (
                                            <MenuItem key={`${siteItem.SiteId}-${index}`} 
                                                value={siteItem.SiteId}
                                        >
                                                {siteItem.Name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextField
                                size="small"
                                label="Module Name"
                                multiline
                                value={moduleName}
                                onChange={handleModule}
                                fullWidth
                                style={{ width: '32%' }}
                            />
                        </Stack>

                            <Stack direction="row" alignItems="center" spacing={3} >
                                <FormControl fullWidth size="small" style={{ width: '32%' }}
                                >
                                    <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Select Executive</InputLabel>
                                    <Select
                                        label="Select Executive"
                                        size="small"
                                        value={executive}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    backgroundColor: '#fbe9e7',
                                                    maxHeight: '200px',
                                                    width: '200px',
                                                },
                                            },
                                        }}
                                        onChange={handleExecutive}

                                    >
                                        <MenuItem key={0} value={""} onChange={() => handleExecutive()}>
                                            Select
                                        </MenuItem>
                                        {selectExecutive.map((exc) => (
                                            <MenuItem
                                                key={exc.ClientId}
                                                value={exc.ClientId}
                                            >
                                                {exc.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                    
                                <TextareaAutosize
                                    label="Request"
                                    placeholder="Request "

                                    minRows={3}
                                    value={request}
                                    onChange={handleRequest}
                                    style={{
                                        width: "66%",
                                        height:"75px",
                                        fontFamily: "Tahoma",
                                        fontSize: "12px",
                                    }}
                                />
                               
                                </Stack>
                            <Stack direction="row" alignItems="flex-start" spacing={3}>
                                {/* Request From field */}
                                <TextField
                                    size="small"
                                    label="Request From"
                                    multiline
                                    value={requestFrom}
                                    onChange={handleRequestFrom}
                                    fullWidth
                                    style={{ width: '40%' }}
                                />

                                  <Button 
                                    component="label" 
                                    variant="contained" 
                                    tabIndex={-1} 
                                    sx={{ 
                                        height: 25, width: 130, borderRadius: 1, fontWeight: 500, mr: 5, 
                                        ":hover": { backgroundColor: "#9e9e9e" }, 
                                        backgroundColor: "#e0e0e0", color: "#212121", boxShadow: "none" 
                                    }} 
                                  > 
                                    Choose Files 
                                    <VisuallyHiddenInput type="file" onChange={(e) => handleSelectFile(e)} multiple /> 
                                  </Button> 
                                {/* Stack to keep the Choose Files button and file names aligned */}
                                <Stack direction="row" alignItems="center" spacing={1} style={{ width: '66%' }}>
                                    

                                    {/* Stack to display selected file names */}
                                    <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ marginLeft: 1 }}>
                                        {requestFile.map((file, index) => (
                                            <Stack
                                                key={index}
                                                direction="row"
                                                alignItems="center"
                                            >
                                                <Typography variant="body2" noWrap>
                                                    {file.name}
                                                </Typography>
                                                <IconButton size="small" onClick={() => removeFile(index)}>
                                                    <Iconify icon="mdi:close" />
                                                </IconButton>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                            </Stack>


                        {/* Save and Cancel Buttons */}
                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>
                               
                                <LoadingButton size="medium" type="submit" variant="contained" sx={{ color: 'white' }}>

                                Save
                            </LoadingButton>

                                <Button sx={{ color: 'white' }} size="medium" variant="contained" color="primary"
                                    onClick={handelCancelClick}>
                                Clear 
                            </Button>
                        </Stack>

                        {isLoading && <LoadingProgressBar sx={{ py: 6 }} />}
                    </Stack>
                        {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}
                    </ContentStyle>
                </Form>
            </FormikProvider>
        </Page>
    );

}