import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Tooltip, Stack, TextField, Typography, Modal, FormControl, InputLabel, Select, MenuItem, Button, Box, Grid, Paper } from '@mui/material';
import { startOfMonth, endOfMonth } from 'date-fns';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Icon } from '@iconify/react';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';


// -----------------------------------------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};
const Status = [
    { id: 0, Name: 'Pending' },
    { id: 1, Name: 'Completed' },
    { id: 2, Name: 'Cancelled' },
];

export default function IssueDeliveryDateDetails({ issueDeliveryDate }) {
    console.log("repo", issueDeliveryDate)

    const TABLE_HEAD = [
        { field: 'IssueId', headerName: 'Issue Id', width: 150 },
        {
            field: 'DeliveryDate', headerName: 'Delivery date', width: 300, valueFormatter: params =>
                Moment(params?.value).format("DD-MMM-yyyy HH:mm")
},
        {
            field: 'CreatedDate', headerName: 'Committed on', width: 300, valueFormatter: params =>
                Moment(params?.value).format("DD-MMM-yyyy HH:mm")
        },
        {
            field: 'CreatedBy', headerName: ' Committed By', width: 300
        },
    ];

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    // const productId = proMaster.ID;
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    // executive ID
    const [userId, setUserId] = useState([]);
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);

    // Modal
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const [exeData, setExeData] = useState([]);

    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/other/issuedeliverydate', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };

    // dropdown
   
    const [effectiveFromValue, setEffectiveFromValue] = useState();

    const [isIssueIdValid, setIsIssueIdValid] = useState(true);
    const [issueIdValue, setIssueIdValue] = useState( '')
    const [showDataGrid, setShowDataGrid] = useState(false);
    const [issueData, setIssueData] = useState([]);
    const [createdByName, setCreatedByName] = useState(''); // For storing the name of the creator
    const [data, setData] = useState('')
    const [showGoButton, setShowGoButton] = useState(true);
    const execMap = exeData.reduce((map, executive) => {
        map[executive.Id] = executive.Name;
        return map;
    }, {});



    const handleEffFrom = (newValue) => {
        if (newValue) {
            const formattedDate = Moment(newValue).format('DD/MMM/yyyy HH:mm');
            setEffectiveFromValue(formattedDate);
        } else {
            // Set to today's date if no date is selected
            const today = Moment(new Date()).format('DD/MMM/yyyy HH:mm');
            setEffectiveFromValue(today);
        }
    };

    useEffect(() => {
        URLS.getExecutivesDropDown()
            .then((response) => {
                const execData = response.data;
                const execMap = execData.reduce((map, executive) => {
                    map[executive.Id] = executive.Name;
                    return map;
                }, {});
                setExeData(execData);
               
            })
            .catch((error) => {
                // Handle error
            });
    }, []);

   // Dependency array
    const handleIssueIdChange = () => {
        if (!issueIdValue) {
            enqueueSnackbar('Please enter an IssueId ');
            return;
        }

        const validatePayload = { IssueId: issueIdValue };

        URLS.validateIssueId(validatePayload)
            .then((validationResponse) => {
                if (validationResponse.status === 200 && validationResponse.data.status === "true") {
                    setShowDataGrid(true);
                    setShowGoButton(false); // Hide Go button after validation is successful

                    URLS.getIssueDetails(validatePayload)
                        .then((issueDetailsResponse) => {
                            setData(issueDetailsResponse.data);

                            URLS.getIssueDeliveryDateListing(validatePayload)
                                .then((deliveryDateResponse) => {
                                    if (deliveryDateResponse.data && deliveryDateResponse.data.length > 0) {
                                        const transformedData = deliveryDateResponse.data.map(item => ({
                                            ...item,
                                            CreatedBy: execMap[item.CreatedBy] || ''
                                        }));
                                        setIssueData(transformedData);
                                        setShowDataGrid(true);
                                    } else {
                                        setIssueData([]);
                                    }
                                })
                                .catch(error => {
                                    handleError(error, enqueueSnackbar);
                                    enqueueSnackbar('Failed to fetch issue delivery date listing.', { variant: 'error' });
                                    setShowDataGrid(false);
                                });
                        })
                        .catch(error => {
                            enqueueSnackbar('Failed to fetch issue details.', { variant: 'error' });
                            setShowDataGrid(false);
                        });
                } else {
                    enqueueSnackbar(validationResponse.data.message || 'Invalid IssueId', { variant: 'error' });
                    setShowDataGrid(false);
                    setIssueData([]);
                }
            })
            .catch(error => {
                enqueueSnackbar('IssueId validation failed.', { variant: 'error' });
                setShowDataGrid(false);
                setIssueData([]);
            });
    };

    useEffect(() => {
        if (!issueIdValue) {
            setShowDataGrid(false);
            setIssueData([]);
        }
    }, [issueIdValue]);

    const handleIssueIdKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent form submission on Enter key
            handleIssueIdChange(); // Trigger validation and data loading
        }
    };

    const formik = useFormik({
        initialValues: {
           
            DeliveryDate: effectiveFromValue,
            IssueId: issueIdValue ,
            
            CreatedBy: userId,
           
        },
        onSubmit: (values, actions) => {
           
            if (issueIdValue.length === 0) {
                enqueueSnackbar("Please select IssueId", { variant: 'error' });
                return;
            }
          
            const payload = {
                "IssueId": issueIdValue,
                "DeliveryDate": effectiveFromValue || Moment(new Date()).format('DD/MMM/yyyy HH:mm'),
                "CreatedBy": userId,              
            };
            console.log("payload>>>>>>>>>", payload)
            const validatePayload = { IssueId: issueIdValue };
        
    URLS.validateIssueId(validatePayload)
        .then((response) => {
            if (response.status === 200 && response.data.status === "true") {

                URLS.saveIssueDeliveryDate(payload)
                    .then((response) => {
                        if (response.status === 200) {
                              navigate('/dashboard/other/issuedeliverydate', { replace: true });
                        }
                        enqueueSnackbar("Data saved successfully", { variant: 'success' });
                    })
                    .catch(error => {
                        enqueueSnackbar("Data Does Not Saved", { variant: 'error' });
                    });
            }
            else {
                enqueueSnackbar(response.data.message, { variant: 'error' });

            }
        })
        .catch(error => {
            enqueueSnackbar('Invalid IssueId', { variant: 'error' });
            setIsIssueIdValid(false); // Set validation status to false
        });

},
    });

    const handleReset = () => {
        setIssueIdValue(''); // Clear the IssueId value
        setShowDataGrid(false); // Hide the data grid
        setIssueData([]); // Clear the issue data
        setData(''); // Clear additional data if needed
        setShowGoButton(true); // Show the "Go" button again
    };

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Stack spacing={4}>

                    <Stack direction="row" spacing={4} m={1} >
                        <Grid container spacing={2}>
                            <Grid item xs={showDataGrid && data && issueIdValue ? 6 : 4}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <TextField
                                        fullWidth
                                        label="IssueId"
                                        size="small"
                                        value={issueIdValue}
                                        onChange={(e) => setIssueIdValue(e.target.value)}
                                        onKeyDown={handleIssueIdKeyPress} 
                                    />
                                    {showGoButton && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                handleIssueIdChange(); // Call the function to handle the click
                                            }}
                                        >
                                            Go
                                        </Button>
                                    )}
                                </Stack>
                            </Grid>

                            {showDataGrid && data && issueIdValue && (
                                <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Delivery Date"
                                    inputFormat="dd/MMM/yyyy HH:mm" // Adjust the format as needed
                                    value={Moment(effectiveFromValue).format('DD-MMM-yyyy HH:mm')} // Include time in the format
                                    onChange={handleEffFrom}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                                    </LocalizationProvider>
                                </Grid>
                            )}
                        </Grid>
                    </Stack>
                    {showDataGrid && data && issueIdValue && (
                        <>
                            <Stack >
                                <Paper elevation={3} >
                                    <Typography variant="body1">
                                        Issue Reported By <strong>{data[0]?.IssueReportedBy}</strong> On Date <strong>{data[0]?.Date}</strong> Of <strong>{data[0]?.Client} ({data[0]?.SiteName})</strong> For <strong>{data[0]?.IssueCategory}</strong> and Owned by <strong>{data[0]?.IssueOwnedBy}</strong>
                                    </Typography>
                                    <Typography>
                                        <em>'{data[0]?.Remarks}'</em>

                                    </Typography>
                                </Paper>


                            </Stack>
                          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>

                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            sx={{ color: 'white' }}
                        >
                            Save
                        </LoadingButton>
                                <Tooltip title="Reset">

                                    <Button
                                        variant="contained"
                                        size="small"
                                        onClick={handleReset}
                                    ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>

                                </Tooltip >
                        <Button
                            sx={{ color: 'white' }}
                            onClick={handelCancelClick}
                            size="medium"
                            variant="contained"
                            color="primary"
                        >
                            Cancel
                        </Button>

                    </Stack>
                            <Stack direction="row">
                                <div style={{ height: '50vh', width: '100%' }}>
                                    <DataGrid disableSelectionOnClick rows={issueData} getRowId={(row) => issueData.indexOf(row)} density={'compact'} columns={TABLE_HEAD} components={{
                                        Toolbar: GridToolbar,
                                    }} />
                                </div>
                            </Stack>
                        
                        </>
                      

                    )}
                  
                </Stack>


            </Form>

            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

        </FormikProvider>
    );
}