import { React, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Card, Typography, Divider, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import { useFormik, Form, FormikProvider } from 'formik';
import AppCache from '../../../services/AppCache';
import URLS from '../../../services/urls.service';
import Page from '../../../components/Page';
import Scrollbar from "../../../components/Scrollbar";
import ClientMaster from '../../../components/SidebarMenuComponents/MasterComponent/ClientMaster'
import ClientMasterDetailsComponent from "../../../components/SidebarMenuComponents/MasterComponent/ClientMasterDetailsComponent";
import { handleError } from '../../../components/Common/Utils';
import ModalPopUp from '../../../components/Common/ModalPopUp';
// ----------------------------------------------------------------------------------------------
const ContentStyle = styled('div')(({ theme }) => ({

    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
}));
export default function ClientMasterDetailsPage() {
    const location = useLocation()
    const { clientMaster } = location.state;

    const projectTeamData = clientMaster.ProjectTeam || [];
    const clientContactData = clientMaster.ClientContactDetails || [];

    const preferredExecutiveeData = clientMaster.PreferredExecutive || [];

    const navigate = useNavigate();
    const [teams, setTeams] = useState([
        {
            projectId: "",
            selectedExecutives: [],
            teamIndex: 0,
            teamMasterID: 0,
            teamName: ""
        }
    ]);
    const [contactDetails, setContactDetails] = useState([
        {
            "Id": 0,
            // "LoggedInUserId": 0,
            "ClientContactId": 0,
            "Name": "",
            "Designation": 0,
            "Phone": "",
            "Email": "",
            "Remark": "",
            /* "IsDelete": true */
        }
    ]);

    const { teamPanelData } = location.state;

    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const [isCheckedCallList, setIsCheckedCallList] = useState((clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
        ? clientMaster.ClientMaster[0].CallListEmail : false);
    const [isApiChecked, setIsApi] = useState((clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
        ? clientMaster.ClientMaster[0].isAPI : false);
    const [isCheckedActive, setIsCheckedActive] = useState((clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
        ? clientMaster.ClientMaster[0]?.IsActive : true);
    const [projectStatus, setProjectStatus] = useState([]);
    const [clientGrouping, setClientGrouping] = useState([]);
    const [value, setValue] = useState(0);
    const [hide, setHide] = useState(false);
    const [projectStatusId, setProjectStatusId] = useState((clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
        ? clientMaster.ClientMaster[0]?.ProjectStatus : "");
   
    const [projectStatusName, setProjectStatusName] = useState((clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
        ? clientMaster.ClientMaster[0].ProjectStatus : "");
    const [subContactId, setSubContactId] = useState((clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
        ? clientMaster.ClientMaster[0]?.SubContactID :"");
    const [industry, setIndustry] = useState((clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
        ? clientMaster.ClientMaster[0]?.Industry : "");
    const [industryData, setIndustryData] = useState([]);
    const [productFamily, setProductFamily] = useState((clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
        ? clientMaster.ClientMaster[0]?.ProductFamily : "");
    const [productFamilyData, setProductFamilyData] = useState([]);
    const [category, setCategory] = useState((clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
        ? clientMaster.ClientMaster[0]?.ClientCategory : "");
    const [categoryData, setCategoryData] = useState([]);
    const [projectDatatDropDown, setProjectDataDropDown] = useState([]);
    const [projectId, setProjectId] = useState(clientMaster.ProjectId);
    
    const [executives, setExecutives] = useState([]);
    const [selectedExecutives, setSelectedExecutives] = useState([]);
    const [saveTeamsTab, setSaveTeamsTab] = useState([]);
   
    const numberOfTeams = teams.length; // Replace this with the actual number of teams
    const maxExecutivesPerTeam = 1;
    const initialExecutiveValue = Array(numberOfTeams)
        .fill([])
        .map(() => Array(maxExecutivesPerTeam).fill(''));

    const initialExecutiveStates = Array(numberOfTeams).fill([]).map(() => Array(maxExecutivesPerTeam).fill({ radio: false }));

    const [executiveValue, setExecutiveValue] = useState(initialExecutiveValue);
    const [executiveStates, setExecutiveStates] = useState(initialExecutiveStates);

    const [executiveRoles, setExecutiveRoles] = useState([]);
    const [executiveCostings, setExecutiveCostings] = useState([]);
    const [exeData, setExeData] = useState([]);
    const [rolesData, setRolesData] = useState([]);
    const [executive, setExecutive] = useState([]);
    const [department, setDepartment] = useState([]);
    const [designation, setDesignation] = useState([]);
    const [selectedPreferredExecutives, setSelectedPreferredExecutives] = useState([]);
    const [currentDepartment, setCurrentDepartment] = useState(''); // Step 1
    const [executiveDepartments, setExecutiveDepartments] = useState({});
    const [selectedDepartment, setSelectedDepartment] = useState('');
    const [preferredExecutiveValue, setpreferredExecutiveValue] = useState([]);
    const [selectedRadioStates, setSelectedRadioStates] = useState({});

    const [DeptValue, setDeptValue] = useState( "");

    const [exeCheckBoxState, setExeCheckBoxState] = useState({});
    const [isHide, setIsHide] = useState(false);

    const [name, setName] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactRemark, setContactRemark] = useState('');
    const [isBilling, setIsBilling] = useState((clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
        ? clientMaster.ClientMaster[0].BillingNameSameAsClientName : true);
    const [interState, setInterState] = useState((clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
        ? clientMaster.ClientMaster[0].Interstate : false);
    const [billingName, setBillingName] = useState((clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
        ? clientMaster.ClientMaster[0].BillingName : '');

    const [executiveIsError, setExecutiveIsError] = useState(
        teams.map((team) => team.selectedExecutives.map(() => false))
    );

    const fetchGroupData = (KeyId, setData) => {
        const payload = { KeyId };
        URLS.getGroupsData(payload)
            .then((response) => {
                setData(response.data);
            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
    };
  
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        if (projectTeamData && projectTeamData.length > 0) {
            const newFieldSets = projectTeamData.map(data => ({
                ...data
            }));
            setTeams(newFieldSets);
        }
    }, []);

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        if (clientContactData && clientContactData.length > 0) {
            const newFieldSets = clientContactData.map(data => ({
                ...data
            }));
            setContactDetails(newFieldSets);
        }
    }, []);

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);

        URLS.getProjectNamesDropDown()
            .then((response) => {
                setProjectDataDropDown(response.data);
            })
            .catch(error => {
                // On error
                console.log("Error");
            });

        const payloadExecutive = { ExecutiveLoginId: 0 };
        URLS.getExecutiveInfo(payloadExecutive)
            .then((response) => {
                setExecutives(response.data);
            })
            .catch(error => {
                // On error
                console.log("Error");
            });
        URLS.getExecutivesDropDown()
            .then((response) => {

                setExeData(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        URLS.getRoles()
            .then((response) => {

                setRolesData(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        URLS.getProjectStatusDropDown()
            .then((response) => {
                setProjectStatus(response.data);
            })
            .catch(error => {
                // On error
                console.log("Error");
            });
        URLS.getClientMasterData()
            .then((response) => {
                setClientGrouping(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        URLS.getDepartmentDropDown()
            .then((response) => {

                setDepartment(response.data);
                setCurrentDepartment(response.data[0].Id); // Set the initial department

            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        URLS.getDesignation()
            .then((response) => {

                setDesignation(response.data);

            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        fetchGroupData(30, setProductFamilyData);
        fetchGroupData(33, setIndustryData);
        fetchGroupData(34, setCategoryData);

    }, []);

    useEffect(() => {
        const { productTeam } = location.state;
        if (productTeam && Array.isArray(productTeam) && productTeam.length > 0) {
            setTeams(productTeam);
        } else {
            setTeams([{ teamMasterID: 0, teamIndex: 0, teamName: '', projectId: "", selectedExecutives: [] }]);
        }
    }, [location.state]);

    useEffect(() => {
        if (projectTeamData.length > 0) {
            const updatedTeams = projectTeamData.map((teamData,index) => ({
                projectId: teamData.ProjectId || "",
                selectedExecutives: [
                    {
                        ProjectTeamId: teamData.ProjectTeamId || "",
                        Costing: teamData.Costing || "", // Default to empty string if Costing is not provided
                        Id: teamData.ExecutiveId || "", // Default to 0 if ExecutiveId is not provided
                        Name: teamData.Name || "", // Use TeamName as a placeholder if Executive Name is not provided
                        Role: teamData.ProjectRole || 0, // Default to 0 if ProjectRole is not provided
                        AltExecutiveId: teamData.AltExecutiveId || '',
                        radio: teamData.IsCoOrdinator || false,
                        errorcheckbox: teamData.IsError || false,
                        Department: teamData.Department || "", // Use TeamName as a placeholder if Executive Name is not provided
                        Designation: teamData.Designation || "", // Use TeamName as a placeholder if Executive Name is not provided

                    }
                ], // Add logic here to populate selected executives if needed
                teamIndex: index || "", // Update as needed based on your logic
                teamMasterID: teamData.ClientProjectId || "", // Assuming TeamId needs to be mapped
                teamName: teamData.TeamName || ""
            }));

            // Update state or prop
            setTeams(updatedTeams);

        }
    }, [projectTeamData]);

    useEffect(() => {
        if (clientContactData && clientContactData.length > 0) {
            const updatedContacts = clientContactData.map((contact, index) => {
                const isNewContact = index === clientContactData.length - 1; // Check if it's the last item (newly added)

                return {
                    ...contact,
                    index,  // Add the index to each contact
                    Id: contact.Id || 0, // Set Id to 0 for the last (new) contact
                    Name: contact.ContactName || "", // Default to empty string if Name is not provided
                    Designation: contact.Designation || "", // Default to empty string if Designation is not provided
                    Phone: contact.Phone || "", // Default to empty string if Phone is not provided
                    Email: contact.Email || "", // Default to empty string if Email is not provided
                    Remark: contact.Remark || "", // Default to empty string if Remark is not provided
                    ClientContactId: contact.ClientContactId || contact.ContactId || 0, // Ensure that ClientContactId defaults to 0
                };
            });

            setContactDetails(updatedContacts); // Update the state with the updated contacts data
        }
    }, [clientContactData]);

    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/master/clientmaster', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);
    };
    
    const handleCheckboxChange = (event) => {
        setIsCheckedCallList(event.target.checked);
    };
    const handleApiCheckboxChange = (event) => {
        setIsApi(event.target.checked);
    };

    const handleIsActiveChange = (event) => {
        setIsCheckedActive(event.target.checked);
    };

    const handleChangeValue = (event, newValue) => {
        setValue(newValue);
    };
    
    const handleHide = () => {
        setHide(true)
    };

    const handleShow = () => {
        setHide(false)
    };

    const handelSelectedProject = (id, name) => {
        setProjectStatusId(id);
        setProjectStatusName(name);
    };
   
    const handleSelectedSubContact = (id) => {
        setSubContactId(id);
    };

    const handleIndustryChange = (id) => {
        setIndustry(id);
    };

    const handleProductFamilyChange = (id) => {
        setProductFamily(id);
    };

    const handleCategoryChange = (id) => {
        setCategory(id);
    };
   
    const handleSelectedProject = (id) => {
        setProjectId(id);
    };

    const handleSelectedExecutive = (event, newValue) => {
        setSelectedExecutives(newValue);
    };
    // Replace with the maximum number of executives per team
    const handleName = (e) => {
        setName(e.target.value)
    }
    const handleContactEmail = (e) => {
        setContactEmail(e.target.value)
    }
    const handleContactRemark = (e) => {
        setContactRemark(e.target.value)
    }
    const handleBillingName = (e) => {
        setBillingName(e.target.value)
    }
    const handleIsBilling = (event) => {
        const isChecked = event.target.checked;
        setIsBilling(isChecked);
        console.log("billingisChecked", isChecked)

        if (!isChecked) {
            // Clear BillingName if checkbox is unchecked
            setBillingName('');
        } else {
            // When the checkbox is unchecked, also clear BillingName
            setBillingName('');
        }
    };
    console.log("billing",isBilling)
    const handleInterState = (event) => {
        setInterState(event.target.checked);
    };

    const addTeam = () => {
        // Get the current maximum teamIndex
        const maxTeamIndex = Math.max(...teams.map(team => team.teamIndex), -1);
        const newTeamIndex = maxTeamIndex + 1;
        setTeams([...teams, { teamMasterID: '', teamIndex: newTeamIndex, teamName: '', projectId: '', selectedExecutives: [] }]);
        setExecutiveValue([...executiveValue, []]);
    };
    const addClientDetail = () => {
        const maxIndex = Math.max(...contactDetails.map(detail => detail.index), -1);
        const newIndex = maxIndex + 1;
        setContactDetails([...contactDetails, {
            Id: 0,
            ClientContactId: 0,
            Name: "",
            Designation: "",
            Phone: "",
            Email: "",
            Remark: ""

        }]);
    };
    const handleClientDetailChange = (index, field, value) => {
        setContactDetails(prevDetails =>
            prevDetails.map((detail, idx) =>
                idx === index ? { ...detail, [field]: value } : detail
            )
        );
    };


    const handleTeamNameChange = (index, value) => {
        const updatedTeams = [...teams];
        updatedTeams[index].teamName = value;
        setTeams(updatedTeams);
    };

    const handleProjectChange = (index, value) => {
        const updatedTeams = [...teams];
        updatedTeams[index].projectId = value;
        setTeams(updatedTeams);
    };

    const handleExecutivesChange = (teamIndex, newValue) => {
        setTeams(prevTeams => {
            const updatedTeams = [...prevTeams];
            updatedTeams[teamIndex] = {
                ...updatedTeams[teamIndex],
                selectedExecutives: newValue.map(exec => {
                    const existingExecutive = updatedTeams[teamIndex].selectedExecutives.find(e => e.Id === exec.Id);
                    return {
                        ...exec,
                        ProjectTeamId: existingExecutive ? existingExecutive.ProjectTeamId : 0
                    };
                })
            };
            return updatedTeams;
        });
    };

    const handleActiveCheckboxChange = (index, execIndex, executiveId, isChecked) => {
        setExecutiveStates(prevStates => ({
            ...prevStates,
            [executiveId]: { ...prevStates[executiveId], CheckboxState: isChecked }
        }));
    };

    const handleRadioChange = (teamIndex, execIndex, selectedExecutiveId) => {
        const updatedTeams = [...teams];
        const updatedExecutiveStates = { ...executiveStates };

        updatedTeams[teamIndex].selectedExecutives.forEach((executive, index) => {
            if (index === execIndex) {
                updatedExecutiveStates[executive.Id] = {
                    ...updatedExecutiveStates[executive.Id],
                    radio: true,
                };
                updatedTeams[teamIndex].selectedExecutives[index].radio = true;
            } else {
                updatedExecutiveStates[executive.Id] = {
                    ...updatedExecutiveStates[executive.Id],
                    radio: false,
                };
                updatedTeams[teamIndex].selectedExecutives[index].radio = false;
            }
        });

        setSelectedRadioStates({ ...selectedRadioStates, [teamIndex]: execIndex });
        setExecutiveStates(updatedExecutiveStates);
        setTeams(updatedTeams);
    };
    const handleContactDesignationChange = (index, value) => {
        setContactDetails(prevDetails =>
            prevDetails.map((detail, idx) =>
                idx === index ? { ...detail, Designation: value } : detail
            )
        );
    };

    const handleRoleChange = (index, execIndex, newValue) => {
        // Update the role in the selected executive
        const updatedTeams = [...teams];
        updatedTeams[index].selectedExecutives[execIndex].Role = newValue;
        setTeams(updatedTeams);

        // Ensure the executiveRoles state array has the necessary structure
        const updatedRoles = [...executiveRoles];
        if (!updatedRoles[index]) {
            updatedRoles[index] = [];
        }
        updatedRoles[index][execIndex] = newValue;
        setExecutiveRoles(updatedRoles);
    };

    const handleCostingChange = (index, execIndex, newValue) => {
        const updatedTeams = [...teams];
        updatedTeams[index].selectedExecutives[execIndex].Costing = newValue; // Update the costing in the selected executive
        setTeams(updatedTeams); // Update the teams state

        // Update executiveCostings state array
        const updatedCostings = [...executiveCostings];
        updatedCostings[index][execIndex] = newValue;
        setExecutiveCostings(updatedCostings);
    };

    const handleErrorCheckboxChange = (index, execIndex, executiveId, isChecked) => {
        // Update the teams state
        const updatedTeams = [...teams];
        updatedTeams[index].selectedExecutives[execIndex].errorcheckbox = isChecked;
        setTeams(updatedTeams);

        // Ensure executiveIsError is correctly structured
        const updatedIsError = [...executiveIsError];
        if (!updatedIsError[index]) {
            updatedIsError[index] = [];
        }
        updatedIsError[index][execIndex] = isChecked;
        setExecutiveIsError(updatedIsError);
    };

    
    const handleSelectedExecutiveDD = (teamIndex, execIndex, altExecutiveId, altExecutiveName) => {
        setTeams((prevTeams) => {
            const updatedTeams = [...prevTeams];
            const updatedExecutives = [...updatedTeams[teamIndex].selectedExecutives];

            updatedExecutives[execIndex] = {
                ...updatedExecutives[execIndex],
                AltExecutiveId: altExecutiveId
            };

            updatedTeams[teamIndex] = {
                ...updatedTeams[teamIndex],
                selectedExecutives: updatedExecutives
            };

            return updatedTeams;
        });
    };


    const SelectExecutive = () => {
        setExecutiveValue("");
    };

    const handleSave = () => {
        const updatedTeams = teams.map((team, index) => {
            const updatedTeam = { ...team };

            updatedTeam.selectedExecutives = team.selectedExecutives.map((executive, execIndex) => {
                const updatedExecutive = { ...executive };
                updatedExecutive.Role = executiveRoles[index] && executiveRoles[index][execIndex];
                updatedExecutive.Costing = executiveCostings[index] && executiveCostings[index][execIndex];

                if (executiveStates[executive.Id]) {
                    updatedExecutive.CheckboxState = executiveStates[executive.Id].CheckboxState;
                    updatedExecutive.errorcheckbox = executiveStates[executive.Id].errorcheckbox;
                }
                updatedExecutive.radio = selectedRadioStates[index] === execIndex;

                return updatedExecutive;
            });

            return updatedTeam;
        });

        setTeams(updatedTeams);
    };
    const removeTeam = (index) => {
        const updatedTeams = [...teams];
        updatedTeams.splice(index, 1);
        setTeams(updatedTeams);
    };

    const deleteTeam = (teamIndexToDelete) => {
        // Filter out the team to delete based on teamIndex
        const updatedTeams = teams.filter((team, index) => index !== teamIndexToDelete);
        const updatedExecutiveValue = executiveValue.filter((_, index) => index !== teamIndexToDelete);
        setTeams(updatedTeams);
        setExecutiveValue(updatedExecutiveValue);
    };
    
    const handleExecutiveCheckBoxChange = (rowId) => {
        const newState = { ...exeCheckBoxState };
        newState[rowId] = !newState[rowId]; 
        setExeCheckBoxState(newState);
        setExecutiveDepartments((prevDepartments) => ({
                ...prevDepartments,
                [rowId]: newState[rowId] ? selectedDepartment : "", // Set the department based on the checkbox state
            }));
        console.log("Updated exeCheckBoxState: ", newState);
    };

    const handleDelete = (cellValues) => {
        setSelectedPreferredExecutives((prevSelectedExecutives) => {
            const updatedExecutives = prevSelectedExecutives.filter((executive, index) => index !== cellValues);
            setSelectedPreferredExecutives((prevIds) => prevIds.filter((id, index) => index !== cellValues));
            setpreferredExecutiveValue((prevExecutiveValue) => prevExecutiveValue.filter((executive, index) => index !== cellValues));
            setExeCheckBoxState((prevState) => {
                const newState = { ...prevState };
                delete newState[cellValues];
                return newState;
            });

            return updatedExecutives;
        });
    };

    const handleSelectedDepartment = (id) => {
        setSelectedDepartment(id); 
        setCurrentDepartment(id);
        setDeptValue(id);
        const payloadsExecutive = { ExecutiveLoginId: id };
        URLS.getExecutiveNameData(payloadsExecutive)
            .then((response) => {
                console.log(response.data);
                setExecutive(response.data);
            })
            .catch(error => {
                // On error
                console.log(" Error ");
            });
        setExecutiveDepartments((prevDepartments) => ({
            ...prevDepartments,
            [id]: selectedDepartment,
        }));
    };

    const SelectDepartment = () => {
        setDeptValue("");
    };
    

    const handlePreferredSelectedExecutive = (event, newValue) => {
        setIsHide(true);
        setpreferredExecutiveValue(newValue);
        const updatedSelectedExecutives = newValue.map((executive) => {
            // Check if clientMaster.PreferredExecutive is defined and find corresponding executive
            const matchedExecutive = clientMaster.PreferredExecutive?.find((preselected) => preselected.ContactId === executive.Id);

            return {
                ...executive,
                Name: matchedExecutive ? matchedExecutive.Name : executive.Name, // Update Name if matched, otherwise keep existing
            };
        });

        setSelectedPreferredExecutives(updatedSelectedExecutives);

        // Update the selected department for the selected executives
        const newDepartments = {};
        newValue.forEach((executive) => {
            newDepartments[executive.Name] = executiveDepartments[executive.Name] || selectedDepartment;
            executive.selected = true; // Assuming the default state is 'true' for each executive being selected
        });

        setExecutiveDepartments((prevDepartments) => ({
            ...prevDepartments,
            ...newDepartments,
        }));
    };

    useEffect(() => {
        if (clientMaster && clientMaster.PreferredExecutive) {
            setpreferredExecutiveValue(clientMaster.PreferredExecutive);
        }
    }, [clientMaster]);

    useEffect(() => {
        if (Array.isArray(clientMaster.PreferredExecutive) && clientMaster.PreferredExecutive.length > 0 && Array.isArray(executives)) {
            const preSelectedExecutives = clientMaster.PreferredExecutive.map((exec) => {
                const matchedExecutive = executives && executives.find((e) => e.Id === exec.ContactId);
                return {
                    ...exec,
                    Id: matchedExecutive ? matchedExecutive.Id : exec.ContactId,
                    Name: matchedExecutive ? matchedExecutive.Name : exec.Name,
                    department: matchedExecutive ? matchedExecutive.DepartmentId : exec.DepartmentId,
                    IsCOnnect: matchedExecutive ? matchedExecutive.selected : exec.IsCOnnect

                };
            });
            setpreferredExecutiveValue(preSelectedExecutives);
            handlePreferredSelectedExecutive(null, preSelectedExecutives);
        }
    }, []);
    useEffect(() => {
        const initialCheckBoxState = {};

        if (clientMaster.PreferredExecutive && Array.isArray(clientMaster.PreferredExecutive)) {
            clientMaster.PreferredExecutive.forEach((executive, index) => {
                initialCheckBoxState[index] = executive.IsCOnnect || false;
            });
        }

        setExeCheckBoxState(initialCheckBoxState);
    }, [clientMaster.PreferredExecutive]);

    useEffect(() => {
        if (projectTeamData && projectTeamData.length > 0) {
            // Create a map to track unique ClientProjectIds and their associated executives
            const teamsMap = new Map();

            // Iterate through the project team data
            projectTeamData.forEach(teamData => {
                // Check if the ClientProjectId already exists in the map
                if (!teamsMap.has(teamData.ClientProjectId)) {
                    // Initialize with the ClientProjectId and empty array for executives
                    teamsMap.set(teamData.ClientProjectId, {
                        projectId: teamData.ProjectId || "",
                        selectedExecutives: [],
                        teamIndex: 0, // Set this if you need to track index
                        teamMasterID: teamData.ClientProjectId,
                        teamName: teamData.TeamName
                    });
                }

                // Add the current teamData to the selectedExecutives array in the map
                const teamInfo = teamsMap.get(teamData.ClientProjectId);
                teamInfo.selectedExecutives.push({
                    Costing: teamData.Costing || "",
                    Id: teamData.ExecutiveId || 0,
                    Name: teamData.Name || "",
                    Role: teamData.ProjectRole || 0,
                    AltExecutiveId: teamData.AltExecutiveId || '',
                    radio: teamData.IsCoOrdinator || false,
                    errorcheckbox: teamData.IsError || false,
                    Department: teamData.Department || "",
                    Designation: teamData.Designation || "",
                });

                // Update the map entry
                teamsMap.set(teamData.ClientProjectId, teamInfo);
            });

            // Convert the map values to an array
            const newFieldSets = Array.from(teamsMap.values());

            // Update state or prop
            setTeams(newFieldSets);
        }
    }, [projectTeamData]);
   
    const SelectPreferredExecutive = () => {
        setpreferredExecutiveValue("");
    };
    
    const ClientMasterSchema = Yup.object().shape({
        ClientName: Yup.string()
            .min(2, 'Too Short!')
            .max(100, 'Too Long!')
            .required('Client Name is required'),
        ERPCode: Yup.string().min(2, 'Too Short!').max(100, 'Too Long!').required('ERP Code is required'),
   
    });
  
    const formik = useFormik({
        initialValues: {
            ContactId: (clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
                ? clientMaster.ClientMaster[0].Id
                : 0,
            SubContactID: subContactId,
            ClientName: (clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
                ? clientMaster.ClientMaster[0].Name
                : "",
            ERPCode: (clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
                ? clientMaster.ClientMaster[0].ERPCode
                : "",
            Remarks: (clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
                ? clientMaster.ClientMaster[0].Remarks
                : "",
            SaplMailerAPI: (clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
                ? clientMaster.ClientMaster[0]?.SAPLMailerAPI
                : "",
            FromEmailId: (clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
                ? clientMaster.ClientMaster[0].FromEmailId
                : "",
            CallListEmail: isCheckedCallList,
            ProjectStatus: projectStatusId,
            IsActive: isCheckedActive,
            isAPI: isApiChecked,
            ZylemAPIUrl: (clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
                ? clientMaster.ClientMaster[0].ZylemAPIUrl
                : "",
            ClientAddress: (clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
                ? clientMaster.ClientMaster[0].ClientAddress
                : "",
            ProductFamily: productFamily,
            Industry: industry,
            Category: category,
            ClientGrouping: clientMaster.ClientGrouping,
            ExecutiveID: userId,
            BillingName: billingName || "",
            ProjectTeamData: {

            },
            PreferredExecutive: {},
            ExecutivesDataMaster: executiveDepartments
        },
        validationSchema: ClientMasterSchema,

        onSubmit: (values, actions) => {
            const projectTeamDataValue = clientMaster?.ProjectTeamDataValue || [];
            
            const payload = {
                "ContactId": (clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
                    ? clientMaster.ClientMaster[0].Id
                    : 0,
                "SubContactID": subContactId || "",
                "ClientName": values.ClientName,
                "ERPCode": values.ERPCode || "",
                "Remarks": values.Remarks || "",
                "SaplMailerAPI": values.SaplMailerAPI || "",
                "FromEmailId": values.FromEmailId || "",
                "CallListEmail": isCheckedCallList || false,
                "ProjectStatus": projectStatusId ,
                "IsActive": isCheckedActive || false,
                "ClientGrouping": values.ClientGrouping || "",
                "Industry": industry,
                "ProductFamily": productFamily,
                 "ClientCategory":category,
                "ExecutiveID": userId,
                "ClientAddress": values.ClientAddress || "",
                "ZylemAPIUrl": values.ZylemAPIUrl || "",
                "isAPI": isApiChecked || false,
                "Interstate": interState || false,
                "BillingNameSameAsClientName": isBilling ,
                "BillingName":billingName || "",
                 ProjectTeamData: teams.map(team => ({
                    teamMasterID: team.teamMasterID || '',
                    teamIndex: team.teamIndex || "",
                    teamName: team.teamName || "",
                    projectId: team.projectId || "",
                    ProjectTeamDataValue: team.selectedExecutives.map((exec, execIndex) => {
                        const projectTeam = (clientMaster.ProjectTeam && clientMaster.ProjectTeam.length > 0) ? clientMaster.ProjectTeam[execIndex] : ""
                        const projectTeamId = projectTeam ? projectTeam.ProjectTeamId : 0;

                       /* const existingExec = clientMaster.ProjectTeam.find(p => p.ExecutiveId === exec.Id); */
                        const existingExec = (clientMaster.ProjectTeam && Array.isArray(clientMaster.ProjectTeam))
                            ? clientMaster.ProjectTeam.find(p => p.ExecutiveId === exec.Id)
                            : "";
                        const projectTeamIdData = existingExec ? existingExec.ProjectTeamId : "";
                        return {
                            /*  "ProjectTeamId": existingExec ? existingExec.ProjectTeamId : 0, */
                            "projectTeamId": projectTeamIdData || "",
                            "ExecutiveId": exec.Id || "",
                            "IsCoordinator": exec.radio || false,
                            "Costing": exec.Costing || "",
                            "ProjectRole": exec.Role || "",
                            "ProjectTeamAlertId":"",
                            "IsError":"",
                            "AltExecutiveId":""
                            // "ProjectTeamAlertId": projectTeam ? projectTeam.ProjectTeamAlertsId : 0,
                            // "IsError": exec.errorcheckbox || false,
                            // "AltExecutiveId": exec.AltExecutiveId || 0

                        };
                    })
                 })),
                
                // "TeamsDataMaster": savedData,
                "ExecutivesDataMaster": [],

                "saveClientContactDetails": contactDetails.map((contact) => ({
                    "Id": contact.Id || 0, // Ensure that Id defaults to 0 if not available
                    "ClientContactId":
                        (clientMaster && clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0) ? clientMaster.ClientMaster[0].Id : 0,
                    "Name": contact.Name || "",  // Ensure Name is populate
                    "Designation": contact.Designation || "",  // Ensure Designation is populated
                    "Phone": contact.Phone || "",  // Ensure Phone is populated
                    "Email": contact.Email || "",  // Ensure Email is populated
                    "Remark": contact.Remark || ""  // Ensure Remark is populated
                })),
                 "PreferredExecutive": preferredExecutiveValue.map((executive,index) => {
                     let matchingContactId = executive.Id; // Default to executive.Id


                     if (clientMaster.PreferredExecutive && clientMaster.PreferredExecutive.length > 0) {
                         const matchingContact = clientMaster.PreferredExecutive.find(pe => pe.ContactId === executive.ContactId);
                         if (matchingContact) {
                             matchingContactId = matchingContact.ContactId !== undefined ? matchingContact.ContactId : executive.Id;
                         }
                     }
                     return {
                         Id: "",
                         ContactId: matchingContactId,
                         DepartmentId: executive.DepartmentId || 0,
                         ClientId: subContactId,
                         IsCOnnect: exeCheckBoxState[index] || false,
                         ProjectId: projectId || 0
                     };
                 }),
               

            };
             const clientPayload = {
                "ClientName": values.ClientName,
                 "ContactId": (clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
                     ? clientMaster.ClientMaster[0].Id
                     : 0,
             };

             const erpPayload = {
                "ERPCode": values.ERPCode,
                 "ContactId": (clientMaster.ClientMaster && clientMaster.ClientMaster.length > 0)
                     ? clientMaster.ClientMaster[0].Id
                     : 0,
             };

            const teamsdata = Array.isArray(teams) ? teams : [];
           
            // Validate ProjectTeamData
            const hasIncompleteProjectTeamData = teamsdata.some(team =>
                (team.teamName === "" && team.projectId !== "") ||
                (team.teamName !== "" && team.projectId === "") ||
                // Check if both teamName and projectId are set, but selectedExecutives is empty
                (team.teamName !== "" && team.projectId !== "" && team.selectedExecutives.length === 0) ||
                // Check if selectedExecutives has data but either teamName or projectId is not set
                (team.selectedExecutives.length > 0 && (team.teamName === "" || team.projectId === ""))
            );

             if (hasIncompleteProjectTeamData) {
                enqueueSnackbar("Data incomplete in Project Team Data.", { variant: 'error' });
             } else {
                URLS.getClientNameValidation(clientPayload)
                    .then((response) => {
                        console.log("response.status", response.data.status);
                        if (response.data.status === "false") {
                            URLS.getERPCodeValidation(erpPayload)
                                .then((response) => {
                                    if (response.data.status === "false") {

                                        URLS.saveClientDetails(payload)
                                            .then((response) => {
                                                if (response.status === 200) {
                                                    navigate('/dashboard/master/clientmaster', { replace: true });
                                                    console.log(response.data);
                                                }
                                                enqueueSnackbar("Record saved successfully", { variant: 'success' });
                                            })
                                            .catch(error => {
                                                enqueueSnackbar("Record does not saved", { variant: 'error' });

                                            });
                                    } else {
                                        enqueueSnackbar("ERP Code Already exists");
                                    }
                                })
                                .catch(error => {
                                    handleError(error, enqueueSnackbar);
                                });
                        }
                        else {
                            enqueueSnackbar("Client Name Already exists");
                        }
                    })
                    .catch(error => {
                        handleError(error, enqueueSnackbar);
                    });
             }

           
            console.log("Payload  in ClientMasterDetailsPage ", payload);
        },
    });


    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
   

    console.log("contactDetails>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", contactDetails,teams)
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit} >
                <Page title="  Client Master Details | Smile Service" >
                    {/*     <Typography variant="h5" color="#b71c1c" marginLeft={3} marginTop={1} >    */}
                    {/*            Client Master Details                                               */}
                    {/*         </Typography>                                                          */}
                    {/*   <Divider/>   */}
                        <Scrollbar>
                            <ContentStyle>
                            <ClientMasterDetailsComponent formik={formik} clientMaster={clientMaster} handleApiCheckboxChange={handleApiCheckboxChange} isApiChecked={isApiChecked} isCheckedCallList={isCheckedCallList} isCheckedActive={isCheckedActive} handleCheckboxChange={handleCheckboxChange} handleIsActiveChange={handleIsActiveChange} projectStatus={projectStatus} clientGrouping={clientGrouping} value={value} handleChangeValue={handleChangeValue}
                                hide={hide} handleHide={handleHide} handleShow={handleShow} projectStatusId={projectStatusId} handelSelectedProject={handelSelectedProject} subContactId={subContactId} handleSelectedSubContact={handleSelectedSubContact} userId={userId}
                                projectDatatDropDown={projectDatatDropDown} projectId={projectId} handleSelectedProject={handleSelectedProject} executives={executives} selectedExecutives={selectedExecutives} handleSelectedExecutive={handleSelectedExecutive}
                                teams={teams} numberOfTeams={numberOfTeams} maxExecutivesPerTeam={maxExecutivesPerTeam} initialExecutiveValue={initialExecutiveValue} executiveValue={executiveValue} addTeam={addTeam} handleTeamNameChange={handleTeamNameChange}
                                handleProjectChange={handleProjectChange} initialExecutiveStates={initialExecutiveStates} executiveStates={executiveStates} handleExecutivesChange={handleExecutivesChange} executiveRoles={executiveRoles} executiveCostings={executiveCostings}
                                handleRoleChange={handleRoleChange} handleCostingChange={handleCostingChange} handleActiveCheckboxChange={handleActiveCheckboxChange} selectedRadioStates={selectedRadioStates} handleRadioChange={handleRadioChange} handleErrorCheckboxChange={handleErrorCheckboxChange}
                                exeData={exeData} handleSelectedExecutiveDD={handleSelectedExecutiveDD} SelectExecutive={SelectExecutive} selectedPreferredExecutives={selectedPreferredExecutives} handleSave={handleSave} removeTeam={removeTeam} deleteTeam={deleteTeam} handleDelete={handleDelete} executive={executive}
                                department={department} preferredExecutiveValue={preferredExecutiveValue} handlePreferredSelectedExecutive={handlePreferredSelectedExecutive} SelectPreferredExecutive={SelectPreferredExecutive} DeptValue={DeptValue} handleSelectedDepartment={handleSelectedDepartment} isHide={isHide}
                                selectedDepartment={selectedDepartment} executiveDepartments={executiveDepartments} currentDepartment={currentDepartment} exeCheckBoxState={exeCheckBoxState} handleExecutiveCheckBoxChange={handleExecutiveCheckBoxChange} rolesData={rolesData} SelectDepartment={SelectDepartment}
                                teamPanelData={teamPanelData} industry={industry} setpreferredExecutiveValue={setpreferredExecutiveValue} industryData={industryData} handleIndustryChange={handleIndustryChange} productFamily={productFamily} productFamilyData={productFamilyData}
                                handleProductFamilyChange={handleProductFamilyChange} category={category} categoryData={categoryData} handleCategoryChange={handleCategoryChange}
                                handleName={handleName} name={name} contactEmail={contactEmail} handleContactEmail={handleContactEmail} contactRemark={contactRemark} designation={designation} handleContactDesignationChange={handleContactDesignationChange}
                                handleContactRemark={handleContactRemark} handleIsBilling={handleIsBilling} isBilling={isBilling} handleInterState={handleInterState} interState={interState} contactDetails={contactDetails} addClientDetail={addClientDetail} handleClientDetailChange={handleClientDetailChange}
                                billingName={billingName} handleBillingName={handleBillingName }
                            />
                                
                            </ContentStyle>
                        </Scrollbar>

                        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="center" spacing={2}>

                            <LoadingButton
                                size="medium"
                                type="submit"
                                variant="contained"
                                sx={{ color: 'white' }}
                            >
                                Save
                            </LoadingButton>

                            <Button
                                sx={{ color: 'white' }}
                                onClick={handelCancelClick}
                                size="medium"
                                variant="contained"
                                color="primary"
                            >
                                Cancel
                            </Button>
                        </Stack>


                </Page>
            </Form>
            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}

        </FormikProvider>
    );
}

