import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// material
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel, Select,
    Autocomplete,
    Tooltip,
    FormControlLabel,
    Checkbox,
    Grid,
    Modal,
    Box,
    Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { startOfMonth, endOfMonth } from 'date-fns';
import Moment from 'moment';
import { styled } from '@mui/material/styles';
import URLS from '../../../services/urls.service';
import Page from '../../Page';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import { handleError } from '../../Common/Utils';
import Iconify from '../../Iconify';
import AppCache from '../../../services/AppCache';
// ----------------------------------------------------------------------




function descendingComparator(a, b, orderBy) {


    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    // if (query) {

    //    return filter(array, (_project) => _project.ClientAlias.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    // }
    return stabilizedThis.map((el) => el[0]);
}
const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .MuiTooltip-tooltip`]: {
        maxWidth: 'none', // Remove the maximum width
        whiteSpace: 'normal', // Ensure content stays on one line
    },
});

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 10,
    p: 2,
};
export default function ExecutiveExpenses({ executiveExpenses }) {
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();
    const [userId, setUserId] = useState([]);
    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
    }, []);
    const TABLE_HEAD = [
        {
            field: 'Date', headerName: 'Date', width: 100, valueFormatter: params =>
                Moment(params?.value).format("DD/MM/YYYY"), },
        { field: 'Type', headerName: 'Category', width: 100 },
        {
            field: 'Amount', headerName: 'Amount', 
            width: 120,

            renderCell: (cellValues) => {
                // Determine the background color based on the selectedAllocationType
                const typeValue = Number(cellValues.row.TypeId);
                let backgroundColor;
                switch (typeValue) {
                    case 1:
                        backgroundColor = 'red';
                        break;
                    case 2:
                        backgroundColor = 'green';
                        break;
                    case 3:
                        backgroundColor = 'color(srgb-linear 0 1 0)';
                        break;
                    default:
                        backgroundColor = 'gray'; // Default color for unhandled cases
                        break;
                }

                return (
                    <div style={{
                        backgroundColor,
                        color: 'white',      
                        borderRadius: '4px',
                        width: '100%',  // Ensure it uses the full width of the cell
                        height: '70%', // Ensure it uses the full height of the cell
                        display: 'flex', // Flexbox for centering content
                        alignItems: 'center', // Center content vertically
                        justifyContent: 'center', // Center content horizontally
                        boxSizing: 'border-box' // Include padding and border in the element's total width and height
                    }}>
                        {cellValues.value}
                    </div>
                );
            },
        },
        { field: 'Remark', headerName: 'Remark', width: 120  },
        {
            field: 'Description',
            headerName: 'Description',
            width: 300,
            renderCell: (cellValues) => {
                const formatDescription = (client, allocationValue, project) => {
                    return `Allocated ${allocationValue} to ${client} (${project})`;
                };

                // Determine the description content based on Category
                let descriptionContent;

                if (cellValues.row.Category === "Food") {
                    descriptionContent = (
                        <span>
                            {`${cellValues.row.FoodCategory} at ${cellValues.row.HotelName}, for ${cellValues.row.Count} person${cellValues.row.Count > 1 ? 's' : ''}.`}
                        </span>
                    );
                } else if (cellValues.row.Category === "Travelling") {
                    descriptionContent = (
                        <span>
                            Expense for {cellValues.row.TravelMode} from {cellValues.row.FromLocation} to {cellValues.row.ToLocation}.
                        </span>
                    );
                } else if (cellValues.row.Category === "Travel Allowance") {
                    descriptionContent = (
                        <span>
                            Travel allowance for the city of {cellValues.row.City}.
                        </span>
                    );
                } else if (cellValues.row.Category === "Toll Charges") {
                    descriptionContent = (
                        <span>
                            Toll Charges from {cellValues.row.FromLocation} to {cellValues.row.ToLocation} for {cellValues.row.Count} person.
                        </span>
                    );
                } else if (cellValues.row.Category === "Lodging") {
                    descriptionContent = (
                        <span>
                            Lodging cost at {cellValues.row.HotelName} hotel for {cellValues.row.Count} person.
                        </span>
                    );
                } else {
                    // Default case if Category doesn't match any of the specified types
                    descriptionContent = (
                        <span>{}</span>
                    );
                }

                // If TypeId is not 2 or 3, include the project details as well
                const projectDetails = cellValues.row.ProjectDetails.map((project, index) => (
                    <div key={index}>
                        {formatDescription(project.ClientName, project.AllocationValue.toFixed(2), project.ProjectName)}
                        <br />
                    </div>
                ));

                return (
                    <NoMaxWidthTooltip
                        title={
                            <Box>
                                {cellValues.row.TypeId !== 2 && cellValues.row.TypeId !== 3 && (
                                    <div>{projectDetails}</div>
                                )}
                            </Box>
                        }
                        arrow
                        placement="top"
                    >
                        <span style={{ whiteSpace: 'normal' }}>{descriptionContent}</span>
                    </NoMaxWidthTooltip>

                );
            }
        }

,
        {
            field: 'actions',
            headerName: '',
            type: 'actions',
            width: 20,
            renderCell: (cellValues) => (
                <div>
                    <IconButton
                        onClick={(event) => {
                            handleEdit(event, cellValues);
                        }}>
                        <Iconify icon="ic:outline-edit" width="20px" height="20px" color="primary.main" />
                    </IconButton>
                </div>
            ),
        },
        {
            field: 'delete',
            headerName: '',
            type: 'delete',
            width: 20,
            renderCell: (cellValues) => {
                console.log("cellValues>>>", cellValues.row)
               
                    return (
                        <div>
                            <IconButton
                                onClick={(event) => {
                                    handleDelete(cellValues);
                                }}
                            >
                                <Iconify icon="ic:baseline-delete" width='20px' height='20px' color='primary.main' />
                            </IconButton>
                        </div>
                    );
                }   
        },
    ];

    const { enqueueSnackbar } = useSnackbar();
    const [fromDateValue, setFromDateValue] = useState(startOfMonth(new Date()));
    const [toDateValue, setToDateValue] = useState(new Date());
    const [open, setOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState();
    const [expenseData, setExpenseData] = useState([]);
    const location = useLocation();

    const handleEdit = (event, cellValues) => {
        const payload = { ExpenseMasterId: cellValues.row.Id}
        URLS.editExecutiveExpenses(payload)
            .then((response) => {

                const jsonDataString = JSON.stringify(response.data);

                const modifiedData = JSON.parse(jsonDataString);
                setData(modifiedData);

                navigate('/dashboard/ExecutiveExpensesDetailsPage', { state: { executiveExpenses: modifiedData } })
            })
            .catch(error => {
                handleError(error, enqueueSnackbar);
            });
    };

    const handleDelete = ( cellValues) => {
        console.log('Delete clicked', cellValues.row);
        setSelectedRow(cellValues.row);
        setOpen(true);
    };
    const handleEffFrom = (newValue) => {
        const fromDate = new Date(newValue);
        const toDate = new Date(toDateValue);

        // Check if From Date is greater than To Date
        if (fromDate > toDate) {
            enqueueSnackbar('From Date cannot be greater than To Date.', { variant: 'error' });
        } else {
            setFromDateValue(newValue);
        }
    };

    const handleEffTo = (newValue) => {
        const toDate = new Date(newValue);
        console.log("toDate>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",toDate,newValue)
        const systemDate = new Date(); // Current system date

        // Check if To Date is greater than system date
        if (toDate > systemDate) {
            enqueueSnackbar('To Date cannot be greater than the system date.', { variant: 'error' });
            return;
        }

        // Check if To Date is less than From Date
        if (new Date(fromDateValue) > toDate) {
            enqueueSnackbar('To Date cannot be less than From Date.', { variant: 'error' });
        } else {
            setToDateValue(newValue);
        }
    };
    const handleClose1 = () => setOpen(false);

    const handleConfirmDelete = (confirmed) => {
       
        if (confirmed !== null) {
            const formattedFromDate = Moment(fromDateValue).format('DD/MMM/YYYY');
            const formattedToDate = Moment(toDateValue).format('DD/MMM/YYYY');
            const payload = { ExpenseMasterId: selectedRow.Id, CreatedBy: userId }
            const filterPayload = { ExpenseMasterId: userId, FromDate: formattedFromDate, ToDate: formattedToDate }

            URLS .deleteExecutiveExpenses(payload)
                .then((response) => {
                    if (response.data.status === true) {
                        URLS.filterExecutiveExpenses(payload)
                            .then((response) => {
                                setExpenseData(processExpenseData(response.data));
                                setIsLoading(false);
                            })
                            .catch(error => {
                                // On error
                                enqueueSnackbar("Failed to fetch expense data", { variant: 'error' });
                                setIsLoading(false);
                            });

                        enqueueSnackbar("Deleted Expense data successfully", { variant: 'success' });
                        setOpen(false);

                    }
                    
                })
                .catch(error => handleError(error));
        }
       
    };
    const processExpenseData = (data) => data.map((item, index) => ({
            ...item,
            id: index + 1 // Use a unique identifier, e.g., index + 1 or another unique value from your data
        }));
    // Filter
    const onFilterClick = () => {
        setIsLoading(true);
        setExpenseData([]); 
        const formattedFromDate = Moment(fromDateValue).format('YYYY/MM/DD');
        const formattedToDate = Moment(toDateValue).format('YYYY/MM/DD');
        const payload = { ExpenseMasterId: userId, FromDate: formattedFromDate, ToDate: formattedToDate }
        URLS.filterExecutiveExpenses(payload)
            .then((response) => {
                if (response.data.length === 0) {
                    enqueueSnackbar("Data not found", { variant: 'success' });
                } else {
                    setExpenseData(processExpenseData(response.data));
                    enqueueSnackbar("Fetched data successfully", { variant: 'success' });
                }
                setIsLoading(false);
            })
            .catch(error => {
                // On error
                enqueueSnackbar("Failed to fetch expense data", { variant: 'error' });
                setIsLoading(false);

            });
        console.log("payload", payload)
    };
    // Reset
    const handleReset = () => {
        setFromDateValue(startOfMonth(new Date()));
        setToDateValue(new Date());
        setSelectedRow([]);
        setExpenseData([])
    }
    useEffect(() => {
        setFromDateValue(startOfMonth(new Date()));
        setToDateValue(new Date());
        setSelectedRow([]);
        setExpenseData([])
    }, [location.key]); 
    
    console.log("selectedRow", selectedRow)
    return (

        <Page title="Executive Expenses" >


            <Card >
                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={2}>
                   
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="From Date"
                                    inputFormat="dd/MMM/yyyy"
                            value={Moment(fromDateValue).format('YYYY/MM/DD')}
                                    onChange={handleEffFrom}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="To Date"
                            inputFormat="dd/MMM/yyyy"
                            disableFuture
                            value={Moment(toDateValue).format('YYYY/MM/DD')}
                            onChange={handleEffTo}
                                    renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                            </LocalizationProvider>

                        
                    {/*  <FormControlLabel control={<Checkbox checked={isCheckedActive} onChange={handleIsActiveChange} />} label="IsActive" style={{ width: '150px' }} /> */}
                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>
                        <Tooltip title="Filter">
                            <Button variant="contained" size="small" onClick={onFilterClick}>
                                <Icon icon="ic:outline-filter-alt" width="25px" height="25px" />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Add New">
                            <Button
                                component={RouterLink}
                                to='/dashboard/ExecutiveExpensesDetailsPage'
                                variant="contained"
                                size="small"
                                state={{ executiveExpenses: { ExpenseMasterId: '', Date: '', ExpenseDate: "", Type: '',ExpenseCategory:"", Amount: '', Remark: '', ExpenseCategoryColumnsModel: [], AllocationItems: [] } }}
                            >
                                <Icon icon="ic:baseline-plus" width="25px" height="25px" />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Reset">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={handleReset}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>

                        </Tooltip >
                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '73vh', width: '100%', overflow: 'hidden' }}>
                    <DataGrid
                        rows={expenseData}
                        density={'compact'}
                        columns={TABLE_HEAD}
                        getRowId={(row) => row.id}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        sx={{
                            '& .MuiDataGrid-cell': {
                                whiteSpace: 'normal', // Enable text wrapping
                                overflow: 'hidden', // Hide overflowed content
                                textOverflow: 'ellipsis', // Add ellipsis to the end of overflowed text
                            },
                            '& .MuiDataGrid-columnHeader': {
                                whiteSpace: 'normal', // Allow text wrapping in headers
                            },
                            '& .MuiDataGrid-row': {
                                height: 'auto', // Adjust height to fit content
                            },
                            '& .MuiDataGrid-cell--textLeft': {
                                textAlign: 'left', // Align text to the left for better readability
                            },
                        }}
                        rowHeight={50} // Adjust this value as needed
                    />
                </div>


            </Card>
           
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...style }}>


                    <Typography id="modal-modal-title" variant="subtitle2" component="h2" textAlign="center">
                        {/*  {selectedRow ? formatRowData(selectedRow) : 'No data available'} */}
                        <Typography id="modal-modal-title" variant="subtitle2" component="h2" textAlign="center">
                            {selectedRow ? (
                                <>
                                    Are you sure you want to delete below data?
                                    <Typography variant="body2" component="div" textAlign="center" mt={2}>
                                        <strong>Date:</strong> {selectedRow.Date},&nbsp;
                                        <strong>Category:</strong> {selectedRow.Category},&nbsp;
                                        <strong>Type:</strong> {selectedRow.Type},&nbsp;
                                        <strong>Amount:</strong> {selectedRow.Amount},&nbsp;
                                        <br />
                                        <strong>Remark:</strong> {selectedRow.Remark},&nbsp;
                                        <strong>Description:</strong> {selectedRow.ProjectDetails ? selectedRow.ProjectDetails.map(item => item.ClientName).join(', ') : ''}
                                    </Typography>
                                </>
                            ) : 'No data available'}
                        </Typography>

                    </Typography>
                    <Stack direction="row" mt={2} spacing={2}>
                        <Button
                            sx={{ color: 'white' }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleConfirmDelete}
                        >
                            Delete
                        </Button>
                        <Button
                            sx={{ color: 'white' }}
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleClose1}

                        >
                            Cancel
                        </Button>

                    </Stack>
                </Box>

            </Modal>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}

        </Page>


    );
}