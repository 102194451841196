import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Tooltip, Stack, TextField, FormControl, InputLabel, Select, Grid, Modal, IconButton, Typography, MenuItem, Button, Box, CardMedia, Tabs, Tab, FormHelperText } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Moment from 'moment';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';
import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';
import AllocationExecutiveExpensesTabPanel from './AllocationExecutiveExpensesTabPanel';

// -----------------------------------------------------------------------------------------------------

const style = {
    position: 'absolute',
    top: '50%',
    justifyContent: 'center',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 10,
    p: 4,
};

const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #4e342e',
    position: 'sticky',
    '& .MuiTabs-indicator': {

    },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
        minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontsize: '20px',
    color: '#b71c1c',
    Transition: '2s',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        color: '#4e342e',

        opacity: 1,
    },
    '&.Mui-selected': {
        color: '#4e342e',

        fontWeight: theme.typography.fontWeightMedium,

    },
    '&.Mui-focusVisible': {
        backgroundColor: '#fff',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ExecutiveExpensesDetailsTabPanel({ formik, value, handleTabChangeValue, saveClicked, handleHide, handleShow, fieldSets, handleAllocationRadioChange, selectedAllocationType,
    selectedClientId, selectedClient, handleClientChange, selectProject, selectedProjectId, handleProjectChange, selectedexpensesCategory, expensesCategory, handleExpensesCategory,
    travelCategory, selectedeTravelCategory, handleTravelCategory, foodCategory, selectedeFoodCategory, handleFoodCategory, handleAllocationValueChange, gridRows, allocationValue, handleAddRow,
    handleFieldChange, selectedExpenseCategoryId, handleDelete, editingRowId, newAllocationValue, handleEdit, handleSave, setNewAllocationValue, executiveExpenses, setGridRows, handleCellEdit, setSelectedClientId
}) {
    const navigate = useNavigate();

    const [openModalForCancel, setOpenModalCancel] = useState(false);
    const message = {
        forCancel: 'Do you want to cancel ? ',
    };
    const forCancelConfirm = (value) => {
        if (value) {
            navigate('/dashboard/master/executiveexpenses', { replace: true });
            setOpenModalCancel(false);
        }
        else {
            setOpenModalCancel(false);
        }
    };

    const handelCancelClick = () => {
        setOpenModalCancel(true);

    };
/*    const categories = selectedExpenseCategoryId.split(','); */
    const categories = (typeof selectedExpenseCategoryId === 'string')
        ? selectedExpenseCategoryId.split(',')
        : [];

    console.log("expensesCategory>>>>>>>>>>>>>>>>>>...", selectedExpenseCategoryId,)

    const validateInput = (input) => {
        return /^\d*$/.test(input); // Regular expression to allow only numeric input
    };

    const renderFormElement = (category) => {
        switch (category) {
            case 'TravelMode':
                return (
                    <FormControl fullWidth size="small">
                        <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Travel Mode</InputLabel>
                        <Select
                            label="Travel Mode"
                            value={travelCategory}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7',
                                        maxHeight: '200px',
                                        width: '200px',
                                    },
                                },
                            }}
                        >
                            <MenuItem key={0} value={""} onClick={() => handleTravelCategory()} >Select</MenuItem>
                            {selectedeTravelCategory.map((travel) => (
                                <MenuItem key={travel.Id} value={travel.Id} onClick={() => handleTravelCategory(travel.Id)}>
                                    {travel.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case 'FromLocation':
                return (
                    <TextField
                        fullWidth
                        size="small"
                        label="From Location"
                        {...formik.getFieldProps('FromLocation')}
                    />
                );
            case 'ToLocation':
                return (
                    <TextField
                        fullWidth
                        size="small"
                        label="To Location"
                        {...formik.getFieldProps('ToLocation')}
                    />
                );
            case 'Count':
                return (
                    <TextField
                        fullWidth
                        size="small"
                        label="Count"
                        {...formik.getFieldProps('Count')}
                    />
                );
            case 'City':
                return (
                    <TextField
                        fullWidth
                        size="small"
                        label="City"
                        {...formik.getFieldProps('City')}
                    />
                );
            case 'FoodCategory':
                return (

                    <FormControl fullWidth size="small">
                        <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Food Category</InputLabel>
                        <Select
                            label="Food Category"
                            value={foodCategory}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7',
                                        maxHeight: '200px',
                                        width: '200px',
                                    },
                                },
                            }}
                        >
                            <MenuItem key={0} value={""} onClick={() => handleFoodCategory()}>Select</MenuItem>
                            {selectedeFoodCategory.map((food) => (
                                <MenuItem key={food.Id} value={food.Id} onClick={() => handleFoodCategory(food.Id)}>
                                    {food.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                );
            case 'HotelName':
                return (
                    <TextField
                        fullWidth
                        size="small"
                        label="Hotel Name"
                        {...formik.getFieldProps('HotelName')}
                    />
                );
            default:
                return null;
        }
    };

    const getColumnsForRow = (categories) => {
        if (!Array.isArray(categories)) {
            return [];
        }
        const row = [];
        for (let i = 0; i < categories.length; i += 3) {
            row.push(categories.slice(i, i + 3));
        }
        return row;
    };


    const {  getFieldProps, errors, touched } = formik;

    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <AntTabs value={value} onChange={handleTabChangeValue} variant="fullWidth" aria-label="basic tabs example" >
                    <AntTab onClick={handleShow} label="Details" {...a11yProps(0)} />
                    {value > 0 && <AntTab onClick={handleShow} label=" Allocation " {...a11yProps(1)} />}
                </AntTabs >
            </Box>
            <TabPanel value={value} index={0}>
                <Stack spacing={3} marginTop={1 }>
                    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
                        <FormControl fullWidth size="small">
                            <InputLabel inputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>Expenses Category</InputLabel>
                            <Select
                                label="Expenses Category"
                                value={expensesCategory}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            backgroundColor: '#fbe9e7',
                                            maxHeight: '200px',
                                            width: '200px',
                                        },
                                    },
                                }}
                            >
                                <MenuItem key={0} value={""} onClick={() => handleExpensesCategory()} >Select</MenuItem>
                                {selectedexpensesCategory.map((expenses) => (
                                    <MenuItem key={expenses.Id} value={expenses.Id} onClick={() => handleExpensesCategory(expenses.Id)}>
                                        {expenses.Name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth 
                            size="small"
                            label="Amount"
                            type="number"
                            {...getFieldProps('Amount')}

                        />
                        <TextField
                            fullWidth 
                            size="small"
                            label="Remark"
                            {...getFieldProps('Remark')}

                        />
                    </Stack>
                    {getColumnsForRow(categories).map((categoryRow, index) => (
                        Array.isArray(categoryRow) && categoryRow.length > 0 && (
                            <Stack key={index} direction={{ xs: "column", sm: "row" }} spacing={4}>
                                {categoryRow.map((category) => (
                                    <Box key={category} sx={{ width: categoryRow.length === 1 ? '50%' : '33%' }}>
                                        {renderFormElement(category)}
                                    </Box>
                                ))}
                            </Stack>
                        )
                    ))}
                   
                </Stack>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <AllocationExecutiveExpensesTabPanel formik={formik} fieldSets={fieldSets} handleAllocationRadioChange={handleAllocationRadioChange} selectedAllocationType={selectedAllocationType} selectedClientId={selectedClientId}
                    selectedClient={selectedClient} handleClientChange={handleClientChange} selectProject={selectProject} selectedProjectId={selectedProjectId} handleAllocationValueChange={handleAllocationValueChange}
                    handleProjectChange={handleProjectChange} gridRows={gridRows} allocationValue={allocationValue} handleAddRow={handleAddRow} handleFieldChange={handleFieldChange} handleDelete={handleDelete}
                    editingRowId={editingRowId} newAllocationValue={newAllocationValue} handleEdit={handleEdit} handleSave={handleSave} setNewAllocationValue={setNewAllocationValue} setGridRows={setGridRows} handleCellEdit={handleCellEdit }
                />
            </TabPanel>



            {openModalForCancel && <ModalPopUp openCloseValue={setOpenModalCancel} msg={message.forCancel} confirm={forCancelConfirm} />}
        </>
    );
}