import * as Yup from 'yup';
import * as React from 'react';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Accordion, Radio, AccordionDetails, Tooltip, Stack, TextField, FormControl, InputLabel, Select, Grid, Modal, MenuItem, Button, Box, Typography, Tabs, Tab, TextareaAutosize, Card, Autocomplete, Chip } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import { styled } from '@mui/material/styles';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Icon } from '@iconify/react';
import URLS from '../../../services/urls.service';
import AppCache from '../../../services/AppCache';


import { handleError } from '../../Common/Utils';
import ModalPopUp from '../../Common/ModalPopUp';

import Iconify from '../../Iconify';



const accordionStyle = {
    backgroundColor: '#fbe9e7',
    minHeight: '10px',
    height: '30px',
    marginTop: '10px',
    marginBottom: '10px'
    // Add other CSS properties as needed
};

export default function ClientContactDeails({ formik, clientMaster, handleName, name, DeptValue, 
    SelectDepartment, contactEmail, handleContactEmail, contactRemark, handleContactDesignationChange, contactDetails, addClientDetail, handleClientDetailChange, designation }) {
    const accordionStyle = {
        backgroundColor: '#fbe9e7',
        minHeight: '10px',
        height: '40px',
        marginBottom: '5px'
        // Add other CSS properties as needed
    };
    
 
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={11} lg={11.5}>
                {contactDetails.length > 0 && contactDetails.map((detail, index) => (
                    <Stack key={index}>
                        <Accordion
                            style={{
                                borderBottom: '1px solid #ddd',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                                margin: '7px'
                            }}
                            defaultExpanded={index === 0} // Set defaultExpanded to true for the first accordion
                        >
                            <AccordionSummary style={accordionStyle} expandIcon={<ExpandMoreIcon />}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <Typography style={{ lineHeight: 2.5 }}>
                                        <span style={{ fontWeight: 'bold' }}>Client Contact Details:</span>
                                    </Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="Name"
                                            size="small"
                                            value={detail.Name}
                                            onChange={(e) => handleClientDetailChange(index, 'Name', e.target.value)}
                                        />

                                        <FormControl fullWidth size="small">
                                            <InputLabel>Designation</InputLabel>
                                            <Select
                                                label="Designation"
                                                value={detail.Designation}  // Ensure you are using the correct field from contactDetails
                                                onChange={(e) => handleContactDesignationChange(index, e.target.value)}  // Capture the change here
                                                MenuProps={{
                                                    PaperProps: {
                                                        sx: {
                                                            backgroundColor: '#fbe9e7',
                                                            maxHeight: '200px',
                                                            width: '200px',
                                                        }
                                                    }
                                                }}
                                            >
                                                <MenuItem value="">
                                                    Select
                                                </MenuItem>
                                                {designation.map((designation) => (
                                                    <MenuItem key={designation.Id} value={designation.Id}>
                                                        {designation.Name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </Stack>

                                    <Stack direction="row" spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="Phone"
                                            size="small"
                                            value={detail.Phone}
                                            onChange={(e) => handleClientDetailChange(index, 'Phone', e.target.value)}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            size="small"
                                            value={detail.Email}
                                            onChange={(e) => handleClientDetailChange(index, 'Email', e.target.value)}
                                        />
                                    </Stack>

                                    <TextField
                                        fullWidth
                                        label="Remark"
                                        size="small"
                                        value={detail.Remark}
                                        onChange={(e) => handleClientDetailChange(index, 'Remark', e.target.value)}
                                    />
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Stack>
                ))}
            </Grid>

            <Grid item xs={12} sm={12} md={1} lg={0.5} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Tooltip title="Add New Contact">
                    <Button onClick={addClientDetail} variant="contained" size="small">
                        <Icon icon="ic:baseline-plus" width="25px" height="25px" />
                    </Button>
                </Tooltip>
            </Grid>
        </Grid>
    );
}






