import { React, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import XLSX from 'sheetjs-style';

// Material-UI components
import {
    Card,
    IconButton,
    Stack,
    Button,
    TextField,
    Divider,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Box,
    Tooltip,
    Menu,
    Fade,
    Typography
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { startOfMonth, endOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import Moment from 'moment';
import { Icon } from '@iconify/react';
import * as FileSaver from 'file-saver';
import Iconify from '../../Iconify';
import URLS from '../../../services/urls.service';
import LoadingProgressBar from '../../../theme/overrides/LoadingProgressBar';
import Page from '../../Page';
import { handleError } from '../../Common/Utils';
import AppCache from '../../../services/AppCache';

// Static data for dropdowns
const clientsData = [
    { id: 1, Name: 'Detailed' },
    { id: 2, Name: 'Summary' },
];
const statusDropdown = [
    { Id: 0, Value: 'Pending' },
    { Id: 1, Value: 'Resolved' },
    { Id: 2, Value: 'WIP' },
    { Id: 3, Value: 'All' },
];

export default function ServiceRequestStatusReport() {

    const columnsnew = [
        {
            field: 'formattedRequest',
            headerName: 'Service Request Details',
            flex: 8, // 80% of the available width
            renderCell: (params) => {
                const requestDate = new Date(params.row.RequestDate).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                });

                return (
                    <div style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                        Service Requested By <span style={{ fontWeight: 'bold' }}>{params.row.RequestFromName}</span>
                        {" "}On Date <span style={{ fontWeight: 'bold' }}>{requestDate}</span>
                        {" "}For <span style={{ fontWeight: 'bold' }}>{params.row.Client} {params.row.Site}</span>
                        <br />
                        {params.row.Request && (
                            <span style={{ fontWeight: 'bold', fontStyle: 'italic' }}>'{params.row.Request}'</span>
                        )}
                    </div>
                );
            }
        },
        {
            field: 'RequestStatus',
            headerName: '',
            flex: 2, // 20% of the available width
            align: 'right',
            headerAlign: 'right', // Align header to the right as well
        },
    ];
    const getRowClassName = (params) => {
        return {
            backgroundColor: params.index % 2 === 0 ? '#ffffff' : '#f0f0f0', // Use your preferred colors
            color: '#000', // Set text color, if necessary
        };
    };

    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();
    const CurrentLoggedInUser = AppCache.GetCurrentLoggedInUser();

    const [clients, setClients] = useState('');
    const [selectClient, setSelectClient] = useState([]);
    const [status, setStatus] = useState('');
    const [effectiveFromValue, setEffectiveFromValue] = useState(startOfMonth(new Date()));
    const [effectiveToValue, setEffectiveToValue] = useState(endOfMonth(new Date()));
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState();
    const [userId, setUserId] = useState([]);
    const [userName, setUserName] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [serviceRequestId, setServiceRequestId] = useState('');


    // Status dropdown handler
    const handleStatus = (id) => setStatus(id);

    // Date handlers
    const handleEffFrom = (newValue) => setEffectiveFromValue(newValue);
    const handleEffTo = (newValue) => setEffectiveToValue(newValue);
    const handleServiceRequestId = (newValue) => setServiceRequestId(newValue);
    // Clients dropdown handler
    const handleClients = (event) => setClients(event.target.value);


    const columns = [];
    const rows = [];

    reports.forEach((item, index) => {
        const row = { id: index + 1 };
        Object.entries(item).forEach(([key, value]) => {
            if (index === 0) {
                columns.push({
                    field: key,
                    headerName: key,
                    width: 150
                });
            }
            row[key] = value;
        });
        rows.push(row);
    })

    // Filter function
    const onFilterClick = () => {
        setIsLoading(true);

        const formattedFromDate = Moment(effectiveFromValue).format('DD-MMM-yyyy');
        const formattedToDate = Moment(effectiveToValue).format('DD-MMM-yyyy');
        const payload = {
            UserId: userId,
            FromDate: formattedFromDate,
            ToDate: formattedToDate,
            ClientId: clients || 0 ,
            Status:status || 0,
            ServiceRequestId: serviceRequestId || 0
        };

        URLS.serviceRequestStatusReport(payload)
            .then((response) => {
                setIsLoading(false);
                setReports(response.data)

            })
            .catch(error => console.log("Error", error));
    };

    // Export to Excel function
    const exportToExcel = () => {
        const fileExtension = '.xlsx';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        // Check if reports are available
        if (!reports || reports.length === 0) {
            console.log("No data to export.");
            return;
        }

        // Prepare headers based on response keys
        const headers = Object.keys(reports[0]);

        // Prepare the row data based on reports data
        const dataToExport = reports.map((row) => {
            return headers.map(header => row[header]); // Map each header to the corresponding row data
        });

        const dataWithHeaders = [headers, ...dataToExport]; // Combine headers with data rows
        const ws = XLSX.utils.aoa_to_sheet(dataWithHeaders); // Convert to AOA format

        const wb = { Sheets: { data: ws }, SheetNames: ['data'] }; // Create workbook

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' }); // Write workbook to buffer
        const data = new Blob([excelBuffer], { type: fileType }); // Create a blob from the buffer

        const fileName = `${userName}_ServiceRequestStatusReport${fileExtension}`; // Define file name

        FileSaver.saveAs(data, fileName); // Save the file
    };
    // Reset function
    const onResetClick = () => {
        setStatus("");
        setClients('');
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(endOfMonth(new Date()));
        setReports([]);
    };

    // Handle export dropdown open/close
    const handleExport = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    useEffect(() => {
        setUserId(CurrentLoggedInUser.ExecutiveId);
        setUserName(CurrentLoggedInUser.ExecutiveName);
    }, []);

    useEffect(() => {
        URLS.getServiceEntryClientName()
            .then((response) => setSelectClient(response.data))
            .catch(error => console.log("Error", error));
    }, []);

    useEffect(() => {
        setStatus("");
        setClients('');
        setEffectiveFromValue(startOfMonth(new Date()));
        setEffectiveToValue(endOfMonth(new Date()));
        setReports([]);
    }, [location.key]);

  

    return (

        <Page title="Service Request Status Report" >
            <Typography variant="h5" color="#b71c1c" margin={1} >
                Service Request Status Report
            </Typography>
            <Card >

                <Stack direction="row" alignItems="center" justifyContent="space-between" m={1} spacing={1}>
                    <FormControl fullWidth size="small">
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }}>
                            Clients
                        </InputLabel>
                        <Select
                            label="Clients"
                            size="small"
                            value={clients}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7',
                                        maxHeight: '200px',
                                    },
                                },
                            }}

                            onChange={handleClients}
                        >
                            {selectClient.map((client) => (
                                <MenuItem
                                    key={client.ClientId}
                                    value={client.ClientId}
                                >
                                    {client.Name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker

                            label="From Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveFromValue).format('YYYY/MM/DD')}
                            onChange={handleEffFrom}

                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                            label="To Date"
                            inputFormat="dd/MMM/yyyy"
                            value={Moment(effectiveToValue).format('DD-MMM-yyyy')}
                            onChange={handleEffTo} // This will be a Date object
                            renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                        />
                    </LocalizationProvider>

                    <FormControl
                        size="small" sx={{ minWidth: 230, fontSize: '0.5rem' }}>
                        <InputLabel InputLabelProps={{ style: { color: 'green', fontSize: '14px' } }} >Status</InputLabel>
                        <Select
                            label="Status"
                            size="small"
                            value={status}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        backgroundColor: '#fbe9e7', // Set the background color here
                                        maxHeight: '200px',
                                        width: '200px',// Set the max height here
                                    }
                                }
                            }}
                        >
                            <MenuItem key={0} value={""} onClick={() => handleStatus()} >Select</MenuItem>

                            {statusDropdown.map((product) => (
                                <MenuItem key={product.Id} value={product.Id} onClick={() => handleStatus(product.Id)}> {product.Value}</MenuItem>
                            ))}
                        </Select>

                    </FormControl>
                    <TextField
                        fullWidth
                        label="Service Request Id"
                        size="small"
                        value={serviceRequestId}
                        onChange={(e) => handleServiceRequestId(e.target.value)}
                    />

                    <Stack direction={{ xs: "column", sm: "row" }} alignItems="center" justifyContent="space-between" spacing={1}>

                        <Tooltip title="Filter">

                            <Button
                                variant="contained"
                                size="small"
                                onClick={onFilterClick}
                            ><Icon icon="ic:outline-filter-alt" width="25px" height="25px" /></Button>
                        </Tooltip >
                        <Tooltip title="Reset">
                            <Button
                                variant="contained"
                                size="small"
                                onClick={onResetClick}
                            ><Icon icon="ic:twotone-restart-alt" width="25px" height="25px" /></Button>
                        </Tooltip >
                        <Tooltip title="Export">
                            <IconButton id="fade-button"
                                aria-controls={open ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleExport} sx={{ marginBottom: 1 }} >
                                <Icon height="15px" icon="ic:baseline-density-small" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                        >
                            <MenuItem onClick={handleClose}>  <Button sx={{ color: 'black' }} size="small" onClick={exportToExcel}>Export to excel</Button></MenuItem>


                        </Menu>

                    </Stack>
                </Stack>
                <Divider />

                {/* ----------------------------------------------------------------- */}

                <div style={{ height: '70vh', width: '100%' }}>
                    <DataGrid
                        rows={reports}
                        density={'compact'}
                        columns={columnsnew} // Use dynamic columns
                        getRowId={(row) => row.id || reports.indexOf(row)}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        sx={{
                            '& .MuiDataGrid-row:nth-of-type(even)': {
                                backgroundColor: 'white', // Even rows: White
                            },
                            '& .MuiDataGrid-row:nth-of-type(odd)': {
                                backgroundColor: '#fbe9e7', // Odd rows: Blue
                                color: 'bleck', // Set text color to white for better contrast
                            },
                        }}
                    />

                </div>

            </Card>
            {isLoading && (<LoadingProgressBar sx={{ py: 6 }} />)}


        </Page>


    );
}
